import React from 'react';
import { useAtom } from 'jotai';
import { getAuthors, builderAtom} from '../../jotais'
import { AuthorBlockRender } from '../BuilderBlock'
import {HorizontalScroller} from '../Misc'

const AuthorsBlock = () => {

  const [pies] = useAtom(getAuthors);
  const [builder] = useAtom(builderAtom);
  const piesCount = pies.length;
  const {button_text_color, button_color} = builder;
  
  return (

    <HorizontalScroller>
      {
        pies.map((pie, idx) => 
          <AuthorBlockRender 
            key={idx} 
            image_url={pie.artwork_cdnUrl}            
            title={pie.title}
            text_color={button_text_color}
            button_color={button_color}
          />
        )
      }
    </HorizontalScroller>

  )
}

export default AuthorsBlock;