import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PodcastsIcon from './PodcastsIcon'

const CPIcon = (props) => {
  const {icon_color, icon_group, icon_name} = props;
  
  switch(icon_group) {
    case 'podcasts':
      return (
        <div style={{
          // borderRadius: '36px',
          width: '36px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // backgroundColor: icon_color
        }}>
          <PodcastsIcon name={icon_name} width={"30px"} height={"30px"}/>
        </div>
      )
    case 'fab': 
      return (
        <div style={{
          borderRadius: '36px',
          width: '36px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: icon_color
        }}>
          <FontAwesomeIcon icon={[icon_group, icon_name]} color="black" size="lg"/>
        </div>                    
      )
    default:
      return <div></div>
  }
}

export default CPIcon;