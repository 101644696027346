import React from 'react';
import { Image } from 'antd';
import {getTextColor} from '../../libs'

const CardBlockFullSize = (props) => {

  const {handleClick, text_color, button_color, title, price_tag, image_url, demoView, price_tag_background_color} = props;

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column',
      borderRadius: "12px",
      minHeight: "120px",
      width: demoView ? "360px" : null,
      // paddingLeft: '30px',
      // paddingRight: '30px',
      // alignItems: 'center',
      backgroundColor: button_color,
      color: getTextColor(button_color),
      // borderColor: `${text_color}`,
      cursor: 'pointer',
      boxShadow: demoView ? '5px 10px 8px 10px #C1C1BD' : null
    }}
      onClick={handleClick}
    >
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Image
          preview={false}
          style={{borderRadius: `12px 12px ${title && title.length > 0 ? "0 0" : "12px 12px"}`}}
          src={image_url ? image_url : "https://via.placeholder.com/160"}/>
      </div>    
      {
        title ? <div style={{padding: "10px"}}><div style={{fontSize: "18px", fontWeight: '700'}}>{title}</div></div> : null
      }
      {price_tag ? <div style={{
          padding: "10px",
          alignSelf: 'flex-end'          
        }}>
          <div style={{
            backgroundColor: price_tag_background_color, 
            color: getTextColor(price_tag_background_color),
            fontSize: '14px',
            fontWeight: '700',
            borderRadius: '5px',
            padding: '5px'
          }}
          >{price_tag}</div>
        </div> : null}      
    </div>
  )
}

export default CardBlockFullSize;