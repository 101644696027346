import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import railsApi from '../api/railsApi'
import {setNotificationState } from '../jotais'

const useFetchProjects = () => {

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setNotiifcation] = useAtom(setNotificationState)
  // const [error, setError] = useState(null);

  const fetchData = () => {
    railsApi.get("/v1/projects")
    .then((res) => {
      setState(res.data);

      setTimeout(() => {
        setLoading(false);
      }, 500)
    })
    .catch((err) => {
      const description = err.response.statusText;
      setNotiifcation({
        status: true, 
        category: 'Notification',
        message: `Error`,
        autoReset: true, 
        description,
        type: 'error',
      })
      // console.log(err.response.statusText)
      setTimeout(() => {
        setLoading(false);
      }, 500)      
    })
  }

  const changeProject = (newData, idx) => {
    const _copy = [...state];
    _copy[idx] = newData;
    setState(_copy)
  }

  useEffect(() => {
    fetchData();
  }, [])

  return [state, loading, fetchData, changeProject];
}

export default useFetchProjects;