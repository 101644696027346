import React from 'react';
import { useAtom } from 'jotai';
import {useIconButtonPies} from '../../hooks'
import { getModalPies, builderAtom} from '../../jotais'
import { reorderArray } from '../../libs'
import ListBody from './ListBody'

const MessagesBodySort = (props) => {
  const {items} = props;
  const [builderState] = useAtom(builderAtom);
  const {modal_type} = builderState;  
  const [pies] = useAtom(getModalPies);

  if(modal_type && pies) {
    return (
      <>
      {props.children}
      <ListBody 
        items={pies} 
        sortableName={modal_type} 
      />
      </>
    )
  }

  return null;
}

export default MessagesBodySort;