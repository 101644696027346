import React, {useState} from "react";
import { useAtom } from 'jotai';
import { updatePassword, authAtom} from './../../jotais'
import {PageHeader} from '../components'
import {Form,FormGroup, Card, CardBody, Label, Input} from 'reactstrap'

const ResetPassword = () => {
  const [user] = useAtom(authAtom);
  // const email = {user};
  const [, updateUserPassword] = useAtom(updatePassword);

  const [password, setPassword] = useState({password: "", passwordConfirmation: ""});

  const handleChangePassword = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setPassword({...password, [name]: val});
  }

  const handleCancel = () => {
    setPassword({password: "", passwordConfirmation: ""});
  }

  const handleClick = () => {
    updateUserPassword(password)
  }

  return (
    <>
      <PageHeader title={"Reset password"}/>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="row align-items-center">
                  <div className="col ml-n2">   
                    <h3 className="card-label font-weight-bolder text-dark">Change Password</h3>
                    <p className="small text-muted mb-1">{user.email}</p>
                  </div>
                  <div className="col-auto">
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      onClick={handleClick}
                    >
                        Save Changes
                    </button>                    
                  </div>
                  <div className="col-auto">
                    <button 
                      type="reset" 
                      className="btn btn-secondary"
                      onClick={handleCancel}
                    >Cancel</button>
                  </div>
                </div>
                <hr/>
                <div className="row align-items-center">
                  <Form >
                    <FormGroup className="row">
                      <Label className="col-12 col-form-label text-alert">New Password</Label>
                      <div className="col-12">
                        <Input 
                          type="password" 
                          className="form-control form-control-lg form-control-solid" 
                          name="password"
                          value={password.password} 
                          placeholder="New password" 
                          onChange={handleChangePassword}
                        />
                      </div>
                    </FormGroup>            
                    <FormGroup className="row">
                      <Label className="col-12 col-form-label text-alert">Verify Password</Label>
                      <div className="col-12">
                        <Input 
                          type="password" 
                          className="form-control form-control-lg form-control-solid" 
                          name="passwordConfirmation"
                          value={password.passwordConfirmation} 
                          placeholder="Verify password" 
                          onChange={handleChangePassword}
                        />
                      </div>
                    </FormGroup>
                  </Form >
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>


    
  )
}

export default ResetPassword;