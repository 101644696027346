const Color = require('color');

const getButtonColor = (hex) => {
  const color = Color(hex);  
  const buttonColor = color.negate();
  const isLight = buttonColor.isLight();
  const button_text_color = isLight ? "#000000" : "#ffffff";
  const button_color = buttonColor.hex();
  return {button_color, button_text_color}
}

const getTextColor = (hex) => {
  if(hex) {
    const color = Color(hex);
    const isLight = color.isLight();
    const textColor = isLight ? "#000000" : "#ffffff";  
    return textColor;
  }

  return "#ffffff";
}

const getOpacityBackgroundColor = (hex) => {
  const color = Color(hex);
  const isLight = color.isLight();
  const backgroundColor = isLight ? "#00000060" : "#ffffff";  
  return backgroundColor;
}

export {getTextColor, getButtonColor, getOpacityBackgroundColor};