import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, TimePicker } from 'antd';

const TimeInput = (props) => {
  const {label, id, date, placeholder, handleTimeChange} = props;
  const [dateLabel, setDateLabel] = useState('')
  const [timeVal, setTimeVal] = useState(null);
  const handeInputChangePlaceholder = () => {};
  
  const onChange = (time, timeString) => {
    // setTimeVal(time);
    handleTimeChange(id, time, timeString);
  }

  useEffect(() => {    
    if(date) {
      // MM/DD/YYYY
      // const dateVal = toDate(date);
      const dateVal = moment(date);
      const dl = dateVal.format("MM/DD/YYYY");
      setDateLabel(dl);
      setTimeVal(dateVal);
    }
  }, [date])

  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
      <div style={{width: "160px", marginRight: "10px"}}>{label}</div>      
      <Input 
        style={{ width: '50%' }}
        disabled id={id} 
        placeholder={placeholder} 
        value={dateLabel} 
        size={"large"}
        onChange={handeInputChangePlaceholder}/>      
      <TimePicker 
        disabled={date === "" ? true : false} 
        format="HH:mm" 
        value={timeVal}
        onChange={onChange} 
        style={{ width: 200, marginLeft: "10px" }} 
        size="large"/>
    </div>
  )
}

export default TimeInput;