import React, {useState, useEffect} from 'react';
import {Input } from 'reactstrap';
import {platformBadges} from '../../libs'


const PlatformBadgesDropdown = (props) => {
  const {onSelect, value} = props;

  const handleChange = e => {
    const iconName = e.target.value;
    const {title, description, icon_color, icon_group, icon_name, placeholder} = platformBadges.filter((x) => x.icon_name === iconName)[0];
    onSelect({title, description, icon_color, icon_group, icon_name, placeholder} )
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const _options = platformBadges.map((option, idx) => <option key={(idx+1).toString()} value={option.icon_name}>{option.title}</option>)
    setOptions(_options);
  }, [])


  return (
    <Input 
      type="select" 
      name="selectMulti" 
      id="platformBadget" 
      onChange={handleChange}
      value={value}
    >
      {options}
    </Input>
  )
}

export default PlatformBadgesDropdown;