import React, { useEffect} from "react";
import { Route, Redirect } from 'react-router-dom'
import { useAtom } from 'jotai';
import { Layout } from 'antd';
// import '../assets/styles/admin.css';
// import '../assets/styles/devices.css';
// import '../assets/styles/dashboard.css';
import '../assets/fonts/feather/feather.min.css'
import '../assets/styles/theme.min.css';
import { authAtom, tryLocalSignin } from '../jotais'
import {HeaderMenus} from '../admin/components'

const AuthedRoute = ({ component: Component, ...rest }) => {
  // const {state, tryLocalSignin} = useContext(AuthContext)
  const [authUser] = useAtom(authAtom);
  const [, trylocalSignIn] = useAtom(tryLocalSignin);
  const { Content } = Layout;

  // 
  // if login, then redirect to main page
  useEffect(() => {
    trylocalSignIn()
  }, [])


  if(authUser.signedIn === undefined) {
    return null;
  } else if (authUser.signedIn === true) { 
    return (
      <Route {...rest} render={(props) => (
        <>
          <HeaderMenus/>
          <div className="main-content">
            <Component {...props} />
          </div>          
        </>
        // <Layout className="layout" className="dashboard-layout-color">
        //   <HeaderMenus/>
        //   <Content style={{ padding: '50px 10px'}}>
        //     <div>
        //       <Component {...props} />
        //     </div>            
        //   </Content> 
        // </Layout>
      )}/>    
    )
  } else {
    return <Redirect to='/signin' />
  }
  // return null;  
}

export default AuthedRoute;