import React from 'react';
import {ReactComponent as ApplePodcastSvg} from '../assets/img/icons/podcasts/apple_podcast.svg'
import {ReactComponent as GooglePodcastSvg} from '../assets/img/icons/podcasts/google_podcast.svg'
import {ReactComponent as BreakerSvg} from '../assets/img/icons/podcasts/breaker.svg'
import {ReactComponent  as CastboxSvg} from '../assets/img/icons/podcasts/castbox.svg'
import {ReactComponent as CastroSvg} from '../assets/img/icons/podcasts/castro.svg'
import {ReactComponent as OvercastSvg} from '../assets/img/icons/podcasts/overcast.svg'
import {ReactComponent as PocketcastSvg} from '../assets/img/icons/podcasts/pocketcast.svg'
import {ReactComponent as RadiopublicSvg} from '../assets/img/icons/podcasts/radiopublic.svg'
import {ReactComponent as SpotifySvg} from '../assets/img/icons/podcasts/spotify.svg'
import {ReactComponent as StitcherSvg} from '../assets/img/icons/podcasts/stitcher.svg'
import {ReactComponent as TuneinSvg} from '../assets/img/icons/podcasts/tunein.svg'
import {ReactComponent as RssSvg} from '../assets/img/icons/podcasts/rss.svg'
import {ReactComponent as KkboxSvg} from '../assets/img/icons/podcasts/kkbox.svg'
import {ReactComponent as BuyMeACoffee} from '../assets/img/icons/podcasts/buy_me_a_coffee.svg'
import {ReactComponent as IHeartRadio} from '../assets/img/icons/podcasts/iHeart.svg'
import {ReactComponent as Sonnet} from '../assets/img/icons/podcasts/sonnet.svg'
import {ReactComponent as PodcastRepublic} from '../assets/img/icons/podcasts/podcast_republic.svg'
import {ReactComponent as Podhero} from '../assets/img/icons/podcasts/podhero.svg'
import {ReactComponent as Podfriend} from '../assets/img/icons/podcasts/podfriend.svg'
import {ReactComponent as PodcastAddict} from '../assets/img/icons/podcasts/podcastaddict.svg'
import {ReactComponent as PlayerFM} from '../assets/img/icons/podcasts/player_fm.svg'
import {ReactComponent as AmazonMusic} from '../assets/img/icons/podcasts/amazon_music.svg'
import {ReactComponent as Patreon} from '../assets/img/icons/podcasts/patreon.svg'
import {ReactComponent as Kofi} from '../assets/img/icons/podcasts/kofi.svg'
import {ReactComponent as Paypal} from '../assets/img/icons/podcasts/paypal.svg'
import {ReactComponent as SquareCash} from '../assets/img/icons/podcasts/square_cash.svg'
import {ReactComponent as Venmo} from '../assets/img/icons/podcasts/venmo.svg'

// const dicts = {
//   "apple_podcast": ApplePodcastSvg,
//   'breaker': BreakerSvg,
//   'google_podcast': GooglePodcastSvg,
//   'castbox': CastboxSvg,
//   'castro': CastroSvg,
//   'overcast': OvercastSvg,
//   'pocketcast': PocketcastSvg,
//   'radiopublic': RadiopublicSvg,
//   'spotify': SpotifySvg,
//   'stitcher': StitcherSvg,
//   'tunein': TuneinSvg,
//   'rss': RssSvg
// }

const PodcastsIcon = ({name, color, width, height}) => {
  const style={width: width ? width : "20px", height: height ? height :"20px"};

  switch(name) {
    case 'apple_podcast':    
      return <ApplePodcastSvg style={style}/>
    case 'google_podcast':    
      return <GooglePodcastSvg style={style}/>
    case 'breaker':    
      return <BreakerSvg style={style}/>
    case 'castbox':    
      return <CastboxSvg style={style}/>            
    case 'castro':    
      return <CastroSvg style={style}/>
    case 'overcast':    
      return <OvercastSvg style={style}/>
    case 'pocketcast':    
      return <PocketcastSvg style={style}/>            
    case 'radiopublic':    
      return <RadiopublicSvg style={style}/>
    case 'spotify':    
      return <SpotifySvg style={style}/>
    case 'stitcher':    
      return <StitcherSvg style={style}/>            
    case 'tunein':    
      return <TuneinSvg style={style}/>
    case 'kkbox':
      return <KkboxSvg style={style}/>      
    case 'rss':    
      return <RssSvg style={style}/>
    case 'buy_me_a_coffee':
      return <BuyMeACoffee style={style}/>
    case "iHeart": 
      return <IHeartRadio style={style}/>
    case "sonnet":
      return <Sonnet style={style}/>
    case "podcast_republic":
      return <PodcastRepublic style={style}/>
    case "pod_hero":
      return <Podhero style={style}/>
    case "pod_friend":
      return <Podfriend style={style}/>
    case "podcast_addict":
      return <PodcastAddict style={style}/>
    case "player_fm":
      return <PlayerFM style={style}/>
    case "amazon_music":
        return <AmazonMusic style={style}/>   
    case 'patreon':   
      return <Patreon style={style}/>   
    case 'kofi':   
      return <Kofi style={style}/>      
    case 'paypal':   
      return <Paypal style={style}/>               
    case 'square_cash':   
      return <SquareCash style={style}/>
    case 'venmo':
      return <Venmo style={style}/>
    default:
      return null;
  }    
  // console.log(color)
  // if(name && dicts[name]) {        
  //   return <img src={dicts[name]} name={name} style={{maxWidth: "26px", fill: color}}/>
  // }
  // return null;
}

export default PodcastsIcon