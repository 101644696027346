import React, {useEffect} from "react";
import {Form,FormGroup, Card, CardBody, Label, Input} from 'reactstrap'
import {useFetchProject} from '../../hooks'
import {PageHeader} from '../components'
import { Link } from "react-router-dom";

const ProjectSettings = (props) => {
  const id = props.match.params.id;
  const [project, setProject, loading, updateProject] = useFetchProject(id);

  const handleOnChange = (event) => {
    const {name, value} = event.target;
    setProject({...project, [name]: value});
  }

  const handleSave = () => {
    updateProject()
  }

  if(loading === false) {
    return (
      <>
        <PageHeader title="Settings"/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardBody>
                  <div className="row align-items-center">
                    <div className="col ml-n2">
                      <h3 className="card-label font-weight-bolder text-dark">Embed &#38; Sharing Analytics</h3>                      
                    </div>
                    <div className="col-auto">
                      <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={handleSave}
                      >
                          Save Changes
                      </button>                    
                    </div>
                    <div className="col-auto">
                      <Link to={`/`} className="btn btn-secondary">Cancel</Link>                      
                    </div>                    
                  </div>
                  <hr/>                                    
                  <div>
                    <h4>Google Analytics</h4>
                    <p>Track page views of embeddable players and social sharing pages on Google Analytics.</p>
                    <Input 
                      placeholder="UA-XXXXXX-1" 
                      value={project.google_analytics_id ? project.google_analytics_id : ""} 
                      name="google_analytics_id"
                      onChange={handleOnChange} 
                    />
                  </div>
                  <br/>
                  <div>
                    <h4>Facebook Pixel</h4>
                    <p>Track page views of embeddable players and social sharing pages on Facebook pixel.</p>
                    <Input 
                      placeholder="123451235"
                      name="facebook_pixel_id"
                      value={project.facebook_pixel_id ? project.facebook_pixel_id : ""} 
                      onChange={handleOnChange}
                    />
                  </div>                  
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    )
  }

  return null;
}

export default ProjectSettings;