import React, {useState} from "react";
import { Link } from 'react-router-dom';
import { FileAddOutlined, UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import { Layout, Menu, Icon } from 'antd';
import { useAtom } from 'jotai';
import { signout } from '../../jotais'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import logo from '../../assets/img/icons/logo.svg'

const { Header } = Layout;
const { SubMenu } = Menu;

const HeaderMenus = () => {
  const [_, signOutClick] = useAtom(signout);
  const [isOpen, setIsOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isOpenUser, setIsOpenUser] = useState(false)

  const toggle = () => setIsOpen(!isOpen);
  const toggleNavbar = () => setCollapsed(!collapsed);
  

  return (
    <Navbar className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
      <div className="container-fluid">

        <NavbarBrand to="/" tag={Link}>
          <img src={logo} className="avbar-brand-img mx-auto"/>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem><NavLink to="/" tag={Link}><i className="fe fe-home"/>Home</NavLink></NavItem>
            <NavItem><NavLink to="/project-setup" tag={Link}>
              <i className="fe fe-file-plus"/>Add new project
            </NavLink></NavItem>
            <NavItem><NavLink to="/integrations" tag={Link}>
              <i className="fe fe-cpu"/>Integrations
            </NavLink></NavItem>
            <NavItem><NavLink to="/change-password" tag={Link}><i className="fe fe-user"/>Reset password</NavLink></NavItem>
            <NavItem><NavLink onClick={signOutClick}><i className="fe fe-log-out"/>Logout</NavLink></NavItem>               
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
  // return (
  //   <Header>
  //     <div className="logo" />
  //     <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
  //       <Menu.Item key="1">
  //         <Link to="/dashboard" style={{
  //           textDecoration: 'none'
  //         }}>
  //           Home
  //         </Link>                     
  //       </Menu.Item>
  //       <Menu.Item key="2">
  //         <Link to="/project-setup" style={{
  //           textDecoration: 'none'
  //         }}>
  //           + Add new project
  //         </Link>          
  //       </Menu.Item>
    
       
  //       <SubMenu 
  //         style={{float: 'right'}}
  //         key="sub1" 
  //         icon={<UserOutlined />} 
  //         title=""
  //       >
  //         <Menu.Item key="3">
  //           <Link to="/integrations" style={{
  //             textDecoration: 'none'
  //           }}>
  //             Integrations
  //           </Link>          
  //         </Menu.Item>          
  //         <Menu.Item key="4">
  //           <Link to="/change-password" style={{
  //             textDecoration: 'none'
  //           }}>
  //             Reset Password
  //           </Link> 
  //         </Menu.Item>              
  //         <Menu.Item key="5" onClick={signOutClick}>
  //           Logout
  //         </Menu.Item>
  //       </SubMenu>      
  //     </Menu>
  //   </Header>
  // )
}

export default HeaderMenus;