import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'
import {CPIcon} from '../../libs'

const PlatformBadgeBlock = (props) => {
  const {id, text_color, button_color, main_type, modal_title, sub_type, name, title, description, image_url, icon_group, icon_name, link_value, demoView} = props;
  const [, toggle] = useAtom(toggleBuilderModal);

  const cssStyle = {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px", 
    display: "flex", 
    alignItems: 'center',
    width: demoView ? "320px" : null,
    minHeight: "60px",
    borderRadius: "10px",
    backgroundColor: button_color,
    color: `${text_color}`,
    borderColor: `${text_color}`,
  }
  
  const handleClick = () => {
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }

  if(demoView) {
    return (
      <div style={{...cssStyle,         
        boxShadow: '5px 10px 8px 10px #C1C1BD'
      }}>        
        <CPIcon icon_group={icon_group} icon_name={icon_name} icon_color={"#ffffff90"}/>
        <div style={{marginLeft: '10px'}}>
          {description ? <div style={{fontSize: "16px"}}>{description}</div> : null}
          {title ? <div style={{fontSize: "18px", fontWeight: '700'}}>{title}</div> : null}
        </div>        
      </div> 
    )
  }


  return (
    <div style={cssStyle} onClick={handleClick}>
      <CPIcon icon_group={icon_group} icon_name={icon_name} icon_color={"#ffffff90"}/>
      <div style={{marginLeft: '10px'}}>
        {description ? <div style={{fontSize: "16px"}}>{description}</div> : null}
        {title ? <div style={{fontSize: "18px", fontWeight: '700'}}>{title}</div> : null}
      </div>  
    </div>
  )
}

export default PlatformBadgeBlock;