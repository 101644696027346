import React from 'react';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'
import CreateButton from './CreateButton'

const AddMessengersButton = (props) => {
  const {width, type, title, name, buttonLabel} = props;

  const [, toggle] = useAtom(toggleBuilderModal);
  const {t, i18n} = useTranslation();

  // return (
  //   <CreateButton onClick={() => {
  //     toggle({type: 'MESSENGERS', title: 'MESSENGERS'});
  //   }}>
  //     <div>+ {t('Add Messengers.1')}</div>
  //   </CreateButton>
  // )

  return (
    <CreateButton onClick={() => {
      toggle({type, title, name});
    }} width={width}>
      <div>+ {buttonLabel ? t(buttonLabel) : ''}</div>
    </CreateButton>
  )  
}

export default AddMessengersButton