import React from 'react';
import { useAtom } from 'jotai';
import { getPodcastPies, builderAtom, getPodcastsBackgroundColor } from '../../jotais'
import { PodcastNetworkBlock, PodcastNetworksSection} from '../BuilderBlock'

const PodcastNetworksBlock = () => {

  const [pies] = useAtom(getPodcastPies);
  const [builder] = useAtom(builderAtom);
  const [backgroundColor] = useAtom(getPodcastsBackgroundColor);
  const piesCount = pies.length;
  const {text_color, button_color, button_text_color} = builder;
  // const flexBasis = (pies.length % 2 === 1 ? );

  if(pies.length === 0) return null;
  
  return (
    <PodcastNetworksSection
      pies={pies}
      backgroundColor={button_color}
      color={button_text_color}
    >
      <>
          {
            pies.map((pie, idx) => (
              <PodcastNetworkBlock 
                pos={idx} 
                key={idx}
                value={pie.value}
                title={pie.placeholder} 
                name={pie.name} 
                piesCount={piesCount}  
                icon_group={pie.icon_group}
                icon_name={pie.icon_name}   
                // icon_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.icon_color : text_color}
                button_color={`${backgroundColor}`} 
                icon_color={text_color}
              /> 
            ))
          }       
      </>
    </PodcastNetworksSection>    
  )
}

export default PodcastNetworksBlock;