import React from 'react';
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'
import {getTextColor} from '../../libs'
import { Image } from 'antd';
import {CountDownCallToActionButton, CountDownTimer} from '../Misc'

const CountDownBlock = (props) => {
  const {id, title, description, link_value, price_tag, price_tag_background_color,
    countdown_from_date, countdown_to_date, schedule_from_date,
    image_url, modal_title, main_type, name, demoView, button_color, button_text_color
  } = props;

  const [, toggle] = useAtom(toggleBuilderModal);

  const handleClick = () => {
    if(demoView) return null;
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }  

  const containerStyle = {
    borderRadius: "12px",
    minHeight: "120px",
    width: demoView ? "420px" : null,
    backgroundColor: button_color,
    color: button_text_color,
    cursor: 'pointer',
    boxShadow: demoView ? '5px 10px 8px 10px #C1C1BD' : null      
  }

  const backgroundImageStyle = {
    display: 'flex',
    justifyContent: 'center'
  }

  return (
    <div style={containerStyle} onClick={handleClick}>
      {image_url ? <div style={backgroundImageStyle}><Image 
        preview={false}
        style={{borderRadius: `12px 12px 0 0`}}
        src={image_url}/></div> : null}
      <div style={{padding: "10px"}} color={button_text_color}>
        {title ? <div style={{fontSize: "18px", fontWeight: '700'}}>{title}</div> : null}
        {description ? <p style={{fontSize: "14px"}}>{description}</p> : null}        
        <CountDownTimer countdown_from_date={countdown_from_date} countdown_to_date={countdown_to_date}/>
      </div>      
      <div style={{padding: "10px"}}>
        <CountDownCallToActionButton price_tag={price_tag} price_tag_background_color={price_tag_background_color}/>
      </div>
    </div>
  )
}

export default CountDownBlock;