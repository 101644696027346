import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ReactComponent as CardBackgroundImage} from '../assets/img/icons/buttons/card_background_image.svg'
// import card_background_image from '../assets/img/icons/buttons/card_background_image.svg'
import {ReactComponent as CardFullImage} from '../assets/img/icons/buttons/card_full_image.svg'
import {ReactComponent as CardThumbnailImage} from '../assets/img/icons/buttons/card_thumbnail.svg'

const ButtonIcon = ({name, color}) => {
  const style={width: "20px", height: "20px", color};

  switch(name) {
    case 'BACKGROUND_BUTTON':
      return <CardBackgroundImage style={style}/>
    case 'CARD_BUTTON':
      return <CardFullImage style={style}/>
    case 'THUMBNAIL_BUTTON':
      return <CardThumbnailImage style={style}/>
    case 'VIDEO_BUTTON':
      return <FontAwesomeIcon size="xs" icon={['fab', 'youtube']} color="black" style={style}/>       
    default:
      return null;
  }
}

export default ButtonIcon;