const platformBadges = [
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,    
    title: 'Apple Podcasts',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'apple_podcast',
    icon_color: 'black',
    placeholder: 'Apple Podcast',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Google Podcasts',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'google_podcast',
    icon_color: 'black',
    placeholder: 'Google Podcast',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Castbox',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'castbox',
    icon_color: 'black',
    placeholder: 'Castbox',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Overcast',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'overcast',
    icon_color: 'black',
    placeholder: 'Overcast',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Pocketcast',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'pocketcast',
    icon_color: 'black',
    placeholder: 'Pocketcast',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'RadioPublic',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'radiopublic',
    icon_color: 'black',
    placeholder: 'RadioPublic',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Spotify',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'spotify',
    icon_color: 'black',
    placeholder: 'Spotify',
    value: "",
    name: "MAIN-BLOCK"
  },  
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true, 
    title: 'TuneIn',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'tunein',
    icon_color: 'black',
    placeholder: 'TuneIn',
    value: "",
    name: "MAIN-BLOCK"
  },
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'KKBOX',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: 'Listen on',
    icon_group: 'podcasts', 
    icon_name: 'kkbox',
    icon_color: 'black',
    placeholder: 'KKBOX',
    value: "",
    name: "MAIN-BLOCK"
  },     
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Patreon',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: 'Become a',
    icon_group: 'fab', 
    icon_name: 'patreon',
    icon_color: 'black',
    placeholder: 'Patreon',
    value: "",
    name: "MAIN-BLOCK"
  },  
  {
    main_type: 'PLATFORM-BADGE',
    sortable: true,
    title: 'Buy me a coffee',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: 'True Supporter',
    icon_group: 'podcasts', 
    icon_name: 'buy_me_a_coffee',
    icon_color: 'black',
    placeholder: 'Buy me a coffee',
    value: "",
    name: "MAIN-BLOCK"
  },         
]

export default platformBadges;
