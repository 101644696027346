import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileUpload} from '../BuilderBlock'

const buttonStyle = {
  cursor: 'pointer',
  borderRadius: '40px',
  borderWidth: '1px'
}

const CountDownImageUploadButton = (props) => {

  const {artwork_cdnUrl, openFileUpload, widgetApi, handleChanged, handleRemoveImage} = props;

  if(artwork_cdnUrl) {
    return (
      <div onClick={handleRemoveImage}
        style={{...buttonStyle, 
          marginTop: '20px',
          background: `url(${artwork_cdnUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: '#00000080',
          borderRadius: '40px',
          borderWidth: '1px',
          padding: "10px"          
        }}>
          <FontAwesomeIcon icon={['fa', 'image']} color="white" size="lg"/>
          <div style={{fontSize: '20px', color: 'white'}}>Remove Image</div>
        </div>
      </div>
    )
  } else if (openFileUpload && widgetApi && handleChanged){
    return (
      <FileUpload widgetApi={widgetApi} handleChanged={handleChanged}>
        <div onClick={openFileUpload} 
          style={{...buttonStyle,
            padding: '10px', 
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',            
          }}
          className="count-down-upload-image-button"
        >
          <FontAwesomeIcon icon={['fa', 'image']} color="white" size="lg"/>
          <div style={{fontSize: '20px', color: 'white'}}>Upload Image</div>
        </div> 
      </FileUpload>   
    )
  } else {
    return null;
  }
  
}

export default CountDownImageUploadButton;