import { atom } from "jotai";
import {builderAtom} from './builderAtom'
import { notificationAtom } from './notificationAtom'
import { 
  getRandomId,
  setLocalStorage, 
  reorderArray,
  getDefaultPies
} from './../libs'
import railsApi from '../api/railsApi'

const PIE = {
  id: '',
  // main_type: ''
  main_type: '', // determine the modal
  sub_type: '', // cards have different option
  modal_title: '',
  value: '',
  name: '',  // determine the display blocks
  sortable: true,
  sort_order: 0,  
  title: '',
  title_text_color: '',  
  link_value: '',
  background_color: '',
  image_url: '',
  description: '',
  button_text: '',
  success_message: '',
  integration_id: '',
  mailing_list_id: '',
  campaign_tag: '',
  icon_group: '',
  icon_name: '',
  icon_color: '',
  placeholder: '',
  price_tag: '',
  price_tag_background_color: '',
  price_tag_text_color: '',
  display_effect_options: false,
  is_pop: false,
  is_hidden: false,  
  schedule_from_date: null,
  schedule_to_date: null,
  schedule_from_date_str: null,
  schedule_to_date_str: null,  
  schedule_time_zone: null,
  countdown_from_date_str: null,
  countdown_to_date_str: null,    
  countdown_from_date: null,
  countdown_to_date: null,  
  countdown_time_zone: null,
  artwork_uuid: null,
  artwork_cdnUrl: null,
  artwork_originalUrl: null,
  artwork_mime_type: null,
  social_username: null,
  title_font_size: null,
  description_font_size: null
}

// we are directly updating the updated_at date in our local storage
// this is a hack, because josh does not know how to set the builder atom updated_at
const refreshBuilderLocalStorageUpDate = (localStorageId) => {
  const dateVal = (new Date()).valueOf()
  setLocalStorage(`${localStorageId}_updated_at`, dateVal);
}

export const piesAtom = atom([]);

export const piesAtomWithPersistence = atom(null, (get, set, id) => {
  const localStorageId = id ? `${id}_builder_pies` : 'draft_builder_pies';  
  const persistenceState = localStorage.getItem(localStorageId);
  const localPiesUpdatedAt = localStorage.getItem(`${localStorageId}_updated_at`);
  // draft_builder_pies_updated_at
  const defaultPies = getDefaultPies();
  
  const piesLocalData = persistenceState ? JSON.parse(persistenceState) : defaultPies;

  if(id) {
    railsApi.get(`/v1/projects/${id}/pies`)
    .then((res) => {
      const data = res.data;    
            
      const serverPiesUpdatedAt = data.length > 0 ? Math.max(...(data.map((x) => new Date(x.updated_at)))) : 0;
      // save the server pies max date to local storage
      
      setLocalStorage(`${localStorageId}_server_copy_updated_at`, serverPiesUpdatedAt);
      if(piesLocalData.length === 0 && data.length === 0) {
        // this should not happen, but who knows         
        setLocalStorage(localStorageId, piesLocalData); // add to local storage
        set(piesAtom, () => {
          return piesLocalData;
        })        
      } else if(data.length > 0 && piesLocalData.length > 0 && localPiesUpdatedAt && Number(localPiesUpdatedAt) >= serverPiesUpdatedAt) {
        // use the local copy
        set(piesAtom, () => {
          return piesLocalData;
        })
      } else {
        // use the server copy
        setLocalStorage(localStorageId, data); // add to local storage
        set(piesAtom, () => {
          return data;
        })        
      }
    })
    .catch((err) => {
      // TODO: ERROR HANDLE
      // Redirect back to home page and send modal saying having issue
      // it can be unauthorized (401)
      const data = get(notificationAtom);
      set(notificationAtom, () => {
        return {...data, 
          status: true, 
          message: `Project not found`, 
          description: `Please try again or send us an email to report issue.`,
          redirect: true,
          redirectTo: '/dashboard',
          type: 'error'
        }
      })
      // console.log(err.response.status);
    })
  } else {
    // Draft
    // builderLocalData
    setLocalStorage(localStorageId, piesLocalData)
    set(piesAtom, () => {
      return piesLocalData;
    })    
  }  

  // set(piesAtom, () => { 
  //   // const currentState = get(builderAtom);
  //   const persistenceState = localStorage.getItem(localStorageId);
  //   if(persistenceState) {
  //     const pies = JSON.parse(persistenceState);
  //     return pies;
  //   } else {
  //     const data = getDefaultPies(); 
  //     return data;
  //   }
  // })
})

export const addPie = atom(null, (_get, set, pie) => {
  // we use this in the podcasts networks, messengers, and social networks pies
  const builder = _get(builderAtom);

  set(piesAtom, () => {
    const currentState = _get(piesAtom);
    // console.log(Math.max(...(currentState.map((cur) => cur.sort_order))))
    const sort_order = currentState.length > 0 ? (Math.max(...(currentState.map((cur) => cur.sort_order))) + 1) : 1;
    const _pie = {...PIE, ...pie, sort_order };
    const newData = [...currentState, _pie];
    
    if(builder.localStorageId){
      const localStorageId = `${builder.localStorageId}_pies`;
      setLocalStorage(localStorageId, newData);
      refreshBuilderLocalStorageUpDate(localStorageId);
    }

    return newData;
  })

  set(builderAtom, () => {
    return {...builder, isEditing: true}
  })
})

export const updatePie = atom(null, (_get, set, pie) => {
  const builder = _get(builderAtom);

  set(piesAtom, () => {    
    const currentState = _get(piesAtom);
    const items = currentState.map((item) => {      
      if(item.id === pie.id) {        
        return {...item, ...pie};
      } else{
        return item;
      }
    })

    if(builder.localStorageId){
      const localStorageId = `${builder.localStorageId}_pies`;
      setLocalStorage(localStorageId, items);
      refreshBuilderLocalStorageUpDate(localStorageId);
    } 
    
    return items;
  })

  set(builderAtom, () => {
    return {...builder, isEditing: true}
  })   
})

export const deletePie = atom(null, (_get, set, pieId) => {
  const builder = _get(builderAtom);

  set(piesAtom, () => {
    const currentState = _get(piesAtom);
    const items = currentState.filter((item) => item.id !== pieId);

    if(builder.localStorageId){
      const localStorageId = `${builder.localStorageId}_pies`;
      setLocalStorage(localStorageId, items);
      refreshBuilderLocalStorageUpDate(localStorageId);
    }

    return items;
  }) 
  
  set(builderAtom, () => {
    return {...builder, isEditing: true}
  })   
})

export const sortPies = atom(null, (_get, set, input) => {
  // input = sourcePieId, destinationPieId
  const {sourcePieId, destinationPieId} = input;
  const builder = _get(builderAtom);

  set(piesAtom, () => {
    const currentState = _get(piesAtom);
    let destinationPieIdx = -1;
    let sourcePieIdx = -1;
    let finding = true;
    let i = 0;
    while( i < currentState.length && finding) {
      if(currentState[i]['id'] === sourcePieId) {
        sourcePieIdx = i;
      }
      if(currentState[i]['id'] === destinationPieId) {
        destinationPieIdx = i;
      }
      if(destinationPieIdx > -1 && sourcePieIdx > -1) finding = false;
      i+=1;
    }

    // const items = [...currentState];    
    // const [sourcePie] = items.splice(sourcePieIdx, 1); // aka reorderedItem
    // items.splice(destinationPieIdx, 0, sourcePie)

    const items = reorderArray(currentState, sourcePieIdx, destinationPieIdx)
    const orders = items.map((x) => x.sort_order);
    const sortOrders = orders.sort((a, b) => a - b);
    const itemsSort = [];

    items.forEach((element, idx) => {
      element.sort_order = sortOrders[idx];
      itemsSort.push(element)
    });
    // const itemsSort = items.map((item, idx) => item.sort_order = sortOrders[idx])
        
    if(builder.localStorageId){
      const localStorageId = `${builder.localStorageId}_pies`;
      setLocalStorage(localStorageId, items);
      refreshBuilderLocalStorageUpDate(localStorageId);
    }

    return items;
  })

  set(builderAtom, () => {
    return {...builder, isEditing: true}
  })   
})

export const getPieById = atom((get) => {
  const {modal_data_id} = get(builderAtom);
  const currentState = get(piesAtom);

  if(modal_data_id) {
    const options = currentState.filter((x) => x.id === modal_data_id);
    if(options.length > 0) {
      return options[0];
    } else {
      return {...PIE, id: (-1 * getRandomId()), title: "", description: "", display_effect_options: true}
    }
  } else {
    return {...PIE, id: (-1 * getRandomId()), title: "", description: "", display_effect_options: true}
  }
})

export const getModalPies = atom((get) => {
  const {modal_data_name} = get(builderAtom);
  const currentState = get(piesAtom);
  if(modal_data_name) {
    return currentState.filter((x) => x.main_type === modal_data_name);
  }
  return [];  
})

export const unionPodcastNetworksPies = atom(null, (_get, set, newPies) => {
  // O(n^2) bad, if someone smart, please fix it. //12/12/2021 - josh chen

  const builder = _get(builderAtom);
  const currentState = _get(piesAtom);
  const pies = [];
  for(let i = 0; i < currentState.length; i++) {
    const pie = currentState[i];
    const {link_value, name, main_type} = pie;
    // main_type: 'PODCAST-NETWORK'
    if(main_type === 'PODCAST-NETWORK') {
      let j = 0;
      while(j < newPies.length) {
        const newPie = newPies[j];        
        if(name === newPie['name']) {
          if((link_value === null || link_value.length === 0 || link_value === "")) {
            pie['link_value'] = newPie['link_value'];
          }
          newPies.splice(j, 1);
        }
        j+=1;
      }
    }
    pies.push(pie)
  }
  const newData = [...pies, ...newPies];
  
  set(piesAtom, () => {
    if(builder.localStorageId){
      const localStorageId = `${builder.localStorageId}_pies`;
      setLocalStorage(localStorageId, newData);
      refreshBuilderLocalStorageUpDate(localStorageId);
    }      
    return newData;
  })
})

export const cudPie = atom(null, (_get, set, pie) => {
  const builder = _get(builderAtom);
  
  set(piesAtom, () => {
    const currentState = _get(piesAtom);
    // based on pie id to look up if the pie has been include, then we can do one of the 3 things.
    // CREATE, UPDATE, OR DELETE
    const _pie = currentState.filter((x) => x.id === pie.id);
    
    if(_pie.length === 0) {
      // CREATE
      // updateOpeningModalPieId(pie.id);
      // const [, addModalPieId] = updateOpeningModalPieId;
      // console.log(addModalPieId)
      if( pie.artwork_cdnUrl === null && (pie.countdown_from_date_str === null || pie.countdown_to_date === null) && pie.title === "" && (pie.social_username === null || pie.social_username === "") && pie.link_value === "" && (pie.price_tag === null || pie.price_tag === "") && pie.image_url === "" && pie.description === "" && (pie.icon_group === "" || pie.icon_name === "")) {
        return currentState;
      }

      const sort_order = currentState.length > 0 ? (Math.max(...(currentState.map((cur) => cur.sort_order))) + 1) : 1;      
      const newPie = {
        ...PIE, 
        ...pie, 
        sort_order, 
        main_type: builder.modal_type, 
        name: builder.modal_data_name,
        display_effect_options: true
      };
      
      const newData = [...currentState, newPie];
      if(builder.localStorageId){
        const localStorageId = `${builder.localStorageId}_pies`;
        setLocalStorage(localStorageId, newData);
        refreshBuilderLocalStorageUpDate(localStorageId);
      }      
      return newData;
    } else {
      // UPDATE OR DELETE
      if( pie.artwork_cdnUrl !== null || (pie.countdown_from_date_str !== null && pie.countdown_to_date !== null) || pie.title.length > 0 || pie.description.length > 0 || (pie.social_username && pie.social_username.length > 0) || pie.link_value.length > 0 || (pie.iamge_url && pie.image_url.length > 0) || (pie.price_tag && pie.price_tag.length > 0) || (pie.icon_group && pie.icon_name && pie.icon_group.length > 0 && pie.icon_name.length > 0)) {
        const items = currentState.map((item) => {      
          if(item.id === pie.id) {        
            return {...item, ...pie};
          } else{
            return item;
          }
        })

        if(builder.localStorageId){
          const localStorageId = `${builder.localStorageId}_pies`;
          setLocalStorage(localStorageId, items);
          refreshBuilderLocalStorageUpDate(localStorageId);
        }

        return items;
      } else {
        // DELETE
        const items = currentState.filter((item) => item.id !== pie.id);

        if(builder.localStorageId){
          const localStorageId = `${builder.localStorageId}_pies`;
          setLocalStorage(localStorageId, items);
          refreshBuilderLocalStorageUpDate(localStorageId);
        }  

        return items;
      }        
    }
  })

  set(builderAtom, () => {
    return {...builder, isEditing: true}
  })     
})

export const getHeaderTitle = atom((get) => {  
  // console.log(builder)
  const currentState = get(piesAtom)
  return currentState.filter((x) => x.main_type === 'HEADER-TITLE');
})

// export const getHeaderDescription = atom((get) => {
//   const currentState = get(piesAtom)
//   return currentState.filter((x) => x.main_type === 'HEADER-TITLE' && x.name === 'description');
// })

export const getAuthors = atom((get) => {
  const currentState = get(piesAtom);
  return currentState.filter((x) => x.main_type === 'AUTHORS' && (x.title && x.title.length > 0));
})

export const getMessengerPies = atom((get) => {  
  // console.log(builder)
  const currentState = get(piesAtom)
  return currentState.filter((x) => x.main_type === 'MESSENGERS' && ((x.link_value && x.link_value.length > 0) || (x.social_username && x.social_username.length > 0)));
})

export const getSocialNetworkPies = atom((get) => {
  const currentState = get(piesAtom)  
  return currentState.filter((x) => x.main_type === 'SOCIAL-NETWORKS' && ((x.link_value && x.link_value.length > 0) || (x.social_username && x.social_username.length > 0)));
})

export const getPodcastPies = atom((get) => {
  const currentState = get(piesAtom)  
  // console.log(currentState)
  return currentState.filter((x) => x.main_type === 'PODCAST-NETWORK' && x.link_value && x.link_value.length > 0);
})

export const getSortedMainPies = atom((get) => {
  const currentState = get(piesAtom);
  const items =  currentState.filter((x) => x.name === 'MAIN-BLOCK');
  if(items.length > 0) {
    const sortedItems = items.sort((a, b) => a.sort_order - b.sort_order );
    return sortedItems;
  }

  return items;
})


export default {}