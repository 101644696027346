import React from 'react';
import {useTranslation} from 'react-i18next'

const cssStyle = {
  backgroundColor: "white", 
  height: "80px", 
  width: '100%',
  marginTop: '20px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',  
  alignItems: 'center',    
}

const publishButtonStyle = {
  backgroundColor: "#07f",
  width: '80%',
  height: '40px',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}

const FooterMenuPublishButton = ({handleClick}) => {
  const {t} = useTranslation();
  
  return (
    <div style={cssStyle}>
      <div style={publishButtonStyle} name="publish" onClick={handleClick}>
        <div style={{color: 'white', fontWeight: 500, fontSize: '1rem'}}>{t("PUBLISH.1")}</div>
      </div>
    </div>  
  )
}

export default FooterMenuPublishButton;