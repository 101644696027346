import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import {DateRangePicker} from './../SimpleDatePicker'
import {DemoBlockPlaceholder, CountDownTextInputs, CountDownImageUploadButton} from './../Misc'
import {CountDownBlock} from '../BuilderBlock'
import {SpecialEffectIndicator} from '../BuilderPie'
import {changeDateTimeZone} from '../../libs'

const CountDownModalBody = () => {
  const widgetApi = useRef();
  // const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);
  const {button_color, button_text_color} = builder;
  // const [colors, setColors] = useState([{ id: 1,solid_color: "#4cd963"}, { id: 1,solid_color: "#eb5757"}, { id: 1,solid_color: "#f2c84c"}]);

  const {id, title, description, link_value, price_tag, price_tag_background_color,
    countdown_from_date, countdown_to_date, countdown_time_zone,
    countdown_from_date_str, countdown_to_date_str, artwork_cdnUrl
  } = pie;

  const timeZone = countdown_time_zone ? countdown_time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleInputChange = (event) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    const name = event.target.name;
    const value = event.target.value;    
    createUpdateDeletePie({...pie, [name]: value, modal_title})
  }  

  const handleDateChange = (range) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }

    const modal_title = builder.modal_title;    
    const {from, to} = range;
    
    // strs
    const countdownFromDateStr = from;
    const countdownToDateStr = to;
    // const scheduleFromDateStr = from;
    // const scheduleToDateStr = to;

    const countdownFromDate = from ? changeDateTimeZone(from, timeZone) : null;
    const countdownToDate = to ? changeDateTimeZone(to, timeZone) : null;
    // const scheduleFromDate = from ? changeDateTimeZone(from, timeZone) : null;
    // const scheduleToDate = to ? changeDateTimeZone(to, timeZone) : null;
    
    createUpdateDeletePie({...pie, 
      modal_title, 
      countdown_from_date: countdownFromDate, 
      countdown_to_date: countdownToDate, 
      countdown_from_date_str: countdownFromDateStr, 
      countdown_to_date_str: countdownToDateStr, 
      countdown_time_zone: timeZone,

      // schedule_from_date: scheduleFromDate, 
      // schedule_to_date: scheduleToDate,      
      // schedule_from_date_str: scheduleFromDateStr, 
      // schedule_to_date_str: scheduleToDateStr,          
      // schedule_time_zone: timeZone
    })
  }

  const handleTimeZone = (x) => {
    if(x && x.value) {
      const pieID = pie.id;
      if(builder.modal_data_id !== pieID) {      
        updateModalId(pieID)
      }
      const modal_title = builder.modal_title;
      
      const countdownTimeZone = x.value;

      const countdownFromDate = changeDateTimeZone(countdown_from_date_str, countdownTimeZone)
      const countdownToDate = changeDateTimeZone(countdown_to_date_str, countdownTimeZone)
      // const scheduleFromDate = changeDateTimeZone(countdown_from_date_str, countdownTimeZone)
      // const scheduleToDate = changeDateTimeZone(countdown_to_date_str, countdownTimeZone)
      
      createUpdateDeletePie({...pie, 
        modal_title, 
        countdown_from_date: countdownFromDate,
        countdown_to_date: countdownToDate,
        countdown_time_zone: countdownTimeZone
        // schedule_from_date: scheduleFromDate,
        // schedule_to_date: scheduleToDate,
        // schedule_time_zone: countdownTimeZone,         
      })
    }
  }
  
  const openFileUpload = () => {
    widgetApi.current.openDialog()
  }    

  const handleUploaded = (data) => {
    // console.log('hi')
    const {cdnUrl, originalUrl, uuid, mimeType} = data;
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }

    const modal_title = builder.modal_title;
    createUpdateDeletePie({
      ...pie, 
      artwork_uuid: uuid, 
      artwork_cdnUrl: cdnUrl, 
      artwork_originalUrl: originalUrl, 
      artwork_mime_type: mimeType,
      modal_title
    })
  }

  const handleRemoveImage = () => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }

    const modal_title = builder.modal_title;
    createUpdateDeletePie({
      ...pie, 
      artwork_uuid: null, 
      artwork_cdnUrl: null, 
      artwork_originalUrl: null, 
      artwork_mime_type: null,
      modal_title
    })
  }

  const handleColorPicked = (value) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;  
    createUpdateDeletePie({...pie, price_tag_background_color: value, modal_title})        
  }  

  return (
    <>
      <DateRangePicker
        fromDate={countdown_from_date_str}
        toDate={countdown_to_date_str}
        handleDateChange={handleDateChange}
        pickedTimeZone={timeZone}
        handleTimeZone={handleTimeZone}
      />
      <div style={{marginBottom: '20px'}}>
        <CountDownImageUploadButton 
          artwork_cdnUrl={artwork_cdnUrl}
          widgetApi={widgetApi} 
          handleRemoveImage={handleRemoveImage}
          handleChanged={handleUploaded}
          openFileUpload={openFileUpload}/>    
      </div>
      <CountDownTextInputs 
        title={title} 
        description={description}
        link_value={link_value}
        price_tag={price_tag}
        price_tag_background_color={price_tag_background_color}
        handleColorPicked={handleColorPicked}
        handleInputChange={handleInputChange} 
      />
      <DemoBlockPlaceholder is_pop={pie.is_pop}>
        <SpecialEffectIndicator is_hidden={false} schedule_from_date={false}>
          <CountDownBlock
            button_color={button_color} 
            button_text_color={button_text_color}
            icon_color={pie.icon_color && pie.icon_color.length > 4 ? pie.icon_color : button_text_color}
            title={pie.title}
            description={pie.description}
            image_url={pie.artwork_cdnUrl ? pie.artwork_cdnUrl : pie.image_url}
            price_tag={pie.price_tag}
            price_tag_background_color={pie.price_tag_background_color ? pie.price_tag_background_color : "#4cd963"}
            link_value={pie.link_value}
            demoView={true}
            countdown_from_date={countdown_from_date}
            countdown_to_date={countdown_to_date}
          />
        </SpecialEffectIndicator>
      </DemoBlockPlaceholder>      
    </>
  )
}

export default CountDownModalBody;
