import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { Input as AntInput } from 'antd';
import { Col, Row, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Button, DropdownToggle} from 'reactstrap';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import {CardBlock, FileUpload} from '../BuilderBlock'
import {DemoBlockPlaceholder} from '../Misc'
import {ButtonIcon} from '../../libs'
import {ListColorPicker} from './../ColorPicker'
import {SpecialEffectIndicator} from '../BuilderPie'
import {useFetchWebsite} from '../../hooks'

const { Search } = AntInput;
let timeout = null;

const CardBlockModalBody = () => {
  const widgetApi = useRef();
  const baseSubTypes = ['THUMBNAIL_BUTTON','BACKGROUND_BUTTON','CARD_BUTTON'];

  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);
  const {button_color, button_text_color} = builder;
  const [subTypes, setSubTypes] = useState(baseSubTypes)
  const [subType, setSubType] = useState('THUMBNAIL_BUTTON');
  const [colors, setColors] = useState([{ id: 1,solid_color: "#4cd963"}, { id: 1,solid_color: "#eb5757"}, { id: 1,solid_color: "#f2c84c"}]);
  const [website, error, loading, fetchData] = useFetchWebsite();
  const [searchValue, setSearchValue] = useState(pie.link_value);
  const [inputURL, setInputURL] = useState({url: "", clicked: 0});
  const { title, description, image, price, video } = website;

  useEffect(() => {
    if(pie.sub_type) {
      setSubType(pie.sub_type);
      if(subTypes.indexOf(pie.sub_type) === -1) {
        setSubTypes([...subTypes, pie.sub_type])
      }      
    }
  }, [pie.sub_type])

  useEffect(() => {
    if(inputURL.url && inputURL.url.length > 0) {
      fetchData(inputURL.url);
    }
  }, [inputURL.url, inputURL.clicked]);

  useEffect(() => {
    if(video) {
      setSubTypes([...baseSubTypes, 'VIDEO_BUTTON'])
    }
  }, [video])

  useEffect(() => {
    if(inputURL.url && inputURL.url.length > 0) {
      const pieID = pie.id;
      if(builder.modal_data_id !== pieID) {      
        updateModalId(pieID)
      }
      const newPieDate = {
        link_value: inputURL.url,
        title: pie.title ? pie.title : (title ? title : ""),
        description: pie.description ? pie.description : (description ? description : ""),
        image_url: image,
        price_tag: pie.price_tag ? pie.price_tag : (price ? price: ""),
      }
      // console.log(newPieDate)
      const modal_title = builder.modal_title;
      
      if(video) {
        createUpdateDeletePie({...pie, ...newPieDate, modal_title, sub_type: 'VIDEO_BUTTON'})
      } else {
        createUpdateDeletePie({...pie, ...newPieDate, modal_title})
      }      
    }    
  }, [title, description, image, price])

  const pieChange = (name, value) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    createUpdateDeletePie({...pie, [name]: value, modal_title})
  } 

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    pieChange(name, value)
  }

  const onSearch = (value) => {    
    setInputURL({url: value, clicked: inputURL.clicked+1})
  }
  
  const searchValueChange = (val) => {
    const searchTerm = val.currentTarget.value;
    setSearchValue(searchTerm);

    if(!searchTerm || searchTerm.length === 0 ) {
      if(pie.link_value && pie.link_value.length > 0) {
        pieChange("link_value", "");
      } 
    }

    if(timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if(searchTerm) {
        // Make a new timeout set to go off in 1000ms (1 second)
        setInputURL({url: searchTerm, clicked: 0})
      }
    }, 1000);
    // setInputURL(searchTerm)

  }
  const handleLayoutClick = (sub_type) => {
    pieChange("sub_type", sub_type);
    setSubType(sub_type)
  }

  const handleColorPicked = (value) => {
    pieChange('price_tag_background_color', value)
  }

  const openFileUpload = () => {
    widgetApi.current.openDialog()
  }

  const handleUploaded = (data) => {
    // console.log('hi')
    const {cdnUrl, originalUrl, uuid, mimeType} = data;
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }

    const modal_title = builder.modal_title;
    createUpdateDeletePie({
      ...pie, 
      artwork_uuid: uuid, 
      artwork_cdnUrl: cdnUrl, 
      artwork_originalUrl: originalUrl, 
      artwork_mime_type: mimeType,
      modal_title
    })
  }


  return (
    <>
      <Form>
        <FormGroup role="form">        
          <Label for="link_value">{t("HEADER-LINK.1")}</Label>
          <Search 
            name="searchTerm"
            placeholder={t("HEADER-LINK.1")}
            onSearch={onSearch} 
            onChange={searchValueChange}
            allowClear
            size="large"
            enterButton
            value={searchValue} 
            defaultValue={searchValue}
            loading={loading}   
          />
        </FormGroup>
        <FormGroup role="form">
          <Label for="title">{t("HEADER-TITLE.3")}</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <FileUpload widgetApi={widgetApi} handleChanged={handleUploaded}>
                <Button onClick={openFileUpload}>
                  <FontAwesomeIcon icon={['fa', 'image']} color="white" size="lg"/>
                </Button>
              </FileUpload>
            </InputGroupAddon>
            <Input 
              type="text" 
              name="title" 
              // id={pie.id}
              id="title"
              placeholder={t("HEADER-TITLE.3")}
              value={pie.title ? pie.title : ""}
              onChange={handleInputChange}          
            />
          </InputGroup>          
        </FormGroup> 
        <FormGroup role="form">          
          <Label for="price_tage">{t("PRICE-TAG.1")}</Label>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>          
            <Input 
              type="text" 
              name="price_tag" 
              style={{flex: 1}}
              // id={pie.id}
              id="price_tag" 
              placeholder={t("PRICE-TAG.1")}
              value={pie.price_tag ? pie.price_tag : ""}
              onChange={handleInputChange}          
            />         
            {pie.price_tag && <ListColorPicker
              style={{flex: 1}}
              colors={colors}
              selectedColor={pie.price_tag_background_color ? pie.price_tag_background_color : "#4cd963"}
              handleColorPicked={handleColorPicked}
            />}
          </div>    
        </FormGroup>                                                  
      </Form>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {subTypes.map((sub_type, idx) => {
          return (
            <div key={idx} onClick={() => {
              handleLayoutClick(sub_type)
            }} 
            style={{marginRight: "10px"}}
            className={`btn btn-${sub_type === subType ? "warning" : "outline-warning"}`}
            >
              <ButtonIcon name={sub_type}/>
            </div>
          )
        })}
      </div>
      <DemoBlockPlaceholder is_pop={pie.is_pop} is_loading={loading} alertMessage="Please wait" alertDescription={`Fetching data from ${inputURL.url}`}>
        <SpecialEffectIndicator is_hidden={pie.is_hidden} schedule_from_date={pie.schedule_from_date}>
          <CardBlock
            button_color={pie.background_color && pie.background_color.length > 4 ? pie.background_color : `${button_color}`} 
            text_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.title_text_color : button_text_color}
            icon_color={pie.icon_color && pie.icon_color.length > 4 ? pie.icon_color : button_text_color}
            title={pie.title}
            description={pie.description}
            image_url={pie.artwork_cdnUrl ? pie.artwork_cdnUrl : pie.image_url}
            price_tag={pie.price_tag}
            price_tag_background_color={pie.price_tag_background_color ? pie.price_tag_background_color : "#4cd963"}
            sub_type={subType}
            icon_group={pie.icon_group}
            icon_name={pie.icon_name}
            link_value={pie.link_value}
            demoView={true}
            is_hidden={pie.is_hidden}
            schedule_from_date={pie.schedule_from_date}            
          />
        </SpecialEffectIndicator>
      </DemoBlockPlaceholder>
    </>
  )
}

export default CardBlockModalBody;