import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroupAddon, Input, Button} from 'reactstrap';
import { FileUpload} from '../BuilderBlock'

const AuthorListItemCore = ({invalid, title, link_value, handleChange}) => {
  const widgetApi = useRef();
  const {t} = useTranslation();

  const handleUploaded = (data) => {
    const {cdnUrl, originalUrl, uuid, mimeType} = data;
    handleChange({
      artwork_uuid: uuid, 
      artwork_cdnUrl: cdnUrl, 
      artwork_originalUrl: originalUrl, 
      artwork_mime_type: mimeType,      
    })
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    handleChange({[name]: value})
  }

  const openFileUpload = () => {
    widgetApi.current.openDialog()
  }  

  return (
    <>
      <InputGroupAddon addonType="prepend">        
        <FileUpload widgetApi={widgetApi} handleChanged={handleUploaded}>
          <Button onClick={openFileUpload}>
            <FontAwesomeIcon icon={['fa', 'image']} color="white" size="lg"/>
          </Button>
        </FileUpload>
      </InputGroupAddon>      
      <Input
        type="text"
        name="title"
        valid={title ? true : false}
        placeholder={t("AUTHOR-NAME.1")}
        value={title ? title : ""}
        invalid={invalid}
        onChange={handleInputChange}
      />   
      <Input
        type="url"
        name="link_value"
        placeholder={t("AUTHOR-PROFILE-LINK.1")}
        value={link_value ? link_value : ""}
        onChange={handleInputChange}
      />               
    </>
  )
}

export default AuthorListItemCore