import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import {setNavigator} from '../src/libs/navigationRef'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { Provider } from 'jotai'
import reportWebVitals from './reportWebVitals';
import './i18n';

library.add(fab, fas)

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={(<div></div>)}>
      <Router history={history} ref={() => {setNavigator(history)} }>
        <Provider>
          <App />                 
        </Provider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
