import React from 'react';
import {Avatar} from 'antd'

const AuthorCardImage = ({image_url, title, button_color, text_color}) => {
	if(image_url) {
		return (
			<Avatar
				src={image_url}
				style={{
					width: "60px",
					height: "60px",
				}}
			/>
		)
	} else {
		return (
      <div style={{
        width: "60px",
        height: "60px",
        borderRadius: '70px',
        backgroundColor: button_color,
        color: text_color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <strong>{title[0]}</strong>
      </div>			
		)
	}
}

export default AuthorCardImage;