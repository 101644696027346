import React from "react";
import ProjectListCard from './ProjectListCard'

const ProjectsList = ({items, handleDelete, handleUpdate, changeProject}) => {
  // const path = process.env.REACT_APP_LINK_PAGE_PATH.split("://")[1];
  const path = process.env.REACT_APP_LINK_PAGE_PATH;

  if(items) {    
    return (
      items.map((item, idx) => {
        return (
          <ProjectListCard 
            key={item.id}
            idx={idx}            
            project={item}
            slugPrefix={path}
            slug={item.slug}
            shorterUrl={`${path}/${item.slug}`}
            projectId={item.id} 
            is_check_podcast_health={item.is_check_podcast_health}
            createdDate={item.created_at}
            artworkUrl={item.artwork_cdnUrl}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}    
            changeProject={changeProject}      
          />
        )
      }
        
    ))
  } else {
    return <></>;
  }
}

export default ProjectsList