const podcastNetworks = [
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 1,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'apple_podcast',
    icon_color: 'black',
    placeholder: 'Apple Podcast',
    value: "",
    name: 'APPLE-PODCAST'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 2,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'google_podcast',
    icon_color: 'black',
    placeholder: 'Google Podcast',
    value: "",
    name: 'GOOGLE-PODCAST'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 3,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'castbox',
    icon_color: 'black',
    placeholder: 'Castbox',
    value: "",
    name: 'CASTBOX'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 4,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'overcast',
    icon_color: 'black',
    placeholder: 'Overcast',
    value: "",
    name: 'OVERCAST'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 5,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'pocketcast',
    icon_color: 'black',
    placeholder: 'Pocketcast',
    value: "",
    name: 'POCKETCAST'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 6,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'radiopublic',
    icon_color: 'black',
    placeholder: 'RadioPublic',
    value: "",
    name: 'RADIOPUBLIC'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 7,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'spotify',
    icon_color: 'black',
    placeholder: 'Spotify',
    value: "",
    name: 'SPOTIFY'
  },  
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 8,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'tunein',
    icon_color: 'black',
    placeholder: 'TuneIn',
    value: "",
    name: 'TUNEIN'
  },  
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 9,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'rss',
    icon_color: 'black',
    placeholder: 'RSS',
    value: "",
    name: 'RSS'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 10,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'stitcher',
    icon_color: 'black',
    placeholder: 'Stitcher',
    value: "",
    name: 'STITCHER'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 11,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'iHeart',
    icon_color: 'black',
    placeholder: 'iHeart',
    value: "",
    name: 'IHEART'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 12,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'player_fm',
    icon_color: 'black',
    placeholder: 'Player FM',
    value: "",
    name: 'PLAYERFM'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 13,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'castro',
    icon_color: 'black',
    placeholder: 'Castro',
    value: "",
    name: 'CASTRO'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 14,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'podcast_addict',
    icon_color: 'black',
    placeholder: 'Podcast Addict',
    value: "",
    name: 'PODCASTADDICT'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 15,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'pod_friend',
    icon_color: 'black',
    placeholder: 'PodFriend',
    value: "",
    name: 'PODFRIEND'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 16,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'pod_hero',
    icon_color: 'black',
    placeholder: 'Podhero',
    value: "",
    name: 'PODHERO'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 17,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'podcast_republic',
    icon_color: 'black',
    placeholder: 'Podcast Republic',
    value: "",
    name: 'PODCASTREPUBLIC'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 18,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'sonnet',
    icon_color: 'black',
    placeholder: 'Sonnet',
    value: "",
    name: 'SONNET'
  },
  {
    id: '',
    main_type: 'PODCAST-NETWORK',
    sortable: true,
    sort_order: 19,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'podcasts', 
    icon_name: 'amazon_music',
    icon_color: 'black',
    placeholder: 'Amazon Music',
    value: "",
    name: 'AMAZON_MUSIC'
  }         

]

export default podcastNetworks;