import React, {useState} from "react";
import { Menu, Dropdown, Button, Space } from 'antd';

const DropdownOptions = (props) => {

  const {options} = props;

  const menu = (
    <Menu>
      {
        options.map((option) => <Menu.Item key={option.id}>
          <a rel="noopener noreferrer" href={option.action_url}>
            {option.title}
          </a>
        </Menu.Item>)
      }
    </Menu>
  )
  

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button>Add new integration</Button>
    </Dropdown>
  );
}

export default DropdownOptions;