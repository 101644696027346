import React from 'react';
import {Hanlder} from '../notificatoins'

const Notification = (props) => {

  return (
    <>
      <Hanlder/>
      {props.children}
    </>
  )
}

export default Notification;