import React from 'react';
import { useAtom } from 'jotai';
import { builderAtom } from '../../jotais'
import { 
  MessagesBody, 
  MessagesBodySort,
  ColorSettingsModalBody,
  HeaderBody,
  SimpleTextInput,
  LinkInput,
  CardBlockModalBody,  
  CountDownModalBody,
  PodcastBlockModalBody,
  AuthorModalBody,
  BadgeModalBody,
  TipUsModalBody,
  MailingListModalBody
} from '../BuilderModalBody'
import {RefreshPodcast} from '../import-podcasts'
import { messengers, socialNetworks, podcastNetworks } from '../../libs/'
import AddSpecialEffects from './AddSpecialEffects'

const BuilderModalBody = () => {
  const [builder] = useAtom(builderAtom);
  const { modal_type } = builder;

  switch(modal_type) {
    case 'MESSENGERS':    
      // return <MessagesBody items={messengers} />
      return <MessagesBodySort items={messengers}/>  
    case 'MESSENGERS-SORT':    
      return <MessagesBodySort items={messengers}/>  
    case 'SOCIAL-NETWORKS':    
      // return <MessagesBody items={socialNetworks} />
      return <MessagesBodySort items={socialNetworks}/>  
    case 'SOCIAL-NETWORKS-SORT':    
      // return <MessagesBodySort/>            
      return <MessagesBodySort items={socialNetworks}/>
    case 'PODCAST-NETWORK':    
      // return <MessagesBody items={podcastNetworks}/>  
      return <><RefreshPodcast/><MessagesBodySort items={podcastNetworks}/></>
    case 'PODCAST-NETWORK-SORT':    
      // return <MessagesBodySort/>
      return <><RefreshPodcast/><MessagesBodySort items={podcastNetworks}/></>
    case 'AUTHORS':
      return <AuthorModalBody/>
    case 'COLOR-SETTINGS':
      return <ColorSettingsModalBody/>
    case 'HEADER-TITLE':
      return <HeaderBody/>  
    case "TEXT-INPUT":
      return <SimpleTextInput/>    
    case "LINK-INPUT":
      return <AddSpecialEffects><LinkInput/></AddSpecialEffects>
    case "PODCAST-FEED":
      return <AddSpecialEffects><PodcastBlockModalBody/></AddSpecialEffects>      
    case "CARD-INPUT": 
      return <AddSpecialEffects><CardBlockModalBody/></AddSpecialEffects>
    case "COUNT-DOWN":
      return <CountDownModalBody/>
    case "PLATFORM-BADGE":
      return <BadgeModalBody/>
    case "TIP-US-BADGE":
      return <TipUsModalBody/>      
    case "MAILING-LIST":
      return <MailingListModalBody/>
    default:
      return null;
  }  
}

export default BuilderModalBody;