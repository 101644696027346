import React from 'react';
import CardBlockThumbnail from './CardBlockThumbnail';
import CardBlockBackgroundButton from './CardBlockBackgroundButton';
import CardBlockFullSize from './CardBlockFullSize';
import CardBlockVideoPlayer from './CardBlockVideoPlayer';

const CardBlockRender = (props) => {

  const {id, text_color, button_color, main_type, modal_title, 
    sub_type, name, title, price_tag, description, image_url, 
    icon_group, icon_name, link_value, demoView, price_tag_background_color, handleClick} = props;

  switch(sub_type) {    
    case 'THUMBNAIL_BUTTON':
      return <CardBlockThumbnail
      handleClick={handleClick}
      text_color={text_color} 
      button_color={button_color} 
      title={title} 
      price_tag={price_tag}      
      price_tag_background_color={price_tag_background_color}
      image_url={image_url} 
      demoView={demoView}
    />
    case 'BACKGROUND_BUTTON':
      return <CardBlockBackgroundButton
      handleClick={handleClick}
      text_color={text_color} 
      button_color={button_color} 
      title={title} 
      price_tag={price_tag}
      price_tag_background_color={price_tag_background_color}
      image_url={image_url} 
      demoView={demoView}/>
    case 'CARD_BUTTON':
      return <CardBlockFullSize
      handleClick={handleClick}
      text_color={text_color} 
      button_color={button_color} 
      price_tag_background_color={price_tag_background_color}
      title={title} 
      price_tag={price_tag}
      image_url={image_url} 
      demoView={demoView}
    />
    case 'VIDEO_BUTTON':
      return <CardBlockVideoPlayer
        handleClick={handleClick}
        text_color={text_color} 
        button_color={button_color} 
        title={title} 
        price_tag={price_tag}      
        price_tag_background_color={price_tag_background_color}
        image_url={image_url}
        link_value={link_value} 
        demoView={demoView}
      />
    default:
      return <CardBlockThumbnail
      handleClick={handleClick}
      text_color={text_color} 
      button_color={button_color} 
      title={title} 
      price_tag={price_tag}      
      price_tag_background_color={price_tag_background_color}
      image_url={image_url} 
      demoView={demoView}/>
  }
}

export default CardBlockRender