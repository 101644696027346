const messengers = [
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 1,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'telegram',
    icon_color: 'black',
    placeholder: 'Telegram username',
    value: "",
    name: 'TELEGRAM'
  },
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 2,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '', 
    icon_group: 'fab',
    icon_name: 'facebook-messenger',
    icon_color: 'black',
    placeholder: 'Messenger/Facebook username',
    value: "",
    name: 'FACEBOOK'
  },
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 3,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '', 
    icon_group: 'fab',
    icon_name: 'skype',
    icon_color: 'black',
    placeholder: 'Skype username',
    value: "",
    name: 'SKYPE'
  },  
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 4,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '',
    icon_group: 'fab',
    icon_name: 'whatsapp-square',
    icon_color: 'black',
    placeholder: 'WhatsApp phone number with country code (1+...)',
    value: "",
    name: 'WHATSAPP'
  },  
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 5,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '', 
    icon_group: 'fab',
    icon_name: 'viber',
    icon_color: 'black',
    placeholder: 'Viber number',
    value: "",
    name: 'VIBER'
  },       
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 6,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '',
    icon_group: 'fas', 
    icon_name: 'envelope',
    icon_color: 'black',
    placeholder: 'Email address',
    value: "",
    is_username: true,
    name: 'EMAIL'
  }, 
  {
    id: '',
    main_type: 'MESSENGERS',
    sortable: true,
    sort_order: 7,  
    title: '',
    title_text_color: null,
    link_value: '',
    background_color: null,
    image_url: '',
    description: '', 
    icon_group: 'fas',
    icon_name: 'phone',
    icon_color: 'black',
    placeholder: 'Phone number with country code (+1...)',
    value: "",
    name: 'PHONE'
  },  
]

export default messengers