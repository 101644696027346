import React, {useState, useEffect} from 'react';
import { Alert } from 'antd';
// import moment from 'moment';
import moment from 'moment-timezone';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import TimeInput from './TimeInput'
import TimeZonePicker from './TimeZonePicker';
import {getDateTimeString, updateTimeToDateString} from '../../libs'

const DateRangePicker = ({fromDate, toDate, pickedTimeZone, handleDateChange, handleTimeZone}) => {
  
  const [dateRange, setDateRange] = useState({from: null, to: null});
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    // setDateRange({from: fromDate, to: toDate})
    const from_date = fromDate ? new Date(fromDate) : null;
    const to_date = toDate ? new Date(toDate) : null;
    setDateRange({from: from_date, to: to_date})
  }, [fromDate, toDate])  

  const {from, to} = dateRange;
  const modifiers = { start: from, end: to }; 

  const handleDateTimeChange = (from, to) => {
    if(from >= to) {
      // TODO: add animiation
      setAlertMessage('DATE-PICKER-ERROR');
      setTimeout(() => {
        setAlertMessage('');
      }, 2000)
    }    
  }

  const handleDayClick = (day) => {

    const range = DateUtils.addDayToRange(day, dateRange);
    const {from, to} = range;
    
    setDateRange({from: from, to: to});
    if(from && to) {
      const fromStr = getDateTimeString(from);
      const toStr = getDateTimeString(to);
      handleDateChange({from: fromStr, to: toStr});
      handleDateTimeChange(from, to);
    } else if (!(from && to)) {
      handleDateChange({from: null, to: null});
    }
  }

  const handleTimeChange = (inputId, time, timeString) => {
    const dateRangeStrs = {from: fromDate, to: toDate};
    const dateStr = dateRangeStrs[inputId]
    const newDateStr = updateTimeToDateString(dateStr, timeString);
    const _range = {...dateRangeStrs, [inputId]: newDateStr}
    const {from, to} = _range;
    handleDateTimeChange(from, to)
    if(from && to && from  < to) {
      handleDateChange({from, to});    
    }
  }

  return (
    <div>
      <div className="SimpleDatePicker" style={{display: 'flex', justifyContent: 'center'}}>
        <DayPicker 
          className="Selectable"
          numberOfMonths={1}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={handleDayClick}
        />
      </div>
      {alertMessage ? <Alert message={alertMessage} type="error" showIcon /> : null}
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>        
        <div>
          <TimeInput 
            label={"START-DAY"} 
            id="from"
            placeholder="PLEASE-SELECT-THE-START-DAY"
            date={from ? from : ""}
            handleTimeChange={handleTimeChange}
          />
          <TimeInput 
            label={"END-DAY"} 
            id="to"
            placeholder="PLEASE-SELECT-THE-END-DAY"
            date={to ? to : ""}
            handleTimeChange={handleTimeChange}
          />
        </div>
      </div>      
      <TimeZonePicker pickedTimeZone={pickedTimeZone} handleTimeZone={handleTimeZone}/>
    </div>
  );

}


export default DateRangePicker;