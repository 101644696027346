import React from "react";
import CardBodyListItem from './CardBodyListItem'

const CardBodyList = (props) => {
  const {items, handleDelete} = props;

  return (
    <>
      {
        items.map((item) => <CardBodyListItem 
          key={item.id}
          id={item.id}
          account_email={item.account_email}
          mailing_service_id={item.mailing_service_id}
          service_name={item.service_name}
          service_image_name={item.service_image_name}
          handleDelete={handleDelete}
        />)
      }      
    </>
  )
}

export default CardBodyList;