import React from 'react';
import {getTextColor} from '../../libs'
// Resize <div> automatically based on text?
// https://stackoverflow.com/questions/16047491/resize-div-automatically-based-on-text

const CardBlockThumbnail = (props) => {

  const {handleClick, text_color, button_color, title, price_tag, image_url, demoView, price_tag_background_color} = props;
  
  return (
    <div style={{
      display: 'flex', 
      borderRadius: "10px",
      minHeight: "120px",
      width: demoView ? "360px" : null, 
      paddingLeft: '10px',
      paddingRight: '10px',
      alignItems: 'center', 
      justifyContent: 'flex-around',
      backgroundColor: `${button_color}`,  
      color: `${text_color}`,
      borderColor: `${text_color}`,
      cursor: 'pointer',
      boxShadow: demoView ? '5px 10px 8px 10px #C1C1BD' : null
    }}
      onClick={handleClick}
    >
      <div style={{
        backgroundImage: `url(${image_url ? image_url : "https://via.placeholder.com/160"})`,
        width: "80px",
        height: "80px",
        backgroundSize: 'cover',
        borderRadius: "10px",
        marginRight: '10px'
      }}>
      </div>
      <div style={{
        maxWidth: '260px'
      }}>
        <p style={{fontSize: "18px"}}>
          {/* {truncateText(title, 14)} */}
          {title}
        </p>
        {
          price_tag && price_tag.length > 0 ? <div style={{
            backgroundColor: price_tag_background_color, 
            padding: "2px 10px",
            fontSize: '14px',
            fontWeight: '700',
            color: getTextColor(price_tag_background_color),
            borderRadius: "8px",
            display: 'inline-block'
            // maxWidth: '50%'
            // justifyContent: 'center'
         }}>
          {price_tag}
         </div> : null
        }        
      </div>
    </div>
  )
}

export default CardBlockThumbnail;