import messengers from './messengers'
import socialNetworks from './socialNetworks'
import podcastNetworks from './podcastNetworks'
import {getRandomId} from './randomId'


const PIE = {
  id: '',
  // main_type: ''
  main_type: '', // determine the modal
  sub_type: '', // cards have different option
  modal_title: '',
  value: '',
  name: '',  // determine the display blocks
  sortable: true,
  sort_order: 0,  
  title: '',
  title_text_color: '',
  link_value: '',
  background_color: '',
  image_url: '',
  description: '',
  icon_group: '',
  icon_name: '',
  icon_color: '',
  placeholder: '',
  price_tag: '',
  price_tag_background_color: '',
  price_tag_text_color: '',
  display_effect_options: false,
  is_pop: false,
  is_hidden: false,  
  schedule_from_date: null,
  schedule_to_date: null,
  schedule_from_date_str: null,
  schedule_to_date_str: null,  
  schedule_time_zone: null,
  countdown_from_date_str: null,
  countdown_to_date_str: null,    
  countdown_from_date: null,
  countdown_to_date: null,  
  countdown_time_zone: null,
  artwork_uuid: null,
  artwork_cdnUrl: null,
  artwork_originalUrl: null,
  artwork_mime_type: null,
  social_username: null
}

const getDefaultPodcastPies = ({feedUrl, trackName, artworkUrl600, networks}) => {
  const msgs = messengers.map((x) => {
    return {...x, id: (-1 * getRandomId())}
  })
  const sns = socialNetworks.map((x) => {
    return {...x, id: (-1 * getRandomId())}
  })

  // convert networks into a hash (key-value pair)
  const networksDict = {}
  for(let i = 0; i < networks.length; i++) {
    const key = networks[i]['name'];
    const url = networks[i]['url'];
    networksDict[key] = url;
  }

  const pns = podcastNetworks.map((x) => {
    const key = x.name;
    const link_value = networksDict[key];
    const id = -1 * getRandomId()
    return {...x, link_value, id}   
  })

  const prepareData = [...msgs, ...sns, ...pns];  
  prepareData.sort((a, b) => `${a.main_type}-${a.sort_order}` - `${b.main_type}-${b.sort_order}`);
  prepareData.forEach((item, idx) => {
    item['sort_order'] = idx + 1;
  });

  let maxSortOrder = prepareData.sort((a, b) => b.sort_order - a.sort_order)[0]['sort_order'];

  maxSortOrder += 1;
  const headerTitle = {...PIE, 
    title: trackName,
    main_type: "HEADER-TITLE",
    modal_title: "TITLE-AND-DESCRIPTION",
    name: "TITLE-AND-DESCRIPTION",
    id: (-1 * getRandomId()), 
    sort_order: maxSortOrder
  }
  prepareData.push(headerTitle)

  if(networksDict['APPLE-PODCAST']) {
    maxSortOrder += 1;
    const badge = {...PIE, 
      title: "Apple Podcasts",
      description: "Listen on",
      icon_color: 'black',
      icon_group: 'podcasts',
      icon_name: 'apple_podcast',
      main_type: "PLATFORM-BADGE",
      link_value: networksDict['APPLE-PODCAST'],
      modal_title: "PLATFORM-BADGE",
      name: "MAIN-BLOCK",
      id: (-1 * getRandomId()), 
      sort_order: maxSortOrder
    } 
    prepareData.push(badge)
  }

  if(networksDict['GOOGLE-PODCAST']) {
    maxSortOrder += 1;
    const badge = {...PIE, 
      title: "Google Podcast",
      description: "Listen on",
      icon_color: 'black',
      icon_group: 'podcasts',
      icon_name: 'google_podcast',
      main_type: "PLATFORM-BADGE",
      link_value: networksDict['GOOGLE-PODCAST'],
      modal_title: "PLATFORM-BADGE",
      name: "MAIN-BLOCK",
      id: (-1 * getRandomId()), 
      sort_order: maxSortOrder
    } 
    prepareData.push(badge)
  }

  if(networksDict['SPOTIFY']) {
    maxSortOrder += 1;
    const badge = {...PIE, 
      title: "Spotify",
      description: "Listen on",
      icon_color: 'black',
      icon_group: 'podcasts',
      icon_name: 'spotify',
      main_type: "PLATFORM-BADGE",
      link_value: networksDict['SPOTIFY'],
      modal_title: "PLATFORM-BADGE",
      name: "MAIN-BLOCK",
      id: (-1 * getRandomId()), 
      sort_order: maxSortOrder
    } 
    prepareData.push(badge)
  }
  
  maxSortOrder += 1;
  const player = {...PIE, 
    title: trackName,
    image_url: artworkUrl600,
    main_type: "PODCAST-FEED",
    sub_type: 'THUMBNAIL',
    value: '3',
    link_value: feedUrl,
    modal_title: "PODCAST-FEED",
    name: "MAIN-BLOCK",
    id: (-1 * getRandomId()), 
    sort_order: maxSortOrder
  }      

  prepareData.push(player)

  return prepareData;
}

export default getDefaultPodcastPies;