import React from "react";
import {CompanyLogoImage} from '../../../libs'

const CardBodyListItem = (props) => {
  const {id, mailing_service_id, account_email, service_name, service_image_name, handleDelete} = props;

  return (
    <div className="row align-items-center">
      <div className="col-auto"> 
        <CompanyLogoImage name={service_image_name}/>
      </div>
      <div className="col ml-n2">
        <h3 className="mb-1">{account_email}</h3>
        <p className="mb-1">{service_name }</p>
      </div>
      <div className="col-auto">
        <div className="btn btn-sm btn-danger" onClick={() => {
          handleDelete(mailing_service_id, id)
        }}>Delete</div>
      </div>
    </div>
  )
}

export default CardBodyListItem;