import React from "react";
import BooleanBarItem from './BooleanBarItem'

const BooleanBarChart = ({data, networkName}) => {
  if(data) {
    return (
      <div style={{display: 'flex', marginTop: "10px"}}>
        {
          data.map((item, idx) => {
            return <BooleanBarItem key={idx} item={item} id={`${networkName}-${idx+1}`}/>
          })
        }
      </div>
    )
  }

  return null;
}

export default BooleanBarChart;