import React from 'react';
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'
import {PodcastBlockRender} from '../BuilderPodcastBlock'

const PodcastBlock = (props) => {
  const {id, text_color, button_color, main_type, modal_title, 
    sub_type, name, title, price_tag, description, image_url, 
    icon_group, icon_name, link_value, demoView, value, button_text_color,
    price_tag_background_color, is_hidden, schedule_from_date, placeholder
  } = props;

  const [, toggle] = useAtom(toggleBuilderModal);


  const handleClick = () => {
    if(demoView) return null;
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }  
  
  return (   
    <PodcastBlockRender 
      id={id}
      text_color={text_color}
      button_text_color={button_text_color}
      button_color={button_color}
      main_type={main_type}
      modal_title={modal_title}
      sub_type={sub_type}
      name={name}
      title={title}
      placeholder={placeholder}
      price_tag={price_tag}
      description={description}
      image_url={image_url}
      icon_group={icon_group}
      icon_name={icon_name}
      value={value}
      link_value={link_value}
      demoView={demoView}
      price_tag_background_color={price_tag_background_color}
      handleClick={handleClick}
    />  
  )  
}

export default PodcastBlock;