import React from 'react';
import {ShortLinkCopyControl} from '../Misc'

const cssStyle = {
  backgroundColor: "white", 
  height: "80px", 
  width: '100%',
  marginTop: '20px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',  
  alignItems: 'center',    
}

const publishButtonStyle = {
  // backgroundColor: "#07f",
  border: '1px solid #BEBAC8',
  width: '80%',
  height: '40px',
  borderRadius: '20px',
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'flex',
  alignItems: 'center',
}

const FooterMenuViewButton = ({shortUrl, artworkUrl}) => {
  
  return (
    <div style={cssStyle}>
      <ShortLinkCopyControl 
        shortUrl={shortUrl}
        artworkUrl={artworkUrl} 
        publishButtonStyle={publishButtonStyle}/>
    </div>
  )
}

export default FooterMenuViewButton;