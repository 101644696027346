import React from 'react';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { getHeaderTitle, toggleBuilderModal} from '../../jotais'

const HeaderTitle = ({textColor}) => {
  const [header] = useAtom(getHeaderTitle);
  const [, toggle] = useAtom(toggleBuilderModal);
  const {t} = useTranslation();

  const emptyTitleStyle = {
    color: `${textColor}80`,
    borderBottomWidth: "3px",
    borderBottomStyle: 'dashed'    
  }

  if(header.length > 0) {
    const pieId = header[0].id;
    const {title, description, title_font_size, description_font_size} = header[0]
    const titleStyle = {color: textColor}
    if(title_font_size) {
      titleStyle['fontSize'] = `${title_font_size}px`
    }
    const descriptionStyle = {fontSize: "15px"}
    if(description_font_size) {
      descriptionStyle['fontSize'] = `${description_font_size}px`
    }

    return (
      <div style={{textAlign: "center", color: textColor, cursor: 'pointer'}} onClick={() => {
        toggle({type: 'HEADER-TITLE', title: 'TITLE-AND-DESCRIPTION', name: 'TITLE-AND-DESCRIPTION', pieId});
      }}>
        {title && title.length > 0 ? <h1 style={titleStyle}>{title}</h1> : null}
        {description && description.length > 0 ? <p style={descriptionStyle}>{description}</p> : null}
        { (title.length === 0 ) && (description.length === 0) ?
          <h1 style={emptyTitleStyle}>{t("Tilte-HERE.1")}</h1> : null
        }
      </div>
    )    
  }

  return (
    <h1 style={{textAlign: "center", ...emptyTitleStyle, cursor: 'pointer'}}
      onClick={() => {
        toggle({type: 'HEADER-TITLE', title: 'TITLE-AND-DESCRIPTION', name: 'TITLE-AND-DESCRIPTION'});
      }}
    >
      {t("Tilte-HERE.1")}
    </h1>
  )
}

export default HeaderTitle;