import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from 'react-i18next'
import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Button, DropdownToggle} from 'reactstrap';
import { useAtom } from 'jotai';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import {IconPanel, DemoBlockPlaceholder} from '../Misc'
import { ButtonBlock } from "../BuilderBlock";
import {SpecialEffectIndicator} from '../BuilderPie'

const LinkInput = () => {
  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);
  const [openIconPanel, setOpenIconPanel] = useState(false);
  const {button_color, button_text_color} = builder;

  // const [pie, setPie] = useState({title: "", id: "", description: ""});

  const pieChange = (name, value) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    createUpdateDeletePie({...pie, [name]: value, modal_title})    
  } 

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    pieChange(name, value);
  }

  const toggleIconPanel = () => {
    setOpenIconPanel(!openIconPanel)
  }

  const handleIconChange = (icon) => {
    if(icon && icon.length === 2) {
      const [icon_group, icon_name] = icon;
      const pieID = pie.id;
      if(builder.modal_data_id !== pieID) {      
        updateModalId(pieID)
      }
      const modal_title = builder.modal_title;
      createUpdateDeletePie({...pie, icon_group, icon_name, modal_title})       
    }
    toggleIconPanel()
  }

  return (
    <>
      <Form>
        <FormGroup role="form">
          <Label for="link_value">{t("HEADER-LINK.1")}</Label>
          <Input 
            type="url" 
            name="link_value" 
            // id={pie.id}
            id="link"
            value={pie.link_value ? pie.link_value : ""}
            onChange={handleInputChange}
            placeholder={t("HEADER-LINK.1")}
          />
        </FormGroup>
        <FormGroup role="form">
          <Label for="title">{t("HEADER-TITLE.3")}</Label>
          <Input 
            type="text" 
            name="title" 
            // id={pie.id}
            id="title"
            placeholder={t("HEADER-TITLE.3")}
            value={pie.title ? pie.title : ""}
            onChange={handleInputChange}          
          />
        </FormGroup> 
        <div>
          {openIconPanel ? null : <InputGroup>
            <div className="btn btn-primary btn-block" onClick={toggleIconPanel}>
              Choose Icon <FontAwesomeIcon icon={['fas', 'arrow-down']}/>
            </div>        
          </InputGroup>
          }
          <IconPanel 
            isOpen={openIconPanel}
            toggleOpen={toggleIconPanel}
            iconClicked={handleIconChange}
          />
        </div>
      </Form>
      <DemoBlockPlaceholder is_pop={pie.is_pop}>
        <SpecialEffectIndicator is_hidden={pie.is_hidden} schedule_from_date={pie.schedule_from_date}>
          <ButtonBlock
            button_color={pie.background_color && pie.background_color.length > 4 ? pie.background_color : `${button_color}`} 
            text_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.title_text_color : button_text_color}
            icon_color={pie.icon_color && pie.icon_color.length > 4 ? pie.icon_color : button_text_color}
            title={pie.title}
            icon_group={pie.icon_group}
            icon_name={pie.icon_name}
            link_value={pie.link_value}
            demoView={true}
          />
        </SpecialEffectIndicator>
      </DemoBlockPlaceholder>
    </>
  )
}

export default LinkInput;