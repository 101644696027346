import React from 'react';
import CountDownClock from './CountDownClock'

const CountDownTimer = ({countdown_to_date, countdown_from_date}) => {
  if(countdown_to_date && countdown_from_date) {
    return <CountDownClock countdown_to_date={countdown_to_date}/>
  } else {
    return (
      <div style={{
        // lineHeight: '50px',
        textAlign: 'center',
        fontWeight: '700'
      }}>
        EXPIRED
      </div>
    )
  }
  

}

export default CountDownTimer