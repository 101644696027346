import { useState, useEffect } from 'react';
import railsApi from '../api/railsApi'

const useFetchMailingServices = () => {
  const [state, setState] = useState([]);

  useEffect(() => {
    railsApi.get(`/v1/mailing_services`)
    .then((res) => {
      // setState(res.data);
      const data = res.data;
      setState(data);    
    })
    .catch((err) => {
      console.log(err.response.message)
    })
  }, [])  

  return [state];
}

export default useFetchMailingServices;