import React from 'react';

const BuilderPieControlHandler = (props) => {

  const {handleClick, 
    button_text_color,
    button_color,
    demoView
  } = props;

  const style = {
    display: 'flex',
    borderRadius: "10px",
    padding: '5px',
    marginBottom: '5px',
    justifyContent: 'center',
    backgroundColor: `${button_color}80`, 
    color: `${button_text_color}`,
    cursor: 'pointer',
    fontSize: '14px'
  }

  return (
    <div>
      {demoView ? null : <div style={style} onClick={handleClick}>
        <strong>Edit</strong>
      </div>}
      {props.children}
    </div>
  )
}

export default BuilderPieControlHandler;