import React from 'react';
import { useAtom } from 'jotai';
import { builderAtom, 
  updateSolidColor, 
  updateGradientColorFrom,
  updateGradientColorTo,
  updateButtonColor,
  colorsAtom,
  themesAtom,
  updateBuilder
} from '../../jotais'
import { Collapse } from 'antd';
import {useTranslation} from 'react-i18next'
import {ListColorPicker, ListThemePicker, CollapseItem} from './../ColorPicker'
import { ProjectBackgroundUpload } from './../BuilderBlock'

const { Panel } = Collapse;

const ColorSettingsModalBody = () => {
  const {t} = useTranslation();

  const [builder] = useAtom(builderAtom);
  
  const [solidColors] = useAtom(colorsAtom);
  const [themes] = useAtom(themesAtom);
  const { button_color, solid_color, degree, gradient_color_from, gradient_color_to,  selected_theme_id} = builder
  
  const [, setBackgroundSolidColor] = useAtom(updateSolidColor);
  const [, setBackgroundGradientColorFrom] = useAtom(updateGradientColorFrom);
  const [, setBackgroundGradientColorTo] = useAtom(updateGradientColorTo);
  const [, setButtonColor] = useAtom(updateButtonColor); 
  const [, updateProject] = useAtom(updateBuilder)

  const handleThemePicked = (data) => {
    updateProject(data)
  }

  return (    
    <Collapse accordion>
      <Panel header={<h5>{t("BACKGROUND-THEME.1")}</h5>} key="1">
        <CollapseItem>
          <ListThemePicker 
            themes={themes}
            handleThemePicked={handleThemePicked} 
            selectedThemeId={selected_theme_id}/>
        </CollapseItem>
      </Panel>      
      <Panel header={<h5>{t("BACKGROUND-SOLID-COLOR.1")}</h5>} key="2">
        <CollapseItem>
          <ListColorPicker 
            colors={solidColors}
            handleColorPicked={setBackgroundSolidColor} 
            selectedColor={solid_color}/>
        </CollapseItem>
      </Panel>
      <Panel header={<h5>{t("BACKGROUND-COLOR-GRADIENT.1")}</h5>} key="3">
        <CollapseItem>
          <ListColorPicker 
            colors={solidColors}
            handleColorPicked={setBackgroundGradientColorFrom} 
            selectedColor={gradient_color_from}/>
          <ListColorPicker 
            colors={solidColors}
            handleColorPicked={setBackgroundGradientColorTo} 
            selectedColor={gradient_color_to}/>                
        </CollapseItem>
      </Panel>
      <Panel header={<h5>{t("BACKGROUND-IMAGE.1")}</h5>} key="4">
        <CollapseItem>          
          <ProjectBackgroundUpload/>          
        </CollapseItem>
      </Panel>
      <Panel header={<h5>{t("BUTTONS-COLOR.1")}</h5>} key="5">
        <CollapseItem>
          <ListColorPicker 
            colors={solidColors}
            handleColorPicked={setButtonColor} 
            selectedColor={button_color}/>
        </CollapseItem>
      </Panel>      
    </Collapse>    
  )
}

export default ColorSettingsModalBody;