import React from 'react';

const CountDownTimerItem = ({time, label, splitter}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{
        display: 'flex', 
        justifyContent: 'space-between',
        width: '100px',
        fontSize: '20px',
        fontWeight: '700'
      }}>
        <div></div>
        <div>{time}</div>
        <div style={{opacity: "0.7"}}>{splitter}</div>
      </div>
      <div style={{fontSize: '.6rem'}}>{label}</div>
    </div>
  )
}

export default CountDownTimerItem;