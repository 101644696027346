import moment from 'moment';

export const momentDurationTimer = (countdownTime) => {
  const given = moment(countdownTime);
  const current = moment().startOf('second');
  const duration = moment.duration(given.diff(current))
  const _days = duration.asDays();
  const days = Math.floor(_days)
  const secs = duration.asSeconds() - days * 24 * 60 * 60;
  let hours   = Math.floor(secs / 3600);
  let minutes = Math.floor((secs - (hours * 3600)) / 60);
  let seconds = secs - (hours * 3600) - (minutes * 60);  
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  
  return [[
    {label: 'DAYS', value: `${days}`, splitter: ":"},
    {label: 'HRS', value: `${hours}`, splitter: ":"},
    {label: 'MIN', value: `${minutes}`, splitter: ":"},
    {label: 'SEC', value: `${seconds}`, splitter: null}
  ], duration.asSeconds()];
}