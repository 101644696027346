import React from "react";
import BooleanBarChart from './BooleanBarChart'
import { PodcastsIcon } from '../../../libs'

const PodcastHealthBarChart = (props) => {
  const {networkName, stats} = props;
  const {data, network_label, network_id} = stats;

  return (
    <div style={{marginTop: '10px'}}>
      <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
        <PodcastsIcon name={network_id}/>
        <div style={{marginLeft: "10px", marginRight: '10px'}}>{network_label}</div>      
        <BooleanBarChart data={data} networkName={`uptime-${networkName}`}/>
      </div>      
    </div>
  )
}

export default PodcastHealthBarChart;