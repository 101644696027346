import React, {useEffect, useState} from 'react';
import {HideOrScheduleIcon} from '../Misc'
import moment from 'moment';

const SpecialEffectIndicator = (props) => {

  const { schedule_from_date, is_hidden} = props;

  const [scheduledHide, setSecheduleHide] = useState(false);
  // ['fa', 'star']
  // ['fa', 'eye-slash']


  useEffect(() => {
    if(schedule_from_date) {
      const given = moment(schedule_from_date);
      const current = moment().startOf('second');
      const duration = moment.duration(given.diff(current));
      const secs = duration.asSeconds()
      
      if(secs > 0) {
        setSecheduleHide(true);
      } else {
        setSecheduleHide(false);
      }
    } else {
      setSecheduleHide(false);
    }
  }, [schedule_from_date])

  const cssStyle = {
    position: "relative",
  }

  const opacityStyle = {
    opacity: (scheduledHide || is_hidden) ? '0.6': null
  }

  const indicatorStyle = {
    position: 'absolute', 
    right: '24px',           
    top: '16px',
  }

  return (
    <div style={cssStyle}>
      <HideOrScheduleIcon schedule_from_date={scheduledHide} is_hidden={is_hidden} cssStyle={indicatorStyle}/>      
      <div style={opacityStyle}>
        {props.children}
      </div>
    </div>
  )  
}

export default SpecialEffectIndicator;