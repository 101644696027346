import React, {useEffect, useState} from 'react';
import { useAtom } from 'jotai';
import { builderAtom, 
  publishBuilder, 
  updateServerBuilder,
  authAtom,
  signupWithCreateProject
} from '../../jotais'
import FooterMenuPublishButton from './FooterMenuPublishButton'
import FooterMenuViewButton from './FooterMenuViewButton'
import AuthModal from './AuthModal'

const FooterMenu = () => {
  const [builder] = useAtom(builderAtom);
  const [user] = useAtom(authAtom);
  const {pies_updated_at, updated_at} = builder;
  const [, createProject] = useAtom(publishBuilder);
  const [, updateProject] = useAtom(updateServerBuilder);
  const [, signupAndCreateProject] = useAtom(signupWithCreateProject)
  const localStorageId = builder.localStorageId;
  const builderUpdateAt = localStorage.getItem(`${localStorageId}_updated_at`);
  const piesUpdateAt = localStorage.getItem(`${localStorageId}_pies_updated_at`);
  const piesServerCopyUpdatedAt = localStorage.getItem(`${localStorageId}_pies_server_copy_updated_at`);
  const pagePath = process.env.REACT_APP_LINK_PAGE_PATH;
  // 3_builder_pies_updated_at
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handClickSignUp = (user_data) => {
    signupAndCreateProject(user_data)
  }

  if(builder.id) {
    // save
    if( (builderUpdateAt && Number(builderUpdateAt) > new Date(builder.updated_at).valueOf()) || (piesUpdateAt && piesServerCopyUpdatedAt && Number(piesUpdateAt) > Number(piesServerCopyUpdatedAt))) {
      return <FooterMenuPublishButton handleClick={updateProject}/>;
    } else {
      return <FooterMenuViewButton artworkUrl={builder.artwork_cdnUrl} shortUrl={`${pagePath}/${builder.slug}`}/>;
    }    
  } else {
    // publish
    if(user.signedIn) {
      return <FooterMenuPublishButton handleClick={createProject}/>;
    } else {
      return (
        <>
          <FooterMenuPublishButton handleClick={() => {setIsModalVisible(true)}}/>
          <AuthModal 
            isModalVisible={isModalVisible} 
            setIsModalVisible={setIsModalVisible}
            handClickSignUp={handClickSignUp}
          />
        </>
      );
    }
  }
}

export default FooterMenu;