import React, {useState} from 'react';
import { PodcastsIcon} from '../../libs'
import {Collapse} from 'reactstrap'

const PodcastNetworksSection = (props) => {
  const {pies, backgroundColor, color} = props;
  const [isOpen, setIsOpen] = useState(false);

  if(pies) {
    return (
        <div style={{
          backgroundColor: backgroundColor,
          color: color,
          minHeight: "60px",
          borderRadius: "10px",
          padding: "10px",
          cursor: 'pointer'
        }}>
          <div onClick={() => {
            setIsOpen(!isOpen)
          }}
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{fontSize: "20px"}}>WHERE TO LISTEN</div>
            <div style={{display: 'flex', gap: '8px'}}>
              {
                pies.slice(0, 5).map((pie, idx) => (
                  <PodcastsIcon key={`${idx}+1`} name={pie.icon_name} color={pie.icon_color} width={"24px"} height={"24px"}/>
                ))
              }
            </div>
          </div>
          <Collapse isOpen={isOpen}>
            <div style={{
              display: 'flex', 
              marginTop: "20px",
              flexWrap: 'wrap',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {props.children}          
            </div>
          </Collapse>
        </div>    
      )
  }

  return null;
}

export default PodcastNetworksSection;