import React from 'react';
import ReactPlayer from 'react-player'
import {getTextColor} from '../../libs'

const CardBlockVideoPlayer = (props) => {
  
  const {handleClick, link_value, text_color, button_color, title, price_tag, image_url, demoView, price_tag_background_color} = props;

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column',
      // borderRadius: "12px",
      minHeight: "240px",
      width: demoView ? "360px" : null,
      backgroundColor: button_color,
      color: getTextColor(button_color),
      cursor: 'pointer',
      boxShadow: demoView ? '5px 10px 8px 10px #C1C1BD' : null
    }}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <ReactPlayer            
          url={link_value}
          className='react-player-video-inline'
          width='100%'
          height='100%'
        />
      </div>    
      {
        title ? <div style={{padding: "10px"}} onClick={handleClick}>
          <div style={{fontSize: "18px", fontWeight: '700'}}>{title}</div>
        </div> : null
      }
      {price_tag ? <div style={{
          padding: "10px",
          alignSelf: 'flex-end'          
        }}>
          <div style={{
            backgroundColor: price_tag_background_color, 
            color: getTextColor(price_tag_background_color),
            fontSize: '14px',
            fontWeight: '700',
            borderRadius: '5px',
            padding: '5px'
          }}
          >{price_tag}</div>
        </div> : null}      
    </div>
  )
}

export default CardBlockVideoPlayer;