import React from 'react';
import {ReactComponent as MailchimpSvg} from '../assets/img/icons/companies/mailchimp.svg'

const CompanyLogoImage = ({name}) => {
  const style={width: "40px", height: "40px"};

  switch(name) {
    case 'MAIL_CHIMP':    
      return <MailchimpSvg style={style}/>                      
    default:
      return null;
  }    
}

export default CompanyLogoImage