import React, {useState} from 'react';
import {useTranslation} from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'reactstrap';

const CollapseItem = (props) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const {label} = props;

  const cssStyle = {
    width: "100%",
    display: 'flex',
    margin: '10px',    
    alignItems: 'baseline'
    // justifyContent: 'space-between'
  }

  return (
    <div>
      {props.children}
    </div>
  )  
}

export default CollapseItem;