import React, {useState} from 'react';
import { AnimatePresence, motion } from 'framer-motion'
import { SplitText } from '../../animations'
import QrCodeModal from './QrCodeModal';
import QrCodeDisplay from './QrCodeDisplay';

const AnimateText = ({visible, text}) => {
  if(visible && text) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SplitText
            initial={{ y: '100%' }}
            animate="visible"
            variants={{
              visible: i => ({
                y: 0,
                transition: {
                  delay: i * 0.1
                }
              })
            }}
          >
            {text}
          </SplitText>
        </motion.div>
      </AnimatePresence>    
    )
  } else {
    return null;
  }  
}

const ShortLinkCopyControl = (props) => {

  const {artworkUrl, shortUrl, publishButtonStyle, qr_color, qr_size, qr_level} = props;

  const [linkCopied, setLinkCopied] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState('Copied');
  
  const linkStyle = {
    cursor: 'pointer',  
    marginRight: '10px',
    fontSize: '18px',
    height: '30px',
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${shortUrl}`).then((x) => {
      setLinkCopied(true);
      setCopiedMessage('Copied')
    })
    .catch((err) => {
      setCopiedMessage('Please allow clipboard access.')
    })
    
    setTimeout(() => {
      setLinkCopied(false);
    }, 4500)
  }

  return (
    <>
      <div style={publishButtonStyle} name="publish">
        <div className='footer-link-hover-underline' style={linkStyle} onClick={handleCopyLink}>        
          {linkCopied ? <AnimateText visible={linkCopied} text={copiedMessage}/> : <AnimateText visible={true} text={shortUrl}/>}
        </div>
        <QrCodeModal> 
          <>
            <p style={{
            fontSize: '1.2rem', 
            fontWeight: "400", 
            textAlign: 'center',
            lineHeight: '1.5'}}>{shortUrl}</p>              
            <QrCodeDisplay
              artworkUrl={artworkUrl}
              shortUrl={shortUrl}
              qr_color={qr_color}
              qr_size={qr_size}
              qr_level={qr_level}          
            /> 
          </>
        </QrCodeModal>
      </div>
  
    </>
  )
}

export default ShortLinkCopyControl;