import React from 'react';
import { useAtom } from 'jotai';
import { builderAtom, getModalPies, cudPie} from '../../jotais'
import {AuthorList, AuthorNewItem} from '../BuilderAuthorBlock'

const AuthorModalBody = () => {
  const [pies] = useAtom(getModalPies);

  const [builder] = useAtom(builderAtom);
  const [, createUpdateDeletePie] = useAtom(cudPie);
  const {modal_title} = builder;
  
  const handleCreate = (data) => {    
    createUpdateDeletePie({...data, modal_title})
  }

  return (
    <div>
      <AuthorList items={pies}/>
      <hr/>
      <AuthorNewItem handleCreate={handleCreate}/>
    </div>
  )
}

export default AuthorModalBody;