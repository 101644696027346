import React, {useState, useEffect} from 'react';
import { useAtom } from "jotai";
import { getPieById , cudPie} from '../../jotais'
import AddSpecialEffect from './AddSpecialEffect';
import {DateRangePicker} from './../SimpleDatePicker'
import {changeDateTimeZone} from '../../libs'

const AddSpecialEffects = (props) => {
  const [pie] = useAtom(getPieById);
  const [, createUpdateDeletePie] = useAtom(cudPie)
  const {is_pop, is_hidden, schedule_from_date_str, schedule_to_date_str, schedule_from_date, schedule_to_date, schedule_time_zone} = pie;

  const timeZone = schedule_time_zone ? schedule_time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const display_effect_options = pie.display_effect_options;

  const [specialEffects, setSpecialEffects] = useState([
    {name: 'POP', val: 'is_pop', icon: ['fa', 'star'], selected: is_pop ? is_pop : false},
    {name: 'HIDE', val: 'is_hidden', icon: ['fa', 'eye-slash'], selected: is_hidden ? true : false},
    {name: 'SCHEDULE', icon: ['fa', 'clock'], selected: (schedule_from_date && schedule_to_date) ? true : false }, 
  ]);

  const [isSchedule, setIsSchedule] = useState((schedule_from_date && schedule_to_date) ? true : false);
  
  const style= {
    display: 'flex',
    justifyContent: 'center',
  }

  const handleClick = (name) => {
    // const pieID = pie.id;
    const newEffects = [...specialEffects];
    const idx = newEffects.findIndex((x) => x.name === name);    
    newEffects[idx].selected = !newEffects[idx].selected;

    const se = newEffects[idx];
    setSpecialEffects(newEffects);
    if(name === 'POP' || name === 'HIDE') {
      const key = se['val'];
      const val = se['selected']
      createUpdateDeletePie({...pie, [key]: val})
    } else {
      if(isSchedule === true) {
        // going to turn into false
        createUpdateDeletePie({...pie, schedule_from_date: null, schedule_to_date: null, schedule_time_zone: null, schedule_from_date_str: null, schedule_to_date_str: null})
      }
      setIsSchedule(!isSchedule);
      // if the schedule has changed false, set from and to date to null
      
    }
  }

  const handleDateChange = (range) => {
    const {from, to} = range;

    const scheduleFromDateStr = from;
    const scheduleToDateStr = to;
    const scheduleFromDate = scheduleFromDateStr ? changeDateTimeZone(from, timeZone) : null;
    const scheduleToDate = scheduleToDateStr ? changeDateTimeZone(to, timeZone) : null;
    // const timeZone = schedule_time_zone ? schedule_time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    createUpdateDeletePie({...pie, 
      schedule_from_date: scheduleFromDate, 
      schedule_to_date: scheduleToDate,      
      schedule_from_date_str: scheduleFromDateStr, 
      schedule_to_date_str: scheduleToDateStr,          
      schedule_time_zone: timeZone      
    })
  }

  const handleTimeZone = (x) => {
    if(x && x.value) {
      const scheduleTimeZone = x.value;

      const scheduleFromDate = schedule_from_date_str ? changeDateTimeZone(schedule_from_date_str, scheduleTimeZone) : null;
      const scheduleToDate = schedule_to_date_str ? changeDateTimeZone(schedule_to_date_str, scheduleTimeZone) : null

      createUpdateDeletePie({...pie, 
        schedule_from_date: scheduleFromDate, 
        schedule_to_date: scheduleToDate,            
        schedule_time_zone: scheduleTimeZone      
      })       
    }
  }

  return (
    <div>
      {props.children}
      {
        display_effect_options ? <div style={style}>{
          specialEffects.map((effect, idx) => <AddSpecialEffect key={idx} name={effect.name} selected={effect.selected} icon={effect.icon} handleClicked={handleClick}/>)
          }</div> : null
      }

      {
        isSchedule && <DateRangePicker
          fromDate={schedule_from_date_str}
          toDate={schedule_to_date_str}
          handleDateChange={handleDateChange}
          pickedTimeZone={timeZone}
          handleTimeZone={handleTimeZone}
        />
      }
    </div>
  )
}

export default AddSpecialEffects;