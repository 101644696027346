import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next'
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import {getRandomId} from '../../libs'
import { useAtom } from 'jotai';
import { builderAtom, getPieById, cudPie, updateOpeningModalPieId} from '../../jotais'
import {FontSizePicker} from '../Misc'

const HeaderBody = () => {
  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie)

  const handleInputChange = (event) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    const name = event.target.name;
    const value = event.target.value;
    createUpdateDeletePie({...pie, [name]: value, modal_title})
  }
  
  return (
    <div>
      <FormGroup role="form">
        <Label for="title">{t("HEADER-TITLE.1")}</Label>
        <Input 
          type="text" 
          name="title" 
          id={pie.id}
          value={pie.title}
          onChange={handleInputChange}
          placeholder={t("HEADER-TITLE.2")}
        />
      </FormGroup>
      <FormGroup role="form">
        <Label for="description">{t("HEADER-DESCRIPTION.1")}</Label>
        <Input 
          type="textarea" 
          name="description" 
          id={pie.id}          
          value={pie.description}
          onChange={handleInputChange}
          placeholder={t("HEADER-DESCRIPTION.2")}
        />
      </FormGroup>
      <Row>
        <Col md={6}>
          <FontSizePicker label="Title Font Size" name="title_font_size" value={pie.title_font_size} handleInputChange={handleInputChange} defaultFontSize="15"/>
        </Col>
        <Col md={6}>
          <FontSizePicker label="Description Font Size" name="description_font_size" value={pie.description_font_size} handleInputChange={handleInputChange}  defaultFontSize="15"/>
        </Col>        
      </Row>
    </div>
  )
}

export default HeaderBody;