import React from 'react';

const HorizontalScroller = (props) => {
  return (
    <div className="horizontal-scroller">
      {props.children}
    </div>
  )
}

export default HorizontalScroller