import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import { cudPie } from '../../jotais'
import { InputGroupAddon,Button,InputGroupText } from 'reactstrap';
import AuthorListItemLayout from './AuthorListItemLayout'
import AuthorListItemCore from './AuthorListItemCore'

const tmpData = {
  title: "",
  description: "",
  link_value: "",
  artwork_uuid: null, 
  artwork_cdnUrl: null, 
  artwork_originalUrl: null, 
  artwork_mime_type: null,
  countdown_from_date_str: null,
  countdown_to_date: null,
  sub_type: "HORIZONTAL-VIEW",
  price_tag: "",
  icon_group: ""
}


const AuthorListItem = ({id, link_value, title}) => {

  const [, changePie] = useAtom(cudPie);

  const handleDeleteClicked = () => {
    changePie({...tmpData, id});
  }

  const handleInputChange = (newData) => {
    changePie({id, ...newData})
  }

  return (
    <AuthorListItemLayout>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FontAwesomeIcon icon={['fas', 'bars']} color="black"/> 
        </InputGroupText>
      </InputGroupAddon>
      <AuthorListItemCore
        link_value={link_value}
        title={title}
        handleChange={handleInputChange}
      />
      <InputGroupAddon addonType="append">
        <Button outline color="secondary" onClick={handleDeleteClicked}>
          <FontAwesomeIcon icon={['fas', 'trash-alt']} color="black"/> 
        </Button>
      </InputGroupAddon>
    </AuthorListItemLayout>
  )  
}

export default AuthorListItem;