import React, {useState, useEffect} from 'react';
import CountDownTimerItem from './CountDownTimerItem'
import {momentDurationTimer} from '../../libs'

// https://stackoverflow.com/questions/40885923/countdown-timer-in-react
// https://dmitripavlutin.com/react-useeffect-explanation/

const CountDownClock = ({countdown_to_date}) => {
  const [timers, setTimers] = useState([[], 0]);
  const [clock, _] = timers;
  // const timers = momentDurationTimer(countdown_to_date)

  useEffect(() => {
    const timerId = setInterval(() => {
      const _timers = momentDurationTimer(countdown_to_date);
      const [_, duration] = _timers;
      setTimers(_timers);
      if(duration <= 0) {
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [countdown_to_date])

  // useEffect(()=>{
  //   let myInterval = setInterval(() => {
  //           if (duration > 0) {
  //               //setSeconds(seconds - 1);
  //           }
  //           if (seconds === 0) {
  //               if (minutes === 0) {
  //                   clearInterval(myInterval)
  //               } else {
  //                   setMinutes(minutes - 1);
  //                   setSeconds(59);
  //               }
  //           } 
  //       }, 1000)
  //       return ()=> {
  //           clearInterval(myInterval);
  //         };
  //   });

  
  return (
    <div style={{marginTop: "20px", marginBottom: "20px", display: 'flex', maxWidth: '400px', justifyContent: 'center'}}>
      {
        clock.map((item, idx) => <CountDownTimerItem key={idx} time={item.value} label={item.label} splitter={item.splitter}/>)
      }
    </div>
  )
}

export default CountDownClock;