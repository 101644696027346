import moment from 'moment-timezone';

export const changeDateTimeZone = (dateStr, timeZone) => {
  if(dateStr) {
    // const dateStr = moment(given).format("YYYY-MM-DD HH:MM");
    // const givenDate = moment(dateStr);  
    // const newDate = new Date(`${dateStr} ${timeZone}`)
    
    const date = moment.tz(dateStr, timeZone);    
    const newDate = date.clone().toDate();
    return newDate;
  }

  return dateStr; // which should be null
}

export const getDateTimeString = (date) => {

  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let hours = date.getHours();
  let mins = date.getMinutes();
  let secs = "00"

  day = day > 10 ? `${day}` : `0${day}`;
  month = month > 10 ? `${month}` : `0${month}`;
  hours = hours > 10 ? `${hours}` : `0${hours}`;
  mins = mins > 10 ? `${mins}` : `0${mins}`;

  return `${year}-${month}-${day}T${hours}:${mins}:${secs}`;
  // return date.getFullYear() + '-' + 
  // (date.getMonth() + 1) + '-' + 
  // date.getDate() + ' ' + 
  // date.getHours() + ':' + 
  // date.getMinutes();
}

export const updateTimeToDateString = (datetimeStr, time) => {
  if(datetimeStr && datetimeStr.split("T").length === 2) {
    const dateStr = datetimeStr.split("T")[0];
    return `${dateStr}T${time}`;
  }

  return datetimeStr
}