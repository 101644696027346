import React, {useEffect, useState} from 'react';
import {PiePlayer} from 'react-podcast-ninja'
// import { builderAtom, piesAtom} from '../../jotais'
import {useFetchRss} from '../../hooks'
import LoadingPodcastStatus from './LoadingPodcastStatus'
import ErrorPodcastStatus from './ErrorPodcastStatus'
import {BuilderPieControlHandler} from './../Misc'
import {getPodcastSubType} from '../../libs'


const PodcastBlockRender = (props) => {
  const {id, text_color, button_text_color, button_color, main_type, modal_title, 
    sub_type, name, title, price_tag, description, image_url, value, placeholder,
    icon_group, icon_name, link_value, demoView, handleClick} = props;

  const [podcast, error, loading, fetchData] = useFetchRss();
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [people, setPeople] = useState([]);
  const [podcastTitle, setPodcastTitle] = useState("")
  const [podcastArtwork, setPodcastArtwork] = useState("")
  const [theme, setTheme] = useState({
    textColor: text_color,
    backgroundColor: text_color,
    cardBackgroundColor: button_color,
    cardTextColor: button_text_color,
    navControler: false,
    themeClassName: null,
  })

  // const {navControler, className} = getPodcastSubType(sub_type)

  useEffect(() => {
    fetchData(link_value);
  },[link_value])

  useEffect(() => {
    const {navControler, className} = getPodcastSubType(sub_type)
    setTheme({...theme, navControler, themeClassName: className})
  }, [sub_type])

  useEffect(() => {
    if(podcast && podcast.episodes && podcast.episodes.length > 0) {
      const num = value ? Number(value) : 0;
      const _items = podcast.episodes.slice(0, num);
      setItems(_items);
      setAllItems(podcast.items.slice(0, 5)); // because we know we only want MAX 5
      setPodcastTitle(podcast.title);

      setPodcastArtwork(podcast.image_url);

      if(podcast.people && podcast.people.length > 0) {
        setPeople(podcast.people);
      } else {
        setPeople([])
      }
    }
  }, [podcast]);

  useEffect(() => {
    if(value && allItems && allItems.length > 0) {
      const num = value ? Number(value) : 0;
      const _items = podcast.episodes.slice(0, num);
      setItems(_items);      
    }
  }, [value])

  if(error) {
    return <ErrorPodcastStatus url={link_value} handleClick={handleClick}/>
  } else if (loading) {
    return <LoadingPodcastStatus demoView={demoView} loading={loading} button_color={button_color}/>
  } else if (items.length === 0) {
    return <ErrorPodcastStatus url={link_value} handleClick={handleClick}/>
  } else {
    return (
      <BuilderPieControlHandler
        handleClick={handleClick}
        demoView={demoView}
        button_text_color={button_text_color}
        button_color={button_color}
      >
        <div style={{width: demoView ? "320px" : null,}}>
          <PiePlayer
            sectionTitle="Latest episodes"
            artwork={podcastArtwork}
            theme={theme}
            episodes={items}
            title={podcastTitle}
            people={people}
            peopleTitle={placeholder}
          />              
        </div>                  
      </BuilderPieControlHandler>
    )
  }  
}

export default PodcastBlockRender;