import { useState, useEffect } from 'react';
import msApi from '../api/msApi'

const useFetchRss = () => {
  // const [state, setState] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [podcast, setPodcast] = useState({});

  const fetchData = (uri) => {
    if(uri) {
      setLoading(true);
      msApi.get(`/v1/feed_parser?uri=${uri}`)
      .then((res) => {
        
        setLoading(false)
        const {title, description, people, itunes, items, episodes} = res.data;        
        const image_url = itunes.image;
        setPodcast({title, description, image_url, items, people, episodes})
      })
      .catch((err) => {
        setLoading(false);
        // setError(err.response.statusText);
      })
    } else {
      setPodcast({})
      setError(null);
    }  
  }


  // useEffect(() => {
  //   fetchData();
  // }, [uri])  

  return [podcast, error, loading, fetchData];
}

export default useFetchRss;