import React from "react";
import DropdownOptions from './DropdownOptions'

const CardTable = (props) => {

  const {options} = props;

  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col ml-n2">
            <h3 className="mb-1">Mailing Integrations</h3>
          </div>
          <div className="col-auto">
            <DropdownOptions options={options}/>  
          </div>          
        </div>  
        <hr/>
        {props.children}      
      </div>      
    </div>
  )
}

export default CardTable;