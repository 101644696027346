import React from 'react';
import { useAtom } from 'jotai';
import { getMessengerPies, builderAtom} from '../../jotais'
import { MessageBlock } from '../BuilderBlock'

const MessengersBlock = () => {

  const cssStyle= {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // backgroundColor: '#fff'
  }

  const [pies] = useAtom(getMessengerPies);
  const [builder] = useAtom(builderAtom);
  const piesCount = pies.length;
  const {text_color} = builder;
  // const flexBasis = (pies.length % 2 === 1 ? );

  return (
    <div style={cssStyle}>
      {
        pies.map((pie, idx) => {
          return (
            <MessageBlock 
              pos={idx} 
              key={idx}
              value={pie.value} 
              name={pie.name} 
              piesCount={piesCount}  
              icon_group={pie.icon_group}
              icon_name={pie.icon_name}
              // icon_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.title_text_color : button_color}                            
              icon_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.icon_color : text_color}
          />
          )
        })
      }
    </div>
  )
}

export default MessengersBlock;