import { atom } from "jotai";
import AsyncStorage from '../auth/AsyncStorage'
import { removeLocalBuilderData,  Mixpanel} from './../libs'
import railsApi from '../api/railsApi'
import { notificationAtom } from './notificationAtom'
import { builderAtom, builderTemp }  from './builderAtom'
import { piesAtom} from './piesAtom'

const temp = {
  id: '',
  uid: null,  
  email: '',
  client: null,
  expiry: null,
  accessType: null,  
  accessToken: null,
  signedIn: undefined,
  type: 'waiting'
}

export const authAtom = atom(temp);

export const tryLocalSignin = atom(null, (_get, set) => {
  set(authAtom, async () => {
    AsyncStorage.updateHeaderCommons(); // load headers into apis
    try {
      const res = await railsApi.get('/auth/validate_token');
      const headers = res.headers;
      const user = res.data.data;
      AsyncStorage.setHeaderItems(headers);
      return {...temp, ...user, signedIn: true, type: 'signin'}
    } catch (err) {
      AsyncStorage.removeHeaderAuthItems()
      return {...temp, signedIn: false, type: 'signout'};
    }
  });
})

export const signin = atom(null, (get, set, tryUser) => {
  const {email, password, is_activated} = tryUser;
  const _email = email.toLowerCase();
  const notiData = get(notificationAtom);

  set(authAtom, async () => {        
    try {      
      const res = await railsApi.post('/auth/sign_in', {email: _email, password})
      const headers = res.headers;
      const user = res.data.data;
      AsyncStorage.setHeaderItems(headers);
      // navigate('/dashboard')
      Mixpanel.identify(user.id);
      Mixpanel.track('Successful login', {email: _email});            
      Mixpanel.people.set({$email: user.email, $is_email_invite: is_activated});

      if (user.is_activated !== true && is_activated === true) {
        // to update invite user status
        const _ = await railsApi.put('/auth', {is_activated: true})
      }
      return {...temp, ...user, signedIn: true, type: 'signin'}
            
    } catch (err) {
      AsyncStorage.removeHeaderItems()
      console.log(err);
      Mixpanel.track('Unsuccessful login', {email: _email});
      
      const notiDataId = (notiData.id ? notiData.id : 1) + 1;      
      set(notificationAtom, () => {
        return {...notiData, 
          id: notiDataId,
          status: true, 
          message: `Failed to Login`, 
          description: "Email and Password are not matched.  Please try again",
          redirect: false,
          type: 'error'
        }
      })    

      return {...temp, signedIn: false, type: 'add_error'};
    }
  })
})

export const signout = atom(null, (get, set, _) => {
  const user = get(authAtom);
  const {uid, client, accessToken} = user;
  
  set(authAtom, async () => {
    try {
      const res = await railsApi.delete('/auth/sign_out', {
        uid, client, 'access-token': accessToken
      })
      AsyncStorage.removeHeaderItems()
      return {...temp, signedIn: false, type: 'signout'}
    }
    catch (err) {   
      AsyncStorage.removeHeaderItems();
      return temp;
    }
  }) 
})

export const updatePassword = atom(null, (get, set, data) => {
  const currentUser = get(authAtom)
  const {password, passwordConfirmation} = data;
  const notiData = get(notificationAtom);

  set(authAtom, async () => {
    try {
      const res = await railsApi.put('/auth/password', {password: password, password_confirmation: passwordConfirmation})
      const headers = res.headers;
      const user = res.data.data;
      AsyncStorage.setHeaderItems(headers);
      set(notificationAtom, () => {
        return {...notiData, 
          status: true, 
          message: `Password updated`, 
          description: `Password has been updated.`,
          redirect: false,
          type: 'success'
        }
      })      
      return {...temp, ...user, signedIn: true, type: 'signin'};      
    } catch (err) {
      const errMsg = err.response.data.errors.full_messages[0];

      set(notificationAtom, () => {
        return {...notiData, 
          status: true, 
          message: `Password update failed`, 
          description: errMsg,
          redirect: false,
          type: 'error'
        }
      }) 
      return currentUser;
    }
  })
})

export const signup = atom(null, (get, set, data) => {
  const {email, password} = data;
  const _email = email.toLowerCase();
  set(authAtom, async () => {        
    try {      
      const res = await railsApi.post('/auth', {email: _email, password})
      const headers = res.headers;
      const user = res.data.data;
      AsyncStorage.setHeaderItems(headers);
      // navigate('/dashboard')
      Mixpanel.identify(user.id);
      Mixpanel.track('Successful sign up', {email: _email});
      Mixpanel.people.set({$email: user.email});

      return {...temp, ...user, signedIn: true, type: 'signin'}
    } catch (err) {
      // AsyncStorage.removeHeaderItems()
      // console.log(err.response.data);      
      const data = get(notificationAtom);
      const notiDataId = (data.id ? data.id : 1) + 1;

      set(notificationAtom, () => {
        return {...data,
          id: notiDataId, 
          status: true, 
          message: `Sign up error`, 
          description: `Email has already been taken.  Please try with another one.`,
          redirect: false,
          type: 'error'
        }
      })
      Mixpanel.track('Unsuccessful login', {email: _email});
      return {...temp, signedIn: false, type: 'signout'};      
    }
  })  
})

export const signupWithCreateProject = atom(null, (get, set, data) => {
  const {email, password} = data;
  set(authAtom, async () => {        
    try {
      const _email = email.toLowerCase();
      const res = await railsApi.post('/auth', {email: _email, password})
      const headers = res.headers;
      const user = res.data.data;
      AsyncStorage.setHeaderItems(headers);

      const accessToken = headers['access-token']
      const client = headers['client']
      const expiry = headers['expiry']
      const tokenType = headers['token-type']
      const uid = headers['uid']
      
      const reqHeaders = {
        'access-token': accessToken,
        'client': client,
        'expiry': expiry,
        'tokenType': tokenType,
        'uid': uid,
      }
      
      // railsApi.defaults.headers.common['uid'] = uid;
      // railsApi.defaults.headers.common['token-type'] = tokenType;
      // railsApi.defaults.headers.common['expiry'] = expiry;
      // railsApi.defaults.headers.common['access-token'] = accessToken;
      // railsApi.defaults.headers.common['client'] = client;

      const builderData = get(builderAtom);
      const pies = get(piesAtom);
      const notiData = get(notificationAtom);

      const localStorageId = builderData.localStorageId;
      const project = await railsApi.post('/v1/projects', builderData, {
        headers: reqHeaders
      });
      const project_id = project.data.id      
      
      const piesData = await railsApi.post(`/v1/projects/${project_id}/pies/sync`, pies, {
        headers: reqHeaders
      })

      set(builderAtom, () => {
        return builderTemp;
      })

      set(piesAtom, () => {
        return [];
      })

      Mixpanel.identify(user.id);
      Mixpanel.track('Successful sign up', {email: _email, project_id: project_id, sign_up_with_create_project: true});
      Mixpanel.people.set({$email: user.email});

      removeLocalBuilderData({localStorageId});

      window.location = '/dashboard'
      return {...temp, ...user, signedIn: true, type: 'signin'}
    } catch (err) {
      // AsyncStorage.removeHeaderItems()
      const data = get(notificationAtom);
      const notiDataId = (data.id ? data.id : 1) + 1;

      set(notificationAtom, () => {
        return {...data, 
          id: notiDataId,
          status: true, 
          message: `Sign up error`, 
          description: `Email has already been taken.  Please try with another one.`,
          redirect: false,
          type: 'error'
        }
      })

      return {...temp, signedIn: false, type: 'signout'};      
    }
  })   
})