import React, { useEffect, useState} from "react";
import { Skeleton } from 'antd';
import { Route, Redirect } from 'react-router-dom'
import { useAtom } from 'jotai';
import { authAtom, tryLocalSignin } from '../jotais'
import '../assets/styles/media-player.css';

const BuilderRoute = ({ component: Component, ...rest }) => {

  const [authUser] = useAtom(authAtom);
  const [, trylocalSignIn] = useAtom(tryLocalSignin);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    trylocalSignIn()
  }, [])

  if(authUser.type === 'signout' ) {
    return <Redirect to='/signin'/>;
  } else if (authUser.signedIn) {

    return (
      <Route {...rest} render={(props) => (
        <>
          <Component {...props} />
        </>
      )}/>
    )
    // if we want to have some sort of feeling that page is loading
    // setTimeout(() => {
    //   setLoading(false)
    // }, 2000);

    // if(loading) {
    //   return <Skeleton avatar paragraph={{ rows: 10 }} active/>
    // } else {
    //   return (
    //     <Route {...rest} render={(props) => (
    //       <>
    //         <Component {...props} />
    //       </>
    //     )}/>
    //   )
    // }
  } else {
    return null;
  }
  
}

export default BuilderRoute;