import React from 'react';
import { Input} from 'reactstrap';
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'

const MailingListBlock = (props) => {
  const {id, text_color, button_color, main_type, modal_title, sub_type, name, title, description, placeholder, price_tag, value, demoView, success_message, integration_id, campaign_tag, button_text} = props;
  const [, toggle] = useAtom(toggleBuilderModal);

  // placeholder = button_text
  // title = title
  // description = success_message
  // price_tag = tag_value
  // value = integration id

  const cssStyle = {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",     
    width: demoView ? "320px" : null,
    minHeight: "60px",
    borderRadius: "10px",
    backgroundColor: button_color,
    color: `${text_color}`,
    borderColor: `${text_color}`,
  }  

  const handleClick = () => {
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }

  if(demoView) {
    return (
      <div style={{...cssStyle,         
        boxShadow: '5px 10px 8px 10px #C1C1BD'
      }}>        
        <div style={{fontSize: "18px", fontWeight: '700', textAlign: 'center'}}>{title}</div>
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
          <Input
            disabled
            placeholder="Your email" 
          />
        </div>
        <div style={{
          marginTop: '20px', 
          color: button_color,
          background: text_color, 
          borderRadius: "10px",
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: "14px", 
          fontWeight: '700',
          cursor: 'pointer'
        }}>{button_text}</div>       
      </div> 
    )
  }  

  return (
    <div style={cssStyle} onClick={handleClick}>
      <div style={{fontSize: "18px", fontWeight: '700', textAlign: 'center'}}>{title}</div>
      <div style={{marginTop: '10px', marginBottom: '10px'}}>
        <Input
          disabled
          placeholder="Your email" 
        />
      </div>
      <div style={{
        marginTop: '20px', 
        marginBottom: '10px',
        color: button_color,
        background: text_color, 
        borderRadius: "10px",
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: "14px", 
        fontWeight: '700',
        cursor: 'pointer'
      }}>{button_text}</div>
    </div>
  )
}

export default MailingListBlock;