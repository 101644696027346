import React, {useRef} from 'react';
import { Avatar } from 'antd';
import { useAtom } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateBuilder, builderAtom, getUpperSectionContrastColor} from '../../jotais'
import {FileUpload} from './../BuilderBlock'

const HeaderAvatar = () => {
  const widgetApi = useRef();
  const [, updateData] = useAtom(updateBuilder);
  const [borderColor] = useAtom(getUpperSectionContrastColor);
  const [builder] = useAtom(builderAtom);
  const {text_color, artwork_uuid, artwork_cdnUrl, artwork_originalUrl} = builder;

  const cssStyle = {
    width: "100px",
    height: "100px",
    // borderRadius: '100px',
    margin: "16px",
    cursor: 'pointer',
    borderStyle: artwork_cdnUrl ? null : 'dashed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: artwork_cdnUrl ? null : `${borderColor}`
  }

  const handleClick = () => {
    widgetApi.current.openDialog()
  }

  const handleUploaded = (data) => {
    // console.log('hi')
    const {cdnUrl, originalUrl, uuid, mimeType} = data;
    updateData({
      artwork_uuid: uuid,
      artwork_cdnUrl: cdnUrl,
      artwork_originalUrl: originalUrl,  
      artwork_mime_type: mimeType    
    })
  }

  return (    
      <div style={cssStyle} onClick={handleClick}>
        <FileUpload widgetApi={widgetApi} handleChanged={handleUploaded}>
          {artwork_cdnUrl ? <Avatar size={100} shape="square" src={artwork_cdnUrl}/> : <FontAwesomeIcon 
            icon={['fas', 'camera']} 
            size="3x"
            color={borderColor}
          /> }
        </FileUpload>
      </div>
    
  )
}

export default HeaderAvatar;