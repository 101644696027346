import React, {useEffect, useState} from 'react';
import {Steps, Divider, Input, Modal, Spin, Space, Button, List, Avatar } from 'antd';
import {Card, CardBody} from 'reactstrap'
import axios from 'axios';

const { Search } = Input;
const baseURL = process.env.REACT_APP_MS_BASE_PATH;
// Init a timeout variable to be used below
let timeout = null;

const ImportPodcast = (props) => {
  const {defaultSearchTitle, handlePickedPodcast, handleMessageCallback, handleMessageVisable} = props;

  const [items, setItems] = useState([]);

  useEffect(() => {
    if(defaultSearchTitle && defaultSearchTitle.length > 0) {
      axios.post((`${baseURL}/v1/itunes_podcast`), {term: defaultSearchTitle})
      .then((res) => {
        const results = res.data.results;
        setItems(results)
      })
      .catch((err) => {
        console.log(err)
      })   
    }
  }, [defaultSearchTitle])

  const onSearch = (value) => {      
    if(value && value.length > 0) {
      axios.post((`${baseURL}/v1/itunes_podcast`), {term: value})
      .then((res) => {
        const results = res.data.results;
        setItems(results)
      })
      .catch((err) => {
        console.log(err)
      })      
    } else {
      setItems([])
    }  
  }

  const onChange = (event) => {
    const value = event.target.value;
    if(timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if(value && value.length > 0) {
        const term = encodeURIComponent(value);
        axios.post((`${baseURL}/v1/itunes_podcast`), {term})
        .then((res) => {
          const results = res.data.results;
          setItems(results)
        })
        .catch((err) => {
          setItems([])       
          console.log(err)
        })  
      } else {
        setItems([])
      }
    }, 300)
  }

  const handleChoose = async (item) => {
    let networks = []

    const {artworkUrl600, trackId, trackViewUrl, trackName, feedUrl, artistName} = item;
    if(trackViewUrl) {
      networks.push({name: "APPLE-PODCAST", url: trackViewUrl})
    }

    if(feedUrl) {
      networks.push({name: "RSS", url: feedUrl})
    }
    //get basic networks
    try {
      const getNetworks = await axios.post((`${baseURL}/v1/basic_networks`), {itunesId: trackId, rssFeed: feedUrl, name: trackName})
      const basicNetworks = getNetworks.data;
      
      // setModalMessage(`Found ${trackName} on ${basicNetworks.length} popular podcast app`);
      if(handleMessageCallback) {handleMessageCallback(`Searching ${trackName} on iHeart Podcast`)};
      networks = [...networks,...basicNetworks];
      if(handleMessageVisable) {
        handleMessageVisable(true);
      }      

      // set state to indicate we has parsed a list of network, and how many of them has been parsed
      const feedRes = await axios.post((`${baseURL}/v1/itunes_podcast_rss_feed_data`), {rssFeed: feedUrl})
      if(feedRes && feedRes.data) {
        const {summary, description, image, subtitle} = feedRes.data; 
             
        const iHeartRadio = await axios.post(`${baseURL}/v1/podcasts_iHeartRadio`, {subtitle, name: trackName})        
        if(iHeartRadio.data) {
          networks.push(iHeartRadio.data)
          // setModalMessage(`Found ${trackName} on iHeart`);
        }
        // console.log(iHeartRadio.data)
        // const html_description = summary.replace(/[\r\n]/g, ' ').replace(/\s+/g, ' ').replace(/ >/g, '>').replace(/> </g, '><').trim();
        if(summary || description) {
          if(handleMessageCallback) {handleMessageCallback(`Searching ${trackName} on Stitcher`)};
          const stitcher = await axios.post(`${baseURL}/v1/podcasts_stitcher`, {summary: summary ? summary : description, name: trackName})              
          if(stitcher.data) {          
            networks.push(stitcher.data)
            // setModalMessage(`Found ${trackName} on Stitcher`);
          }     
        }
   
        // // console.log(stitcher.data)
        const playerFM = await axios.post(`${baseURL}/v1/podcasts_player_fm`, {artistName, name: trackName})        
        if(handleMessageCallback) {handleMessageCallback(`Searching ${trackName} on Player FM`)};
        if(playerFM.data) {
          networks.push(playerFM.data)          
          // setModalMessage(`Found ${trackName} on Player FM`);
        }          
        const spotify = await axios.post(`${baseURL}/v1/podcasts_spotify`, {summary, name: trackName})        
        if(handleMessageCallback) {handleMessageCallback(`Searching ${trackName} on Spotify`)};
        if(spotify.data) {
          networks.push(spotify.data)
          // setModalMessage(`Found ${trackName} on Spotify`);
        }           
        const googlePodcasts = await axios.post(`${baseURL}/v1/podcasts_google`, {rssFeed: feedUrl})
        if(handleMessageCallback) {handleMessageCallback(`Searching ${trackName} on Google Podcast`)};
        if(googlePodcasts.data) {
          networks.push(googlePodcasts.data)
          // setModalMessage(`Found ${trackName} on Google Podcast`);
        }           
      }      
    } catch (err){
      console.log(err);
    }

    handlePickedPodcast({networks, artworkUrl600, trackId, trackViewUrl, trackName, feedUrl, artistName})
  }

  return (
    <div>
        {props.children}
        <Search
          placeholder="Search for podcast"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearch}
          onChange={onChange}
          defaultValue={defaultSearchTitle}
        />
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={items}
          renderItem={item => (
            <List.Item
              style={{cursor: 'pointer'}}
              actions={[<div key="list-loadmore-edit">Choose</div>]}
              onClick={() => {handleChoose(item)}}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={item.artworkUrl600} />
                }
                title={<div>{item.trackName}</div>}
                description={item.artistName}
              />
            </List.Item>
          )}
        />  

    </div>    
  )
}

export default ImportPodcast;