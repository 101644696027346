import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import { builderAtom } from '../../jotais'
import { PodcastsIcon } from '../../libs'
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button, FormGroup, FormText } from 'reactstrap';

const ListBodyItem = (props) => {
  const {id, name, is_username, social_username, link_value, placeholder, idx, icon_color, icon_group, icon_name, handleChange, sortable} = props;

  const [builder] = useAtom(builderAtom);
  const {modal_data_name } = builder;
  const [inputInvalid, setInputInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  // console.log(prefix_icon_group)
  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const value = event.target.value;

    if(is_username && value.indexOf('/') >= 0) {
      setInputInvalid(true);
      setErrorMessage(`Please make sure ${value} is the username.`)
    } else {
      if(inputInvalid) {
        setInputInvalid(false);
      }
      // handleChange(idx, inputName, value);
      handleChange(idx, {[inputName]: value});
    }    
  }

  const handleDeleteClick = () => {
    if(is_username) {
      handleChange(idx, {link_value: "", social_username: ""});
    } else {
      handleChange(idx, {link_value: ""});
    }    
  }

  return (
    <div style={{marginBottom: "10px"}}>
      <InputGroup size="lg">
        <InputGroupAddon addonType="prepend">
          {sortable ? <InputGroupText style={{backgroundColor: link_value ? "#B7C4DF" : "#fff"}}>
            <FontAwesomeIcon icon={['fas', 'bars']} color="black"/> 
          </InputGroupText> : null}          
          <InputGroupText style={{backgroundColor: link_value ?  "#B7C4DF" : "#fff"}} color="black">
            {
              modal_data_name === 'PODCAST-NETWORK' ? <PodcastsIcon name={icon_name}/> : <FontAwesomeIcon icon={[icon_group, icon_name]} color="black"/> 
            }
          </InputGroupText>
        </InputGroupAddon>
        <Input
          invalid={inputInvalid}
          name={is_username ? "social_username" : "link_value"} 
          placeholder={placeholder}
          value={is_username ? (social_username ? social_username : "") : link_value}
          onChange={handleInputChange}
          style={{color: "#07090C"}}
        />
        <InputGroupAddon addonType="append">
          <Button outline color="secondary" onClick={handleDeleteClick}>
            <FontAwesomeIcon icon={['fas', 'trash-alt']} color="black"/> 
          </Button>
        </InputGroupAddon>
       </InputGroup>
       {inputInvalid ? <FormText color="danger" >{errorMessage}</FormText> : null}
    </div>
  )
}

export default ListBodyItem;
