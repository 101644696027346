const turnArrayIntoHashByKey = (arr, keyName) => {
  const result = {};

  for(let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const key = item[keyName];
    result[key] = item;
  }

  return result;
}

export default turnArrayIntoHashByKey;