import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAtom } from 'jotai';
import { addPie, updatePie, sortPies } from '../../jotais'
import AuthorListItem from './AuthorListItem'

const AuthorList = ({items, sortableName, handleDDEnd}) => {
  
  const [, addBuilderPie] = useAtom(addPie);
  const [, updateBuilderPie] = useAtom(updatePie);
  const [, sortBuilderPies] = useAtom(sortPies);

  const handleOnDragEnd = (result) => {    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination ? result.destination.index : -1;
    if(destinationIndex >= 0) {
      const source = items[sourceIndex];
      const destination = items[destinationIndex];      
      sortBuilderPies({sourcePieId: source.id, destinationPieId: destination.id})
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="authors">
        {(provided) => (
          <div className="authors"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {
              items.map((item, idx) => {
                return (
                  <Draggable key={`authors-${idx}`} draggableId={`authors-${idx}`} index={idx}>
                    {(provided) => {
                      return (
                        <div 
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <AuthorListItem
                            key={idx}
                            id={item.id}
                            link_value={item.link_value}
                            title={item.title}
                          />
                        </div>
                      )
                    }}
                  </Draggable>                  
                )}
              )
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default AuthorList;