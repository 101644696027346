const dicts = {
  'MIX-CARD-TILE': {className: 'jc-npp-mix', navControler: false},
  'THUMBNAIL': {className: 'jc-npp-thumbnail', navControler: false},
  'CARD': {className: 'jc-npp-card', navControler: false},
  'TILE': {className: 'jc-npp-tile', navControler: true}
}

const getPodcastSubType = (keyword) => {
  if(dicts[keyword]) {
    return dicts[keyword];
  }  
  
  return {}
}

export {
  getPodcastSubType
}