const data = {
  "fas ad": "Ad",
"fas address-book": "Address book",
"fas address-card": "Address card",
"fas adjust": "Adjust",
"fas air-freshener": "Air freshener",
"fas align-center": "Align center",
"fas align-justify": "Align justify",
"fas align-left": "Align left",
"fas align-right": "Align right",
"fas allergies": "Allergies",
"fas ambulance": "Ambulance",
"fas american-sign-language-interpreting": "American sign language interpreting",
"fas anchor": "Anchor",
"fas angle-double-down": "Angle double down",
"fas angle-double-left": "Angle double left",
"fas angle-double-right": "Angle double right",
"fas angle-double-up": "Angle double up",
"fas angle-down": "Angle down",
"fas angle-left": "Angle left",
"fas angle-right": "Angle right",
"fas angle-up": "Angle up",
"fas angry": "Angry",
"fas ankh": "Ankh",
"fas apple-alt": "Apple alt",
"fas archive": "Archive",
"fas archway": "Archway",
"fas arrow-alt-circle-down": "Arrow alt circle down",
"fas arrow-alt-circle-left": "Arrow alt circle left",
"fas arrow-alt-circle-right": "Arrow alt circle right",
"fas arrow-alt-circle-up": "Arrow alt circle up",
"fas arrow-circle-down": "Arrow circle down",
"fas arrow-circle-left": "Arrow circle left",
"fas arrow-circle-right": "Arrow circle right",
"fas arrow-circle-up": "Arrow circle up",
"fas arrow-down": "Arrow down",
"fas arrow-left": "Arrow left",
"fas arrow-right": "Arrow right",
"fas arrow-up": "Arrow up",
"fas arrows-alt": "Arrows alt",
"fas arrows-alt-h": "Arrows alt h",
"fas arrows-alt-v": "Arrows alt v",
"fas assistive-listening-systems": "Assistive listening systems",
"fas asterisk": "Asterisk",
"fas at": "At",
"fas atlas": "Atlas",
"fas atom": "Atom",
"fas audio-description": "Audio description",
"fas award": "Award",
"fas baby": "Baby",
"fas baby-carriage": "Baby carriage",
"fas backspace": "Backspace",
"fas backward": "Backward",
"fas bacon": "Bacon",
"fas balance-scale": "Balance scale",
"fas balance-scale-left": "Balance scale left",
"fas balance-scale-right": "Balance scale right",
"fas ban": "Ban",
"fas band-aid": "Band aid",
"fas barcode": "Barcode",
"fas bars": "Bars",
"fas baseball-ball": "Baseball ball",
"fas basketball-ball": "Basketball ball",
"fas bath": "Bath",
"fas battery-empty": "Battery empty",
"fas battery-full": "Battery full",
"fas battery-half": "Battery half",
"fas battery-quarter": "Battery quarter",
"fas battery-three-quarters": "Battery three quarters",
"fas bed": "Bed",
"fas beer": "Beer",
"fas bell": "Bell",
"fas bell-slash": "Bell slash",
"fas bezier-curve": "Bezier curve",
"fas bible": "Bible",
"fas bicycle": "Bicycle",
"fas biking": "Biking",
"fas binoculars": "Binoculars",
"fas biohazard": "Biohazard",
"fas birthday-cake": "Birthday cake",
"fas blender": "Blender",
"fas blender-phone": "Blender phone",
"fas blind": "Blind",
"fas blog": "Blog",
"fas bold": "Bold",
"fas bolt": "Bolt",
"fas bomb": "Bomb",
"fas bone": "Bone",
"fas bong": "Bong",
"fas book": "Book",
"fas book-dead": "Book dead",
"fas book-medical": "Book medical",
"fas book-open": "Book open",
"fas book-reader": "Book reader",
"fas bookmark": "Bookmark",
"fas border-all": "Border all",
"fas border-none": "Border none",
"fas border-style": "Border style",
"fas bowling-ball": "Bowling ball",
"fas box": "Box",
"fas box-open": "Box open",
"fas boxes": "Boxes",
"fas braille": "Braille",
"fas brain": "Brain",
"fas bread-slice": "Bread slice",
"fas briefcase": "Briefcase",
"fas briefcase-medical": "Briefcase medical",
"fas broadcast-tower": "Broadcast tower",
"fas broom": "Broom",
"fas brush": "Brush",
"fas bug": "Bug",
"fas building": "Building",
"fas bullhorn": "Bullhorn",
"fas bullseye": "Bullseye",
"fas burn": "Burn",
"fas bus": "Bus",
"fas bus-alt": "Bus alt",
"fas business-time": "Business time",
"fas calculator": "Calculator",
"fas calendar": "Calendar",
"fas calendar-alt": "Calendar alt",
"fas calendar-check": "Calendar check",
"fas calendar-day": "Calendar day",
"fas calendar-minus": "Calendar minus",
"fas calendar-plus": "Calendar plus",
"fas calendar-times": "Calendar times",
"fas calendar-week": "Calendar week",
"fas camera": "Camera",
"fas camera-retro": "Camera retro",
"fas campground": "Campground",
"fas candy-cane": "Candy cane",
"fas cannabis": "Cannabis",
"fas capsules": "Capsules",
"fas car": "Car",
"fas car-alt": "Car alt",
"fas car-battery": "Car battery",
"fas car-crash": "Car crash",
"fas car-side": "Car side",
"fas caret-down": "Caret down",
"fas caret-left": "Caret left",
"fas caret-right": "Caret right",
"fas caret-square-down": "Caret square down",
"fas caret-square-left": "Caret square left",
"fas caret-square-right": "Caret square right",
"fas caret-square-up": "Caret square up",
"fas caret-up": "Caret up",
"fas carrot": "Carrot",
"fas cart-arrow-down": "Cart arrow down",
"fas cart-plus": "Cart plus",
"fas cash-register": "Cash register",
"fas cat": "Cat",
"fas certificate": "Certificate",
"fas chair": "Chair",
"fas chalkboard": "Chalkboard",
"fas chalkboard-teacher": "Chalkboard teacher",
"fas charging-station": "Charging station",
"fas chart-area": "Chart area",
"fas chart-bar": "Chart bar",
"fas chart-line": "Chart line",
"fas chart-pie": "Chart pie",
"fas check": "Check",
"fas check-circle": "Check circle",
"fas check-double": "Check double",
"fas check-square": "Check square",
"fas cheese": "Cheese",
"fas chess": "Chess",
"fas chess-bishop": "Chess bishop",
"fas chess-board": "Chess board",
"fas chess-king": "Chess king",
"fas chess-knight": "Chess knight",
"fas chess-pawn": "Chess pawn",
"fas chess-queen": "Chess queen",
"fas chess-rook": "Chess rook",
"fas chevron-circle-down": "Chevron circle down",
"fas chevron-circle-left": "Chevron circle left",
"fas chevron-circle-right": "Chevron circle right",
"fas chevron-circle-up": "Chevron circle up",
"fas chevron-down": "Chevron down",
"fas chevron-left": "Chevron left",
"fas chevron-right": "Chevron right",
"fas chevron-up": "Chevron up",
"fas child": "Child",
"fas church": "Church",
"fas circle": "Circle",
"fas circle-notch": "Circle notch",
"fas city": "City",
"fas clinic-medical": "Clinic medical",
"fas clipboard": "Clipboard",
"fas clipboard-check": "Clipboard check",
"fas clipboard-list": "Clipboard list",
"fas clock": "Clock",
"fas clone": "Clone",
"fas closed-captioning": "Closed captioning",
"fas cloud": "Cloud",
"fas cloud-download-alt": "Cloud download alt",
"fas cloud-meatball": "Cloud meatball",
"fas cloud-moon": "Cloud moon",
"fas cloud-moon-rain": "Cloud moon rain",
"fas cloud-rain": "Cloud rain",
"fas cloud-showers-heavy": "Cloud showers heavy",
"fas cloud-sun": "Cloud sun",
"fas cloud-sun-rain": "Cloud sun rain",
"fas cloud-upload-alt": "Cloud upload alt",
"fas cocktail": "Cocktail",
"fas code": "Code",
"fas code-branch": "Code branch",
"fas coffee": "Coffee",
"fas cog": "Cog",
"fas cogs": "Cogs",
"fas coins": "Coins",
"fas columns": "Columns",
"fas comment": "Comment",
"fas comment-alt": "Comment alt",
"fas comment-dollar": "Comment dollar",
"fas comment-dots": "Comment dots",
"fas comment-medical": "Comment medical",
"fas comment-slash": "Comment slash",
"fas comments": "Comments",
"fas comments-dollar": "Comments dollar",
"fas compact-disc": "Compact disc",
"fas compass": "Compass",
"fas compress": "Compress",
"fas compress-arrows-alt": "Compress arrows alt",
"fas concierge-bell": "Concierge bell",
"fas cookie": "Cookie",
"fas cookie-bite": "Cookie bite",
"fas copy": "Copy",
"fas copyright": "Copyright",
"fas couch": "Couch",
"fas credit-card": "Credit card",
"fas crop": "Crop",
"fas crop-alt": "Crop alt",
"fas cross": "Cross",
"fas crosshairs": "Crosshairs",
"fas crow": "Crow",
"fas crown": "Crown",
"fas crutch": "Crutch",
"fas cube": "Cube",
"fas cubes": "Cubes",
"fas cut": "Cut",
"fas database": "Database",
"fas deaf": "Deaf",
"fas democrat": "Democrat",
"fas desktop": "Desktop",
"fas dharmachakra": "Dharmachakra",
"fas diagnoses": "Diagnoses",
"fas dice": "Dice",
"fas dice-d20": "Dice d20",
"fas dice-d6": "Dice d6",
"fas dice-five": "Dice five",
"fas dice-four": "Dice four",
"fas dice-one": "Dice one",
"fas dice-six": "Dice six",
"fas dice-three": "Dice three",
"fas dice-two": "Dice two",
"fas digital-tachograph": "Digital tachograph",
"fas directions": "Directions",
"fas divide": "Divide",
"fas dizzy": "Dizzy",
"fas dna": "Dna",
"fas dog": "Dog",
"fas dollar-sign": "Dollar sign",
"fas dolly": "Dolly",
"fas dolly-flatbed": "Dolly flatbed",
"fas donate": "Donate",
"fas door-closed": "Door closed",
"fas door-open": "Door open",
"fas dot-circle": "Dot circle",
"fas dove": "Dove",
"fas download": "Download",
"fas drafting-compass": "Drafting compass",
"fas dragon": "Dragon",
"fas draw-polygon": "Draw polygon",
"fas drum": "Drum",
"fas drum-steelpan": "Drum steelpan",
"fas drumstick-bite": "Drumstick bite",
"fas dumbbell": "Dumbbell",
"fas dumpster": "Dumpster",
"fas dumpster-fire": "Dumpster fire",
"fas dungeon": "Dungeon",
"fas edit": "Edit",
"fas egg": "Egg",
"fas eject": "Eject",
"fas ellipsis-h": "Ellipsis h",
"fas ellipsis-v": "Ellipsis v",
"fas envelope": "Envelope",
"fas envelope-open": "Envelope open",
"fas envelope-open-text": "Envelope open text",
"fas envelope-square": "Envelope square",
"fas equals": "Equals",
"fas eraser": "Eraser",
"fas ethernet": "Ethernet",
"fas euro-sign": "Euro sign",
"fas exchange-alt": "Exchange alt",
"fas exclamation": "Exclamation",
"fas exclamation-circle": "Exclamation circle",
"fas exclamation-triangle": "Exclamation triangle",
"fas expand": "Expand",
"fas expand-arrows-alt": "Expand arrows alt",
"fas external-link-alt": "External link alt",
"fas external-link-square-alt": "External link square alt",
"fas eye": "Eye",
"fas eye-dropper": "Eye dropper",
"fas eye-slash": "Eye slash",
"fas fan": "Fan",
"fas fast-backward": "Fast backward",
"fas fast-forward": "Fast forward",
"fas fax": "Fax",
"fas feather": "Feather",
"fas feather-alt": "Feather alt",
"fas female": "Female",
"fas fighter-jet": "Fighter jet",
"fas file": "File",
"fas file-alt": "File alt",
"fas file-archive": "File archive",
"fas file-audio": "File audio",
"fas file-code": "File code",
"fas file-contract": "File contract",
"fas file-csv": "File csv",
"fas file-download": "File download",
"fas file-excel": "File excel",
"fas file-export": "File export",
"fas file-image": "File image",
"fas file-import": "File import",
"fas file-invoice": "File invoice",
"fas file-invoice-dollar": "File invoice dollar",
"fas file-medical": "File medical",
"fas file-medical-alt": "File medical alt",
"fas file-pdf": "File pdf",
"fas file-powerpoint": "File powerpoint",
"fas file-prescription": "File prescription",
"fas file-signature": "File signature",
"fas file-upload": "File upload",
"fas file-video": "File video",
"fas file-word": "File word",
"fas fill": "Fill",
"fas fill-drip": "Fill drip",
"fas film": "Film",
"fas filter": "Filter",
"fas fingerprint": "Fingerprint",
"fas fire": "Fire",
"fas fire-alt": "Fire alt",
"fas fire-extinguisher": "Fire extinguisher",
"fas first-aid": "First aid",
"fas fish": "Fish",
"fas fist-raised": "Fist raised",
"fas flag": "Flag",
"fas flag-checkered": "Flag checkered",
"fas flag-usa": "Flag usa",
"fas flask": "Flask",
"fas flushed": "Flushed",
"fas folder": "Folder",
"fas folder-minus": "Folder minus",
"fas folder-open": "Folder open",
"fas folder-plus": "Folder plus",
"fas font": "Font",
"fas football-ball": "Football ball",
"fas forward": "Forward",
"fas frog": "Frog",
"fas frown": "Frown",
"fas frown-open": "Frown open",
"fas funnel-dollar": "Funnel dollar",
"fas futbol": "Futbol",
"fas gamepad": "Gamepad",
"fas gas-pump": "Gas pump",
"fas gavel": "Gavel",
"fas gem": "Gem",
"fas genderless": "Genderless",
"fas ghost": "Ghost",
"fas gift": "Gift",
"fas gifts": "Gifts",
"fas glass-cheers": "Glass cheers",
"fas glass-martini": "Glass martini",
"fas glass-martini-alt": "Glass martini alt",
"fas glass-whiskey": "Glass whiskey",
"fas glasses": "Glasses",
"fas globe": "Globe",
"fas globe-africa": "Globe africa",
"fas globe-americas": "Globe americas",
"fas globe-asia": "Globe asia",
"fas globe-europe": "Globe europe",
"fas golf-ball": "Golf ball",
"fas gopuram": "Gopuram",
"fas graduation-cap": "Graduation cap",
"fas greater-than": "Greater than",
"fas greater-than-equal": "Greater than equal",
"fas grimace": "Grimace",
"fas grin": "Grin",
"fas grin-alt": "Grin alt",
"fas grin-beam": "Grin beam",
"fas grin-beam-sweat": "Grin beam sweat",
"fas grin-hearts": "Grin hearts",
"fas grin-squint": "Grin squint",
"fas grin-squint-tears": "Grin squint tears",
"fas grin-stars": "Grin stars",
"fas grin-tears": "Grin tears",
"fas grin-tongue": "Grin tongue",
"fas grin-tongue-squint": "Grin tongue squint",
"fas grin-tongue-wink": "Grin tongue wink",
"fas grin-wink": "Grin wink",
"fas grip-horizontal": "Grip horizontal",
"fas grip-lines": "Grip lines",
"fas grip-lines-vertical": "Grip lines vertical",
"fas grip-vertical": "Grip vertical",
"fas guitar": "Guitar",
"fas h-square": "H square",
"fas hamburger": "Hamburger",
"fas hammer": "Hammer",
"fas hamsa": "Hamsa",
"fas hand-holding": "Hand holding",
"fas hand-holding-heart": "Hand holding heart",
"fas hand-holding-usd": "Hand holding usd",
"fas hand-lizard": "Hand lizard",
"fas hand-middle-finger": "Hand middle finger",
"fas hand-paper": "Hand paper",
"fas hand-peace": "Hand peace",
"fas hand-point-down": "Hand point down",
"fas hand-point-left": "Hand point left",
"fas hand-point-right": "Hand point right",
"fas hand-point-up": "Hand point up",
"fas hand-pointer": "Hand pointer",
"fas hand-rock": "Hand rock",
"fas hand-scissors": "Hand scissors",
"fas hand-spock": "Hand spock",
"fas hands": "Hands",
"fas hands-helping": "Hands helping",
"fas handshake": "Handshake",
"fas hanukiah": "Hanukiah",
"fas hard-hat": "Hard hat",
"fas hashtag": "Hashtag",
"fas hat-cowboy": "Hat cowboy",
"fas hat-cowboy-side": "Hat cowboy side",
"fas hat-wizard": "Hat wizard",
// "fas haykal": "Haykal",
"fas hdd": "Hdd",
"fas heading": "Heading",
"fas headphones": "Headphones",
"fas headphones-alt": "Headphones alt",
"fas headset": "Headset",
"fas heart": "Heart",
"fas heart-broken": "Heart broken",
"fas heartbeat": "Heartbeat",
"fas helicopter": "Helicopter",
"fas highlighter": "Highlighter",
"fas hiking": "Hiking",
"fas hippo": "Hippo",
"fas history": "History",
"fas hockey-puck": "Hockey puck",
"fas holly-berry": "Holly berry",
"fas home": "Home",
"fas horse": "Horse",
"fas horse-head": "Horse head",
"fas hospital": "Hospital",
"fas hospital-alt": "Hospital alt",
"fas hospital-symbol": "Hospital symbol",
"fas hot-tub": "Hot tub",
"fas hotdog": "Hotdog",
"fas hotel": "Hotel",
"fas hourglass": "Hourglass",
"fas hourglass-end": "Hourglass end",
"fas hourglass-half": "Hourglass half",
"fas hourglass-start": "Hourglass start",
"fas house-damage": "House damage",
"fas hryvnia": "Hryvnia",
"fas i-cursor": "I cursor",
"fas ice-cream": "Ice cream",
"fas icicles": "Icicles",
"fas icons": "Icons",
"fas id-badge": "Id badge",
"fas id-card": "Id card",
"fas id-card-alt": "Id card alt",
"fas igloo": "Igloo",
"fas image": "Image",
"fas images": "Images",
"fas inbox": "Inbox",
"fas indent": "Indent",
"fas industry": "Industry",
"fas infinity": "Infinity",
"fas info": "Info",
"fas info-circle": "Info circle",
"fas italic": "Italic",
"fas jedi": "Jedi",
"fas joint": "Joint",
"fas journal-whills": "Journal whills",
"fas kaaba": "Kaaba",
"fas key": "Key",
"fas keyboard": "Keyboard",
"fas khanda": "Khanda",
"fas kiss": "Kiss",
"fas kiss-beam": "Kiss beam",
"fas kiss-wink-heart": "Kiss wink heart",
"fas kiwi-bird": "Kiwi bird",
"fas landmark": "Landmark",
"fas language": "Language",
"fas laptop": "Laptop",
"fas laptop-code": "Laptop code",
"fas laptop-medical": "Laptop medical",
"fas laugh": "Laugh",
"fas laugh-beam": "Laugh beam",
"fas laugh-squint": "Laugh squint",
"fas laugh-wink": "Laugh wink",
"fas layer-group": "Layer group",
"fas leaf": "Leaf",
"fas lemon": "Lemon",
"fas less-than": "Less than",
"fas less-than-equal": "Less than equal",
"fas level-down-alt": "Level down alt",
"fas level-up-alt": "Level up alt",
"fas life-ring": "Life ring",
"fas lightbulb": "Lightbulb",
"fas link": "Link",
"fas lira-sign": "Lira sign",
"fas list": "List",
"fas list-alt": "List alt",
"fas list-ol": "List ol",
"fas list-ul": "List ul",
"fas location-arrow": "Location arrow",
"fas lock": "Lock",
"fas lock-open": "Lock open",
"fas long-arrow-alt-down": "Long arrow alt down",
"fas long-arrow-alt-left": "Long arrow alt left",
"fas long-arrow-alt-right": "Long arrow alt right",
"fas long-arrow-alt-up": "Long arrow alt up",
"fas low-vision": "Low vision",
"fas luggage-cart": "Luggage cart",
"fas magic": "Magic",
"fas magnet": "Magnet",
"fas mail-bulk": "Mail bulk",
"fas male": "Male",
"fas map": "Map",
"fas map-marked": "Map marked",
"fas map-marked-alt": "Map marked alt",
"fas map-marker": "Map marker",
"fas map-marker-alt": "Map marker alt",
"fas map-pin": "Map pin",
"fas map-signs": "Map signs",
"fas marker": "Marker",
"fas mars": "Mars",
"fas mars-double": "Mars double",
"fas mars-stroke": "Mars stroke",
"fas mars-stroke-h": "Mars stroke h",
"fas mars-stroke-v": "Mars stroke v",
"fas mask": "Mask",
"fas medal": "Medal",
"fas medkit": "Medkit",
"fas meh": "Meh",
"fas meh-blank": "Meh blank",
"fas meh-rolling-eyes": "Meh rolling eyes",
"fas memory": "Memory",
"fas menorah": "Menorah",
"fas mercury": "Mercury",
"fas meteor": "Meteor",
"fas microchip": "Microchip",
"fas microphone": "Microphone",
"fas microphone-alt": "Microphone alt",
"fas microphone-alt-slash": "Microphone alt slash",
"fas microphone-slash": "Microphone slash",
"fas microscope": "Microscope",
"fas minus": "Minus",
"fas minus-circle": "Minus circle",
"fas minus-square": "Minus square",
"fas mitten": "Mitten",
"fas mobile": "Mobile",
"fas mobile-alt": "Mobile alt",
"fas money-bill": "Money bill",
"fas money-bill-alt": "Money bill alt",
"fas money-bill-wave": "Money bill wave",
"fas money-bill-wave-alt": "Money bill wave alt",
"fas money-check": "Money check",
"fas money-check-alt": "Money check alt",
"fas monument": "Monument",
"fas moon": "Moon",
"fas mortar-pestle": "Mortar pestle",
"fas mosque": "Mosque",
"fas motorcycle": "Motorcycle",
"fas mountain": "Mountain",
"fas mouse": "Mouse",
"fas mouse-pointer": "Mouse pointer",
"fas mug-hot": "Mug hot",
"fas music": "Music",
"fas network-wired": "Network wired",
"fas neuter": "Neuter",
"fas newspaper": "Newspaper",
"fas not-equal": "Not equal",
"fas notes-medical": "Notes medical",
"fas object-group": "Object group",
"fas object-ungroup": "Object ungroup",
"fas oil-can": "Oil can",
"fas om": "Om",
"fas otter": "Otter",
"fas outdent": "Outdent",
"fas pager": "Pager",
"fas paint-brush": "Paint brush",
"fas paint-roller": "Paint roller",
"fas palette": "Palette",
"fas pallet": "Pallet",
"fas paper-plane": "Paper plane",
"fas paperclip": "Paperclip",
"fas parachute-box": "Parachute box",
"fas paragraph": "Paragraph",
"fas parking": "Parking",
"fas passport": "Passport",
"fas pastafarianism": "Pastafarianism",
"fas paste": "Paste",
"fas pause": "Pause",
"fas pause-circle": "Pause circle",
"fas paw": "Paw",
"fas peace": "Peace",
"fas pen": "Pen",
"fas pen-alt": "Pen alt",
"fas pen-fancy": "Pen fancy",
"fas pen-nib": "Pen nib",
"fas pen-square": "Pen square",
"fas pencil-alt": "Pencil alt",
"fas pencil-ruler": "Pencil ruler",
"fas people-carry": "People carry",
"fas pepper-hot": "Pepper hot",
"fas percent": "Percent",
"fas percentage": "Percentage",
"fas person-booth": "Person booth",
"fas phone": "Phone",
"fas phone-alt": "Phone alt",
"fas phone-slash": "Phone slash",
"fas phone-square": "Phone square",
"fas phone-square-alt": "Phone square alt",
"fas phone-volume": "Phone volume",
"fas photo-video": "Photo video",
"fas piggy-bank": "Piggy bank",
"fas pills": "Pills",
"fas pizza-slice": "Pizza slice",
"fas place-of-worship": "Place of worship",
"fas plane": "Plane",
"fas plane-arrival": "Plane arrival",
"fas plane-departure": "Plane departure",
"fas play": "Play",
"fas play-circle": "Play circle",
"fas plug": "Plug",
"fas plus": "Plus",
"fas plus-circle": "Plus circle",
"fas plus-square": "Plus square",
"fas podcast": "Podcast",
"fas poll": "Poll",
"fas poll-h": "Poll h",
"fas poo": "Poo",
"fas poo-storm": "Poo storm",
"fas poop": "Poop",
"fas portrait": "Portrait",
"fas pound-sign": "Pound sign",
"fas power-off": "Power off",
"fas pray": "Pray",
"fas praying-hands": "Praying hands",
"fas prescription": "Prescription",
"fas prescription-bottle": "Prescription bottle",
"fas prescription-bottle-alt": "Prescription bottle alt",
"fas print": "Print",
"fas procedures": "Procedures",
"fas project-diagram": "Project diagram",
"fas puzzle-piece": "Puzzle piece",
"fas qrcode": "Qrcode",
"fas question": "Question",
"fas question-circle": "Question circle",
"fas quidditch": "Quidditch",
"fas quote-left": "Quote left",
"fas quote-right": "Quote right",
"fas quran": "Quran",
"fas radiation": "Radiation",
"fas radiation-alt": "Radiation alt",
"fas rainbow": "Rainbow",
"fas random": "Random",
"fas receipt": "Receipt",
"fas record-vinyl": "Record vinyl",
"fas recycle": "Recycle",
"fas redo": "Redo",
"fas redo-alt": "Redo alt",
"fas registered": "Registered",
"fas remove-format": "Remove format",
"fas reply": "Reply",
"fas reply-all": "Reply all",
"fas republican": "Republican",
"fas restroom": "Restroom",
"fas retweet": "Retweet",
"fas ribbon": "Ribbon",
"fas ring": "Ring",
"fas road": "Road",
"fas robot": "Robot",
"fas rocket": "Rocket",
"fas route": "Route",
"fas rss": "Rss",
"fas rss-square": "Rss square",
"fas ruble-sign": "Ruble sign",
"fas ruler": "Ruler",
"fas ruler-combined": "Ruler combined",
"fas ruler-horizontal": "Ruler horizontal",
"fas ruler-vertical": "Ruler vertical",
"fas running": "Running",
"fas rupee-sign": "Rupee sign",
"fas sad-cry": "Sad cry",
"fas sad-tear": "Sad tear",
"fas satellite": "Satellite",
"fas satellite-dish": "Satellite dish",
"fas save": "Save",
"fas school": "School",
"fas screwdriver": "Screwdriver",
"fas scroll": "Scroll",
"fas sd-card": "Sd card",
"fas search": "Search",
"fas search-dollar": "Search dollar",
"fas search-location": "Search location",
"fas search-minus": "Search minus",
"fas search-plus": "Search plus",
"fas seedling": "Seedling",
"fas server": "Server",
"fas shapes": "Shapes",
"fas share": "Share",
"fas share-alt": "Share alt",
"fas share-alt-square": "Share alt square",
"fas share-square": "Share square",
"fas shekel-sign": "Shekel sign",
"fas shield-alt": "Shield alt",
"fas ship": "Ship",
"fas shipping-fast": "Shipping fast",
"fas shoe-prints": "Shoe prints",
"fas shopping-bag": "Shopping bag",
"fas shopping-basket": "Shopping basket",
"fas shopping-cart": "Shopping cart",
"fas shower": "Shower",
"fas shuttle-van": "Shuttle van",
"fas sign": "Sign",
"fas sign-in-alt": "Sign in alt",
"fas sign-language": "Sign language",
"fas sign-out-alt": "Sign out alt",
"fas signal": "Signal",
"fas signature": "Signature",
"fas sim-card": "Sim card",
"fas sitemap": "Sitemap",
"fas skating": "Skating",
"fas skiing": "Skiing",
"fas skiing-nordic": "Skiing nordic",
"fas skull": "Skull",
"fas skull-crossbones": "Skull crossbones",
"fas slash": "Slash",
"fas sleigh": "Sleigh",
"fas sliders-h": "Sliders h",
"fas smile": "Smile",
"fas smile-beam": "Smile beam",
"fas smile-wink": "Smile wink",
"fas smog": "Smog",
"fas smoking": "Smoking",
"fas smoking-ban": "Smoking ban",
"fas sms": "Sms",
"fas snowboarding": "Snowboarding",
"fas snowflake": "Snowflake",
"fas snowman": "Snowman",
"fas snowplow": "Snowplow",
"fas socks": "Socks",
"fas solar-panel": "Solar panel",
"fas sort": "Sort",
"fas sort-alpha-down": "Sort alpha down",
"fas sort-alpha-down-alt": "Sort alpha down alt",
"fas sort-alpha-up": "Sort alpha up",
"fas sort-alpha-up-alt": "Sort alpha up alt",
"fas sort-amount-down": "Sort amount down",
"fas sort-amount-down-alt": "Sort amount down alt",
"fas sort-amount-up": "Sort amount up",
"fas sort-amount-up-alt": "Sort amount up alt",
"fas sort-down": "Sort down",
"fas sort-numeric-down": "Sort numeric down",
"fas sort-numeric-down-alt": "Sort numeric down alt",
"fas sort-numeric-up": "Sort numeric up",
"fas sort-numeric-up-alt": "Sort numeric up alt",
"fas sort-up": "Sort up",
"fas spa": "Spa",
"fas space-shuttle": "Space shuttle",
"fas spell-check": "Spell check",
"fas spider": "Spider",
"fas spinner": "Spinner",
"fas splotch": "Splotch",
"fas spray-can": "Spray can",
"fas square": "Square",
"fas square-full": "Square full",
"fas square-root-alt": "Square root alt",
"fas stamp": "Stamp",
"fas star": "Star",
"fas star-and-crescent": "Star and crescent",
"fas star-half": "Star half",
"fas star-half-alt": "Star half alt",
"fas star-of-david": "Star of david",
"fas star-of-life": "Star of life",
"fas step-backward": "Step backward",
"fas step-forward": "Step forward",
"fas stethoscope": "Stethoscope",
"fas sticky-note": "Sticky note",
"fas stop": "Stop",
"fas stop-circle": "Stop circle",
"fas stopwatch": "Stopwatch",
"fas store": "Store",
"fas store-alt": "Store alt",
"fas stream": "Stream",
"fas street-view": "Street view",
"fas strikethrough": "Strikethrough",
"fas stroopwafel": "Stroopwafel",
"fas subscript": "Subscript",
"fas subway": "Subway",
"fas suitcase": "Suitcase",
"fas suitcase-rolling": "Suitcase rolling",
"fas sun": "Sun",
"fas superscript": "Superscript",
"fas surprise": "Surprise",
"fas swatchbook": "Swatchbook",
"fas swimmer": "Swimmer",
"fas swimming-pool": "Swimming pool",
"fas synagogue": "Synagogue",
"fas sync": "Sync",
"fas sync-alt": "Sync alt",
"fas syringe": "Syringe",
"fas table": "Table",
"fas table-tennis": "Table tennis",
"fas tablet": "Tablet",
"fas tablet-alt": "Tablet alt",
"fas tablets": "Tablets",
"fas tachometer-alt": "Tachometer alt",
"fas tag": "Tag",
"fas tags": "Tags",
"fas tape": "Tape",
"fas tasks": "Tasks",
"fas taxi": "Taxi",
"fas teeth": "Teeth",
"fas teeth-open": "Teeth open",
"fas temperature-high": "Temperature high",
"fas temperature-low": "Temperature low",
"fas tenge": "Tenge",
"fas terminal": "Terminal",
"fas text-height": "Text height",
"fas text-width": "Text width",
"fas th": "Th",
"fas th-large": "Th large",
"fas th-list": "Th list",
"fas theater-masks": "Theater masks",
"fas thermometer": "Thermometer",
"fas thermometer-empty": "Thermometer empty",
"fas thermometer-full": "Thermometer full",
"fas thermometer-half": "Thermometer half",
"fas thermometer-quarter": "Thermometer quarter",
"fas thermometer-three-quarters": "Thermometer three quarters",
"fas thumbs-down": "Thumbs down",
"fas thumbs-up": "Thumbs up",
"fas thumbtack": "Thumbtack",
"fas ticket-alt": "Ticket alt",
"fas times": "Times",
"fas times-circle": "Times circle",
"fas tint": "Tint",
"fas tint-slash": "Tint slash",
"fas tired": "Tired",
"fas toggle-off": "Toggle off",
"fas toggle-on": "Toggle on",
"fas toilet": "Toilet",
"fas toilet-paper": "Toilet paper",
"fas toolbox": "Toolbox",
"fas tools": "Tools",
"fas tooth": "Tooth",
"fas torah": "Torah",
"fas torii-gate": "Torii gate",
"fas tractor": "Tractor",
"fas trademark": "Trademark",
"fas traffic-light": "Traffic light",
"fas train": "Train",
"fas tram": "Tram",
"fas transgender": "Transgender",
"fas transgender-alt": "Transgender alt",
"fas trash": "Trash",
"fas trash-alt": "Trash alt",
"fas trash-restore": "Trash restore",
"fas trash-restore-alt": "Trash restore alt",
"fas tree": "Tree",
"fas trophy": "Trophy",
"fas truck": "Truck",
"fas truck-loading": "Truck loading",
"fas truck-monster": "Truck monster",
"fas truck-moving": "Truck moving",
"fas truck-pickup": "Truck pickup",
"fas tshirt": "Tshirt",
"fas tty": "Tty",
"fas tv": "Tv",
"fas umbrella": "Umbrella",
"fas umbrella-beach": "Umbrella beach",
"fas underline": "Underline",
"fas undo": "Undo",
"fas undo-alt": "Undo alt",
"fas universal-access": "Universal access",
"fas university": "University",
"fas unlink": "Unlink",
"fas unlock": "Unlock",
"fas unlock-alt": "Unlock alt",
"fas upload": "Upload",
"fas user": "User",
"fas user-alt": "User alt",
"fas user-alt-slash": "User alt slash",
"fas user-astronaut": "User astronaut",
"fas user-check": "User check",
"fas user-circle": "User circle",
"fas user-clock": "User clock",
"fas user-cog": "User cog",
"fas user-edit": "User edit",
"fas user-friends": "User friends",
"fas user-graduate": "User graduate",
"fas user-injured": "User injured",
"fas user-lock": "User lock",
"fas user-md": "User md",
"fas user-minus": "User minus",
"fas user-ninja": "User ninja",
"fas user-nurse": "User nurse",
"fas user-plus": "User plus",
"fas user-secret": "User secret",
"fas user-shield": "User shield",
"fas user-slash": "User slash",
"fas user-tag": "User tag",
"fas user-tie": "User tie",
"fas user-times": "User times",
"fas users": "Users",
"fas users-cog": "Users cog",
"fas utensil-spoon": "Utensil spoon",
"fas utensils": "Utensils",
"fas vector-square": "Vector square",
"fas venus": "Venus",
"fas venus-double": "Venus double",
"fas venus-mars": "Venus mars",
"fas vial": "Vial",
"fas vials": "Vials",
"fas video": "Video",
"fas video-slash": "Video slash",
"fas vihara": "Vihara",
"fas voicemail": "Voicemail",
"fas volleyball-ball": "Volleyball ball",
"fas volume-down": "Volume down",
"fas volume-mute": "Volume mute",
"fas volume-off": "Volume off",
"fas volume-up": "Volume up",
"fas vote-yea": "Vote yea",
"fas vr-cardboard": "Vr cardboard",
"fas walking": "Walking",
"fas wallet": "Wallet",
"fas warehouse": "Warehouse",
"fas water": "Water",
"fas wave-square": "Wave square",
"fas weight": "Weight",
"fas weight-hanging": "Weight hanging",
"fas wheelchair": "Wheelchair",
"fas wifi": "Wifi",
"fas wind": "Wind",
"fas window-close": "Window close",
"fas window-maximize": "Window maximize",
"fas window-minimize": "Window minimize",
"fas window-restore": "Window restore",
"fas wine-bottle": "Wine bottle",
"fas wine-glass": "Wine glass",
"fas wine-glass-alt": "Wine glass alt",
"fas won-sign": "Won sign",
"fas wrench": "Wrench",
"fas x-ray": "X ray",
"fas yen-sign": "Yen sign",
"fas yin-yang": "Yin yang",
// "far ad": "Ad",
// "far address-book": "Address book",
// "far address-card": "Address card",
// "far adjust": "Adjust",
// "far air-freshener": "Air freshener",
// "far align-center": "Align center",
// "far align-justify": "Align justify",
// "far align-left": "Align left",
// "far align-right": "Align right",
// "far allergies": "Allergies",
// "far ambulance": "Ambulance",
// "far american-sign-language-interpreting": "American sign language interpreting",
// "far anchor": "Anchor",
// "far angle-double-down": "Angle double down",
// "far angle-double-left": "Angle double left",
// "far angle-double-right": "Angle double right",
// "far angle-double-up": "Angle double up",
// "far angle-down": "Angle down",
// "far angle-left": "Angle left",
// "far angle-right": "Angle right",
// "far angle-up": "Angle up",
// "far angry": "Angry",
// "far ankh": "Ankh",
// "far apple-alt": "Apple alt",
// "far archive": "Archive",
// "far archway": "Archway",
// "far arrow-alt-circle-down": "Arrow alt circle down",
// "far arrow-alt-circle-left": "Arrow alt circle left",
// "far arrow-alt-circle-right": "Arrow alt circle right",
// "far arrow-alt-circle-up": "Arrow alt circle up",
// "far arrow-circle-down": "Arrow circle down",
// "far arrow-circle-left": "Arrow circle left",
// "far arrow-circle-right": "Arrow circle right",
// "far arrow-circle-up": "Arrow circle up",
// "far arrow-down": "Arrow down",
// "far arrow-left": "Arrow left",
// "far arrow-right": "Arrow right",
// "far arrow-up": "Arrow up",
// "far arrows-alt": "Arrows alt",
// "far arrows-alt-h": "Arrows alt h",
// "far arrows-alt-v": "Arrows alt v",
// "far assistive-listening-systems": "Assistive listening systems",
// "far asterisk": "Asterisk",
// "far at": "At",
// "far atlas": "Atlas",
// "far atom": "Atom",
// "far audio-description": "Audio description",
// "far award": "Award",
// "far baby": "Baby",
// "far baby-carriage": "Baby carriage",
// "far backspace": "Backspace",
// "far backward": "Backward",
// "far bacon": "Bacon",
// "far balance-scale": "Balance scale",
// "far balance-scale-left": "Balance scale left",
// "far balance-scale-right": "Balance scale right",
// "far ban": "Ban",
// "far band-aid": "Band aid",
// "far barcode": "Barcode",
// "far bars": "Bars",
// "far baseball-ball": "Baseball ball",
// "far basketball-ball": "Basketball ball",
// "far bath": "Bath",
// "far battery-empty": "Battery empty",
// "far battery-full": "Battery full",
// "far battery-half": "Battery half",
// "far battery-quarter": "Battery quarter",
// "far battery-three-quarters": "Battery three quarters",
// "far bed": "Bed",
// "far beer": "Beer",
// "far bell": "Bell",
// "far bell-slash": "Bell slash",
// "far bezier-curve": "Bezier curve",
// "far bible": "Bible",
// "far bicycle": "Bicycle",
// "far biking": "Biking",
// "far binoculars": "Binoculars",
// "far biohazard": "Biohazard",
// "far birthday-cake": "Birthday cake",
// "far blender": "Blender",
// "far blender-phone": "Blender phone",
// "far blind": "Blind",
// "far blog": "Blog",
// "far bold": "Bold",
// "far bolt": "Bolt",
// "far bomb": "Bomb",
// "far bone": "Bone",
// "far bong": "Bong",
// "far book": "Book",
// "far book-dead": "Book dead",
// "far book-medical": "Book medical",
// "far book-open": "Book open",
// "far book-reader": "Book reader",
// "far bookmark": "Bookmark",
// "far border-all": "Border all",
// "far border-none": "Border none",
// "far border-style": "Border style",
// "far bowling-ball": "Bowling ball",
// "far box": "Box",
// "far box-open": "Box open",
// "far boxes": "Boxes",
// "far braille": "Braille",
// "far brain": "Brain",
// "far bread-slice": "Bread slice",
// "far briefcase": "Briefcase",
// "far briefcase-medical": "Briefcase medical",
// "far broadcast-tower": "Broadcast tower",
// "far broom": "Broom",
// "far brush": "Brush",
// "far bug": "Bug",
// "far building": "Building",
// "far bullhorn": "Bullhorn",
// "far bullseye": "Bullseye",
// "far burn": "Burn",
// "far bus": "Bus",
// "far bus-alt": "Bus alt",
// "far business-time": "Business time",
// "far calculator": "Calculator",
// "far calendar": "Calendar",
// "far calendar-alt": "Calendar alt",
// "far calendar-check": "Calendar check",
// "far calendar-day": "Calendar day",
// "far calendar-minus": "Calendar minus",
// "far calendar-plus": "Calendar plus",
// "far calendar-times": "Calendar times",
// "far calendar-week": "Calendar week",
// "far camera": "Camera",
// "far camera-retro": "Camera retro",
// "far campground": "Campground",
// "far candy-cane": "Candy cane",
// "far cannabis": "Cannabis",
// "far capsules": "Capsules",
// "far car": "Car",
// "far car-alt": "Car alt",
// "far car-battery": "Car battery",
// "far car-crash": "Car crash",
// "far car-side": "Car side",
// "far caret-down": "Caret down",
// "far caret-left": "Caret left",
// "far caret-right": "Caret right",
// "far caret-square-down": "Caret square down",
// "far caret-square-left": "Caret square left",
// "far caret-square-right": "Caret square right",
// "far caret-square-up": "Caret square up",
// "far caret-up": "Caret up",
// "far carrot": "Carrot",
// "far cart-arrow-down": "Cart arrow down",
// "far cart-plus": "Cart plus",
// "far cash-register": "Cash register",
// "far cat": "Cat",
// "far certificate": "Certificate",
// "far chair": "Chair",
// "far chalkboard": "Chalkboard",
// "far chalkboard-teacher": "Chalkboard teacher",
// "far charging-station": "Charging station",
// "far chart-area": "Chart area",
// "far chart-bar": "Chart bar",
// "far chart-line": "Chart line",
// "far chart-pie": "Chart pie",
// "far check": "Check",
// "far check-circle": "Check circle",
// "far check-double": "Check double",
// "far check-square": "Check square",
// "far cheese": "Cheese",
// "far chess": "Chess",
// "far chess-bishop": "Chess bishop",
// "far chess-board": "Chess board",
// "far chess-king": "Chess king",
// "far chess-knight": "Chess knight",
// "far chess-pawn": "Chess pawn",
// "far chess-queen": "Chess queen",
// "far chess-rook": "Chess rook",
// "far chevron-circle-down": "Chevron circle down",
// "far chevron-circle-left": "Chevron circle left",
// "far chevron-circle-right": "Chevron circle right",
// "far chevron-circle-up": "Chevron circle up",
// "far chevron-down": "Chevron down",
// "far chevron-left": "Chevron left",
// "far chevron-right": "Chevron right",
// "far chevron-up": "Chevron up",
// "far child": "Child",
// "far church": "Church",
// "far circle": "Circle",
// "far circle-notch": "Circle notch",
// "far city": "City",
// "far clinic-medical": "Clinic medical",
// "far clipboard": "Clipboard",
// "far clipboard-check": "Clipboard check",
// "far clipboard-list": "Clipboard list",
// "far clock": "Clock",
// "far clone": "Clone",
// "far closed-captioning": "Closed captioning",
// "far cloud": "Cloud",
// "far cloud-download-alt": "Cloud download alt",
// "far cloud-meatball": "Cloud meatball",
// "far cloud-moon": "Cloud moon",
// "far cloud-moon-rain": "Cloud moon rain",
// "far cloud-rain": "Cloud rain",
// "far cloud-showers-heavy": "Cloud showers heavy",
// "far cloud-sun": "Cloud sun",
// "far cloud-sun-rain": "Cloud sun rain",
// "far cloud-upload-alt": "Cloud upload alt",
// "far cocktail": "Cocktail",
// "far code": "Code",
// "far code-branch": "Code branch",
// "far coffee": "Coffee",
// "far cog": "Cog",
// "far cogs": "Cogs",
// "far coins": "Coins",
// "far columns": "Columns",
// "far comment": "Comment",
// "far comment-alt": "Comment alt",
// "far comment-dollar": "Comment dollar",
// "far comment-dots": "Comment dots",
// "far comment-medical": "Comment medical",
// "far comment-slash": "Comment slash",
// "far comments": "Comments",
// "far comments-dollar": "Comments dollar",
// "far compact-disc": "Compact disc",
// "far compass": "Compass",
// "far compress": "Compress",
// "far compress-arrows-alt": "Compress arrows alt",
// "far concierge-bell": "Concierge bell",
// "far cookie": "Cookie",
// "far cookie-bite": "Cookie bite",
// "far copy": "Copy",
// "far copyright": "Copyright",
// "far couch": "Couch",
// "far credit-card": "Credit card",
// "far crop": "Crop",
// "far crop-alt": "Crop alt",
// "far cross": "Cross",
// "far crosshairs": "Crosshairs",
// "far crow": "Crow",
// "far crown": "Crown",
// "far crutch": "Crutch",
// "far cube": "Cube",
// "far cubes": "Cubes",
// "far cut": "Cut",
// "far database": "Database",
// "far deaf": "Deaf",
// "far democrat": "Democrat",
// "far desktop": "Desktop",
// "far dharmachakra": "Dharmachakra",
// "far diagnoses": "Diagnoses",
// "far dice": "Dice",
// "far dice-d20": "Dice d20",
// "far dice-d6": "Dice d6",
// "far dice-five": "Dice five",
// "far dice-four": "Dice four",
// "far dice-one": "Dice one",
// "far dice-six": "Dice six",
// "far dice-three": "Dice three",
// "far dice-two": "Dice two",
// "far digital-tachograph": "Digital tachograph",
// "far directions": "Directions",
// "far divide": "Divide",
// "far dizzy": "Dizzy",
// "far dna": "Dna",
// "far dog": "Dog",
// "far dollar-sign": "Dollar sign",
// "far dolly": "Dolly",
// "far dolly-flatbed": "Dolly flatbed",
// "far donate": "Donate",
// "far door-closed": "Door closed",
// "far door-open": "Door open",
// "far dot-circle": "Dot circle",
// "far dove": "Dove",
// "far download": "Download",
// "far drafting-compass": "Drafting compass",
// "far dragon": "Dragon",
// "far draw-polygon": "Draw polygon",
// "far drum": "Drum",
// "far drum-steelpan": "Drum steelpan",
// "far drumstick-bite": "Drumstick bite",
// "far dumbbell": "Dumbbell",
// "far dumpster": "Dumpster",
// "far dumpster-fire": "Dumpster fire",
// "far dungeon": "Dungeon",
// "far edit": "Edit",
// "far egg": "Egg",
// "far eject": "Eject",
// "far ellipsis-h": "Ellipsis h",
// "far ellipsis-v": "Ellipsis v",
// "far envelope": "Envelope",
// "far envelope-open": "Envelope open",
// "far envelope-open-text": "Envelope open text",
// "far envelope-square": "Envelope square",
// "far equals": "Equals",
// "far eraser": "Eraser",
// "far ethernet": "Ethernet",
// "far euro-sign": "Euro sign",
// "far exchange-alt": "Exchange alt",
// "far exclamation": "Exclamation",
// "far exclamation-circle": "Exclamation circle",
// "far exclamation-triangle": "Exclamation triangle",
// "far expand": "Expand",
// "far expand-arrows-alt": "Expand arrows alt",
// "far external-link-alt": "External link alt",
// "far external-link-square-alt": "External link square alt",
// "far eye": "Eye",
// "far eye-dropper": "Eye dropper",
// "far eye-slash": "Eye slash",
// "far fan": "Fan",
// "far fast-backward": "Fast backward",
// "far fast-forward": "Fast forward",
// "far fax": "Fax",
// "far feather": "Feather",
// "far feather-alt": "Feather alt",
// "far female": "Female",
// "far fighter-jet": "Fighter jet",
// "far file": "File",
// "far file-alt": "File alt",
// "far file-archive": "File archive",
// "far file-audio": "File audio",
// "far file-code": "File code",
// "far file-contract": "File contract",
// "far file-csv": "File csv",
// "far file-download": "File download",
// "far file-excel": "File excel",
// "far file-export": "File export",
// "far file-image": "File image",
// "far file-import": "File import",
// "far file-invoice": "File invoice",
// "far file-invoice-dollar": "File invoice dollar",
// "far file-medical": "File medical",
// "far file-medical-alt": "File medical alt",
// "far file-pdf": "File pdf",
// "far file-powerpoint": "File powerpoint",
// "far file-prescription": "File prescription",
// "far file-signature": "File signature",
// "far file-upload": "File upload",
// "far file-video": "File video",
// "far file-word": "File word",
// "far fill": "Fill",
// "far fill-drip": "Fill drip",
// "far film": "Film",
// "far filter": "Filter",
// "far fingerprint": "Fingerprint",
// "far fire": "Fire",
// "far fire-alt": "Fire alt",
// "far fire-extinguisher": "Fire extinguisher",
// "far first-aid": "First aid",
// "far fish": "Fish",
// "far fist-raised": "Fist raised",
// "far flag": "Flag",
// "far flag-checkered": "Flag checkered",
// "far flag-usa": "Flag usa",
// "far flask": "Flask",
// "far flushed": "Flushed",
// "far folder": "Folder",
// "far folder-minus": "Folder minus",
// "far folder-open": "Folder open",
// "far folder-plus": "Folder plus",
// "far font": "Font",
// "far football-ball": "Football ball",
// "far forward": "Forward",
// "far frog": "Frog",
// "far frown": "Frown",
// "far frown-open": "Frown open",
// "far funnel-dollar": "Funnel dollar",
// "far futbol": "Futbol",
// "far gamepad": "Gamepad",
// "far gas-pump": "Gas pump",
// "far gavel": "Gavel",
// "far gem": "Gem",
// "far genderless": "Genderless",
// "far ghost": "Ghost",
// "far gift": "Gift",
// "far gifts": "Gifts",
// "far glass-cheers": "Glass cheers",
// "far glass-martini": "Glass martini",
// "far glass-martini-alt": "Glass martini alt",
// "far glass-whiskey": "Glass whiskey",
// "far glasses": "Glasses",
// "far globe": "Globe",
// "far globe-africa": "Globe africa",
// "far globe-americas": "Globe americas",
// "far globe-asia": "Globe asia",
// "far globe-europe": "Globe europe",
// "far golf-ball": "Golf ball",
// "far gopuram": "Gopuram",
// "far graduation-cap": "Graduation cap",
// "far greater-than": "Greater than",
// "far greater-than-equal": "Greater than equal",
// "far grimace": "Grimace",
// "far grin": "Grin",
// "far grin-alt": "Grin alt",
// "far grin-beam": "Grin beam",
// "far grin": "Grin",
"fab 500px": "500px",
"fab accessible-icon": "Accessible icon",
"fab accusoft": "Accusoft",
"fab acquisitions-incorporated": "Acquisitions incorporated",
"fab adn": "Adn",
// "fab adobe": "Adobe",
"fab adversal": "Adversal",
"fab affiliatetheme": "Affiliatetheme",
"fab airbnb": "Airbnb",
"fab algolia": "Algolia",
"fab alipay": "Alipay",
"fab amazon": "Amazon",
"fab amazon-pay": "Amazon pay",
"fab amilia": "Amilia",
"fab android": "Android",
"fab angellist": "Angellist",
"fab angrycreative": "Angrycreative",
"fab angular": "Angular",
"fab app-store": "App store",
"fab app-store-ios": "App store ios",
"fab apper": "Apper",
"fab apple": "Apple",
"fab apple-pay": "Apple pay",
"fab artstation": "Artstation",
"fab asymmetrik": "Asymmetrik",
"fab atlassian": "Atlassian",
"fab audible": "Audible",
"fab autoprefixer": "Autoprefixer",
"fab avianex": "Avianex",
"fab aviato": "Aviato",
"fab aws": "Aws",
"fab bandcamp": "Bandcamp",
"fab battle-net": "Battle net",
"fab behance": "Behance",
"fab behance-square": "Behance square",
"fab bimobject": "Bimobject",
"fab bitbucket": "Bitbucket",
"fab bitcoin": "Bitcoin",
"fab bity": "Bity",
"fab black-tie": "Black tie",
"fab blackberry": "Blackberry",
"fab blogger": "Blogger",
"fab blogger-b": "Blogger b",
"fab bluetooth": "Bluetooth",
"fab bluetooth-b": "Bluetooth b",
"fab bootstrap": "Bootstrap",
"fab btc": "Btc",
"fab buffer": "Buffer",
"fab buromobelexperte": "Buromobelexperte",
"fab buy-n-large": "Buy n large",
"fab buysellads": "Buysellads",
"fab canadian-maple-leaf": "Canadian maple leaf",
"fab cc-amazon-pay": "Cc amazon pay",
"fab cc-amex": "Cc amex",
"fab cc-apple-pay": "Cc apple pay",
"fab cc-diners-club": "Cc diners club",
"fab cc-discover": "Cc discover",
"fab cc-jcb": "Cc jcb",
"fab cc-mastercard": "Cc mastercard",
"fab cc-paypal": "Cc paypal",
"fab cc-stripe": "Cc stripe",
"fab cc-visa": "Cc visa",
"fab centercode": "Centercode",
"fab centos": "Centos",
"fab chrome": "Chrome",
"fab chromecast": "Chromecast",
"fab cloudscale": "Cloudscale",
"fab cloudsmith": "Cloudsmith",
"fab cloudversify": "Cloudversify",
"fab codepen": "Codepen",
"fab codiepie": "Codiepie",
"fab confluence": "Confluence",
"fab connectdevelop": "Connectdevelop",
"fab contao": "Contao",
"fab cotton-bureau": "Cotton bureau",
"fab cpanel": "Cpanel",
"fab creative-commons": "Creative commons",
"fab creative-commons-by": "Creative commons by",
"fab creative-commons-nc": "Creative commons nc",
"fab creative-commons-nc-eu": "Creative commons nc eu",
"fab creative-commons-nc-jp": "Creative commons nc jp",
"fab creative-commons-nd": "Creative commons nd",
"fab creative-commons-pd": "Creative commons pd",
"fab creative-commons-pd-alt": "Creative commons pd alt",
"fab creative-commons-remix": "Creative commons remix",
"fab creative-commons-sa": "Creative commons sa",
"fab creative-commons-sampling": "Creative commons sampling",
"fab creative-commons-sampling-plus": "Creative commons sampling plus",
"fab creative-commons-share": "Creative commons share",
"fab creative-commons-zero": "Creative commons zero",
"fab critical-role": "Critical role",
"fab css3": "Css3",
"fab css3-alt": "Css3 alt",
"fab cuttlefish": "Cuttlefish",
"fab d-and-d": "D and d",
"fab d-and-d-beyond": "D and d beyond",
"fab dashcube": "Dashcube",
"fab delicious": "Delicious",
"fab deploydog": "Deploydog",
"fab deskpro": "Deskpro",
"fab dev": "Dev",
"fab deviantart": "Deviantart",
"fab dhl": "Dhl",
"fab diaspora": "Diaspora",
"fab digg": "Digg",
"fab digital-ocean": "Digital ocean",
"fab discord": "Discord",
"fab discourse": "Discourse",
"fab dochub": "Dochub",
"fab docker": "Docker",
"fab draft2digital": "Draft2digital",
"fab dribbble": "Dribbble",
"fab dribbble-square": "Dribbble square",
"fab dropbox": "Dropbox",
"fab drupal": "Drupal",
"fab dyalog": "Dyalog",
"fab earlybirds": "Earlybirds",
"fab ebay": "Ebay",
"fab edge": "Edge",
"fab elementor": "Elementor",
"fab ello": "Ello",
"fab ember": "Ember",
"fab empire": "Empire",
"fab envira": "Envira",
"fab erlang": "Erlang",
"fab ethereum": "Ethereum",
"fab etsy": "Etsy",
"fab evernote": "Evernote",
"fab expeditedssl": "Expeditedssl",
"fab facebook": "Facebook",
"fab facebook-f": "Facebook f",
"fab facebook-messenger": "Facebook messenger",
"fab facebook-square": "Facebook square",
"fab fantasy-flight-games": "Fantasy flight games",
"fab fedex": "Fedex",
"fab fedora": "Fedora",
"fab figma": "Figma",
"fab firefox": "Firefox",
"fab first-order": "First order",
"fab first-order-alt": "First order alt",
"fab firstdraft": "Firstdraft",
"fab flickr": "Flickr",
"fab flipboard": "Flipboard",
"fab fly": "Fly",
"fab font-awesome": "Font awesome",
"fab font-awesome-alt": "Font awesome alt",
"fab font-awesome-flag": "Font awesome flag",
"fab fonticons": "Fonticons",
"fab fonticons-fi": "Fonticons fi",
"fab fort-awesome": "Fort awesome",
"fab fort-awesome-alt": "Fort awesome alt",
"fab forumbee": "Forumbee",
"fab foursquare": "Foursquare",
"fab free-code-camp": "Free code camp",
"fab freebsd": "Freebsd",
"fab fulcrum": "Fulcrum",
"fab galactic-republic": "Galactic republic",
"fab galactic-senate": "Galactic senate",
"fab get-pocket": "Get pocket",
"fab gg": "Gg",
"fab gg-circle": "Gg circle",
"fab git": "Git",
"fab git-alt": "Git alt",
"fab git-square": "Git square",
"fab github": "Github",
"fab github-alt": "Github alt",
"fab github-square": "Github square",
"fab gitkraken": "Gitkraken",
"fab gitlab": "Gitlab",
"fab gitter": "Gitter",
"fab glide": "Glide",
"fab glide-g": "Glide g",
"fab gofore": "Gofore",
"fab goodreads": "Goodreads",
"fab goodreads-g": "Goodreads g",
"fab google": "Google",
"fab google-drive": "Google drive",
"fab google-play": "Google play",
"fab google-plus": "Google plus",
"fab google-plus-g": "Google plus g",
"fab google-plus-square": "Google plus square",
"fab google-wallet": "Google wallet",
"fab gratipay": "Gratipay",
"fab grav": "Grav",
"fab gripfire": "Gripfire",
"fab grunt": "Grunt",
"fab gulp": "Gulp",
"fab hacker-news": "Hacker news",
"fab hacker-news-square": "Hacker news square",
"fab hackerrank": "Hackerrank",
"fab hips": "Hips",
"fab hire-a-helper": "Hire a helper",
"fab hooli": "Hooli",
"fab hornbill": "Hornbill",
"fab hotjar": "Hotjar",
"fab houzz": "Houzz",
"fab html5": "Html5",
"fab hubspot": "Hubspot",
"fab imdb": "Imdb",
"fab instagram": "Instagram",
"fab intercom": "Intercom",
"fab internet-explorer": "Internet explorer",
"fab invision": "Invision",
"fab ioxhost": "Ioxhost",
"fab itch-io": "Itch io",
"fab itunes": "Itunes",
"fab itunes-note": "Itunes note",
"fab java": "Java",
"fab jedi-order": "Jedi order",
"fab jenkins": "Jenkins",
"fab jira": "Jira",
"fab joget": "Joget",
"fab joomla": "Joomla",
"fab js": "Js",
"fab js-square": "Js square",
"fab jsfiddle": "Jsfiddle",
"fab kaggle": "Kaggle",
"fab keybase": "Keybase",
"fab keycdn": "Keycdn",
"fab kickstarter": "Kickstarter",
"fab kickstarter-k": "Kickstarter k",
"fab korvue": "Korvue",
"fab laravel": "Laravel",
"fab lastfm": "Lastfm",
"fab lastfm-square": "Lastfm square",
"fab leanpub": "Leanpub",
"fab less": "Less",
"fab line": "Line",
"fab linkedin": "Linkedin",
"fab linkedin-in": "Linkedin in",
"fab linode": "Linode",
"fab linux": "Linux",
"fab lyft": "Lyft",
"fab magento": "Magento",
"fab mailchimp": "Mailchimp",
"fab mandalorian": "Mandalorian",
"fab markdown": "Markdown",
"fab mastodon": "Mastodon",
"fab maxcdn": "Maxcdn",
"fab mdb": "Mdb",
"fab medapps": "Medapps",
"fab medium": "Medium",
"fab medium-m": "Medium m",
"fab medrt": "Medrt",
"fab meetup": "Meetup",
"fab megaport": "Megaport",
"fab mendeley": "Mendeley",
"fab microsoft": "Microsoft",
"fab mix": "Mix",
"fab mixcloud": "Mixcloud",
"fab mizuni": "Mizuni",
"fab modx": "Modx",
"fab monero": "Monero",
"fab napster": "Napster",
"fab neos": "Neos",
"fab nimblr": "Nimblr",
"fab node": "Node",
"fab node-js": "Node js",
"fab npm": "Npm",
"fab ns8": "Ns8",
"fab nutritionix": "Nutritionix",
"fab odnoklassniki": "Odnoklassniki",
"fab odnoklassniki-square": "Odnoklassniki square",
"fab old-republic": "Old republic",
"fab opencart": "Opencart",
"fab openid": "Openid",
"fab opera": "Opera",
"fab optin-monster": "Optin monster",
"fab orcid": "Orcid",
"fab osi": "Osi",
"fab page4": "Page4",
"fab pagelines": "Pagelines",
"fab palfed": "Palfed",
"fab patreon": "Patreon",
"fab paypal": "Paypal",
"fab penny-arcade": "Penny arcade",
"fab periscope": "Periscope",
"fab phabricator": "Phabricator",
"fab phoenix-framework": "Phoenix framework",
"fab phoenix-squadron": "Phoenix squadron",
"fab php": "Php",
"fab pied-piper": "Pied piper",
"fab pied-piper-alt": "Pied piper alt",
"fab pied-piper-hat": "Pied piper hat",
"fab pied-piper-pp": "Pied piper pp",
"fab pinterest": "Pinterest",
"fab pinterest-p": "Pinterest p",
"fab pinterest-square": "Pinterest square",
"fab playstation": "Playstation",
"fab product-hunt": "Product hunt",
"fab pushed": "Pushed",
"fab python": "Python",
"fab qq": "Qq",
"fab quinscape": "Quinscape",
"fab quora": "Quora",
"fab r-project": "R project",
"fab raspberry-pi": "Raspberry pi",
"fab ravelry": "Ravelry",
"fab react": "React",
"fab reacteurope": "Reacteurope",
"fab readme": "Readme",
"fab rebel": "Rebel",
"fab red-river": "Red river",
"fab reddit": "Reddit",
"fab reddit-alien": "Reddit alien",
"fab reddit-square": "Reddit square",
"fab redhat": "Redhat",
"fab renren": "Renren",
"fab replyd": "Replyd",
"fab researchgate": "Researchgate",
"fab resolving": "Resolving",
"fab rev": "Rev",
"fab rocketchat": "Rocketchat",
"fab rockrms": "Rockrms",
"fab safari": "Safari",
"fab salesforce": "Salesforce",
"fab sass": "Sass",
"fab schlix": "Schlix",
"fab scribd": "Scribd",
"fab searchengin": "Searchengin",
"fab sellcast": "Sellcast",
"fab sellsy": "Sellsy",
"fab servicestack": "Servicestack",
"fab shirtsinbulk": "Shirtsinbulk",
"fab shopware": "Shopware",
"fab simplybuilt": "Simplybuilt",
"fab sistrix": "Sistrix",
"fab sith": "Sith",
"fab sketch": "Sketch",
"fab skyatlas": "Skyatlas",
"fab skype": "Skype",
"fab slack": "Slack",
"fab slack-hash": "Slack hash",
"fab slideshare": "Slideshare",
"fab snapchat": "Snapchat",
"fab snapchat-ghost": "Snapchat ghost",
"fab snapchat-square": "Snapchat square",
"fab soundcloud": "Soundcloud",
"fab sourcetree": "Sourcetree",
"fab speakap": "Speakap",
"fab speaker-deck": "Speaker deck",
"fab spotify": "Spotify",
"fab squarespace": "Squarespace",
"fab stack-exchange": "Stack exchange",
"fab stack-overflow": "Stack overflow",
"fab stackpath": "Stackpath",
"fab staylinked": "Staylinked",
"fab steam": "Steam",
"fab steam-square": "Steam square",
"fab steam-symbol": "Steam symbol",
"fab sticker-mule": "Sticker mule",
"fab strava": "Strava",
"fab stripe": "Stripe",
"fab stripe-s": "Stripe s",
"fab studiovinari": "Studiovinari",
"fab stumbleupon": "Stumbleupon",
"fab stumbleupon-circle": "Stumbleupon circle",
"fab superpowers": "Superpowers",
"fab supple": "Supple",
"fab suse": "Suse",
"fab swift": "Swift",
"fab symfony": "Symfony",
"fab teamspeak": "Teamspeak",
"fab telegram": "Telegram",
"fab telegram-plane": "Telegram plane",
"fab tencent-weibo": "Tencent weibo",
"fab the-red-yeti": "The red yeti",
"fab themeco": "Themeco",
"fab themeisle": "Themeisle",
"fab think-peaks": "Think peaks",
"fab trade-federation": "Trade federation",
"fab trello": "Trello",
"fab tripadvisor": "Tripadvisor",
"fab tumblr": "Tumblr",
"fab tumblr-square": "Tumblr square",
"fab twitch": "Twitch",
"fab twitter": "Twitter",
"fab twitter-square": "Twitter square",
"fab typo3": "Typo3",
"fab uber": "Uber",
"fab ubuntu": "Ubuntu",
"fab uikit": "Uikit",
"fab umbraco": "Umbraco",
"fab uniregistry": "Uniregistry",
"fab untappd": "Untappd",
"fab ups": "Ups",
"fab usb": "Usb",
"fab usps": "Usps",
"fab ussunnah": "Ussunnah",
"fab vaadin": "Vaadin",
"fab viacoin": "Viacoin",
"fab viadeo": "Viadeo",
"fab viadeo-square": "Viadeo square",
"fab viber": "Viber",
"fab vimeo": "Vimeo",
"fab vimeo-square": "Vimeo square",
"fab vimeo-v": "Vimeo v",
"fab vine": "Vine",
"fab vk": "Vk",
"fab vnv": "Vnv",
"fab vuejs": "Vuejs",
"fab waze": "Waze",
"fab weebly": "Weebly",
"fab weibo": "Weibo",
"fab weixin": "Weixin",
"fab whatsapp": "Whatsapp",
"fab whatsapp-square": "Whatsapp square",
"fab whmcs": "Whmcs",
"fab wikipedia-w": "Wikipedia w",
"fab windows": "Windows",
"fab wix": "Wix",
"fab wizards-of-the-coast": "Wizards of the coast",
"fab wolf-pack-battalion": "Wolf pack battalion",
"fab wordpress": "Wordpress",
"fab wordpress-simple": "Wordpress simple",
"fab wpbeginner": "Wpbeginner",
"fab wpexplorer": "Wpexplorer",
"fab wpforms": "Wpforms",
"fab wpressr": "Wpressr",
"fab xbox": "Xbox",
"fab xing": "Xing",
"fab xing-square": "Xing square",
"fab y-combinator": "Y combinator",
"fab yahoo": "Yahoo",
"fab yammer": "Yammer",
"fab yandex": "Yandex",
"fab yandex-international": "Yandex international",
"fab yarn": "Yarn",
"fab yelp": "Yelp",
"fab yoast": "Yoast",
"fab youtube": "Youtube",
"fab youtube-square": "Youtube square",
"fab zhihu": "Zhihu"}

const reverseData = () => {
  const keys = Object.keys(data);
  const flattedItems = keys.map((key) => {
    return [key, data[key]]
  })
  return flattedItems;
}

export const getTopFontAwesomeIcons = (index) => {
  const flattedData = reverseData();
  const numbers = 28 * (index ? index : 1);
  const items = flattedData.slice(0, numbers);
  return items;
}

export const searchFontAwesomeIcons = (searchTerm) => {

  const flattedData = reverseData();

  // const keys = Object.keys(data);
  const st = searchTerm.toLowerCase();
  const filters = flattedData.filter((item) => {
    return item[1].toLowerCase().includes(st);
  })

  return filters;
}