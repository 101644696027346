import React from "react";
import { Avatar, Image, Button, Divider} from 'antd';
import { UserOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { format } from 'date-fns'
import { Link } from "react-router-dom";

// Hanlde delete action, we want to remove data in jotai and local storage too


const ProjectCardHeader = (props) => {
  const {title, id, shorterUrl, artworkUrl, createdDate, handleDelete} = props;

  // const publishButtonStyle = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   color: '#007bff',
  // }

  return (
    <div className="row align-items-center">
      <div className="col-auto">        
        {artworkUrl ? <div className="avatar avatar-lg"><img src={artworkUrl} className="avatar-img rounded-circle" /></div> : 
          <div className="avatar avatar-lg"><span className="font-size-h3 symbol-label font-weight-boldest"><UserOutlined /></span></div>}                    
      </div>
      <div className="col ml-n2">
        <h3 className="mb-1">{title ? title : 'Undefined'}</h3>
        <p className="small text-muted mb-1">created on {createdDate ? format(new Date(createdDate), 'MM/dd/yyyy') : null}</p>
        {props.children}
        <br/>
        <div className="row">
          <div className="col-auto">
            <Link to={`/projects/${id}/settings`} className="btn btn-sm btn-warning">Settings</Link>
          </div>       
          <div className="col-auto">
            <Link to={`/projects/${id}`} className="btn btn-sm btn-primary">Edit</Link>
          </div>      
          <div className="col-auto">
            <div className="btn btn-sm btn-danger" onClick={() => {
                handleDelete(id)
              }}>Delete</div>          
          </div>            
        </div>
      </div>     
    </div>    
  )

  // return (
  //   <div className="card-header border-0 pt-7">
  //     <h3 className="card-title align-items-start flex-column">
  //       <span className="card-label font-weight-bolder font-size-h2 text-dark-75">{title ? title : 'Undefined'}</span>
  //       <span className="mt-3 font-weight-bold font-size-lg">
  //         created on {createdDate ? format(new Date(createdDate), 'MM/dd/yyyy') : null}
  //       </span>
  //     </h3>    
  //     <div className="card-toolbar">
  //       <ul className="nav nav-pills nav-pills-sm nav-dark">
  //         <li class="nav-item">
  //           <Link to={`/projects/${id}`} className="nav-link py-2 px-4 active font-weight-bolder font-size-sm">Edit</Link>            
  //         </li>
  //         <li class="nav-item ml-0" onClick={() => handleDelete(id)}>
  //           <div className="nav-link py-2 px-4 font-weight-bolder font-size-sm">Delete</div>
  //         </li>
  //       </ul>
  //     </div>
  //   </div>    
  // )

  // const metaStyle = {
  //   display: 'flex',
  // }

  // const controlsStyle = {
  //   marginTop: "5px"
  // }

  // const textBaseStyle = {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   maxWidth: '200px',
  //   textOverflow: 'ellipsis'
  // }

  // const publishButtonStyle = {
  //   // backgroundColor: "#07f",
  //   // border: '1px solid #BEBAC8',
  //   // borderRadius: '20px',
  //   // paddingLeft: '20px',
  //   // paddingRight: '20px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   color: '#007bff',
  //   // size: "40px"
  // }

  // return (
  //   <>
  //     <div style={metaStyle}>
  //       {artworkUrl ? <Avatar size={50} src={artworkUrl} /> : <Avatar size={50} icon={<UserOutlined />} />}
  //       <div style={{marginLeft: '20px'}}>
  //         {/* <div style={{
  //           fontSize: '18px', 
  //           fontWeight: '700',
  //           ...textBaseStyle
  //         }}>{shorterUrl}</div> */}
  //         <ShortLinkCopyControl shortUrl={shorterUrl} publishButtonStyle={publishButtonStyle}/>
  //         <div style={{fontSize: '16px', ...textBaseStyle}}>{title ? title : 'Undefined'}</div>
  //         <div>created on {createdDate ? format(new Date(createdDate), 'MM/dd/yyyy') : null}</div>        
  //         <div style={controlsStyle}>
  //           <Button type="primary" style={{marginRight: '10px'}}><Link to={`/projects/${id}`}>Edit</Link></Button>
  //           <Button danger onClick={() => {
  //             handleDelete(id)
  //           }}>Delete</Button>
  //         </div>
  //       </div>
  //     </div>   
  //     <Divider/>  
  //   </> 
  // )
}

export default ProjectCardHeader;