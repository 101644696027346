import React from 'react';
import { PodcastsIcon, blockSize } from '../../libs'
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'

const PodcastNetworkBlock = ((props) => {  
  const {value, title, name, piesCount, pos, icon_group, icon_name, icon_color, button_color} = props;
  const [, toggle] = useAtom(toggleBuilderModal);
  const flexBasis = blockSize(piesCount, pos, 3);

  const cssStyle = {
    order: 1,
    // flexBasis,
    // backgroundColor: "#e3e3e3",
    // padding: "10px",
    // marginTop: "10px",
    // marginBottom: "10px",
    height: "80px",
    width: "80px",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center', 
    alignItems: 'center',
    // backgroundColor: '#A9DEF9',
    // backgroundColor: button_color,
    // borderStyle: 'dashed',
    // borderColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    // color: "rgba(0, 0, 0, 0.5)",
    // fontSize: "18px",
    // fontWeight: '700',
    cursor: "pointer",
    // filter: 'blur(4px)'
    // margin: "10px"
  }    

  return (
    <div style={cssStyle} onClick={() => {
      toggle({type: 'PODCAST-NETWORK-SORT', title: 'PODCASTS', name: 'PODCAST-NETWORK'});
    }}>
      <PodcastsIcon name={icon_name} color={icon_color} width={"30px"} height={"30px"}/>
      <div style={{fontSize: "10px", marginTop: '4px', textAlign: 'center'}}>{title}</div>
    </div>
  )
})

export default PodcastNetworkBlock;