import messengers from './messengers'
import socialNetworks from './socialNetworks'
import podcastNetworks from './podcastNetworks'
import {getRandomId} from './randomId'

const getDefaultPies = () => {
  const msgs = messengers.map((x) => {
    return {...x, id: (-1 * getRandomId())}
  })
  const sns = socialNetworks.map((x) => {
    return {...x, id: (-1 * getRandomId())}
  })
  const pns = podcastNetworks.map((x) => {
    return {...x, id: (-1 * getRandomId())}
  })

  const prepareData = [...msgs, ...sns, ...pns];  
  prepareData.sort((a, b) => `${a.main_type}-${a.sort_order}` - `${b.main_type}-${b.sort_order}`);
  prepareData.forEach((item, idx) => {
    item['sort_order'] = idx + 1;
  });

  return prepareData;
}

export default getDefaultPies;