import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAtom } from 'jotai';
import { addPie, updatePie, sortPies } from '../../jotais'
import ListBodyItem from './ListBodyItem'

const ListBody = (props) => {

  const {items, sortableName, handleDDEnd} = props;
  
  const [, addBuilderPie] = useAtom(addPie);
  const [, updateBuilderPie] = useAtom(updatePie);
  const [, sortBuilderPies] = useAtom(sortPies);
  // console.log(items)

  const handleChange = (idx, data) => {
    const item = items[idx];

    if(item.id) {
      // updateBuilderPie({...item, [name]: value})
      updateBuilderPie({...item, ...data})
    } else {      
      // create
      const pie = {...item, id: (-1 * Date.now()), ...data}
      addBuilderPie(pie);
    }
  }

  const handleOnDragEnd = (result) => {
    // we need to make change on two different state
    // one in the component state, and one in the jotai data
    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination ? result.destination.index : -1;
    if(destinationIndex >= 0) {
      const source = items[sourceIndex];
      const destination = items[destinationIndex];      
      sortBuilderPies({sourcePieId: source.id, destinationPieId: destination.id})
    }
  }

  if(sortableName) {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={sortableName}>
          {(provided) => (
            <div className={sortableName}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                items.map((item, idx) => {                  
                  return (
                    <Draggable key={`${sortableName}-${idx}`} draggableId={`${sortableName}-${idx}`} index={idx}>
                      {(provided) => {
                        return (
                          <div 
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef} 
                          >
                            <ListBodyItem 
                              key={idx} 
                              id={item.id}
                              idx={idx}
                              sortable={true}
                              icon_group={item.icon_group}
                              icon_name={item.icon_name}
                              icon_color={item.icon_color}                              
                              name={item.name}
                              is_username={item.is_username}
                              link_value={item.link_value}
                              social_username={item.social_username}
                              placeholder={item.placeholder}
                              handleChange={handleChange}
                            />
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )    
  }
  
  return (    
    items.map((item, idx) => <ListBodyItem 
      key={idx} 
      id={item.id}
      idx={idx}
      name={item.name}
      value={item.value}
      icon_group={item.icon_group}
      icon_name={item.icon_name}
      icon_color={item.icon_color}
      placeholder={item.placeholder}
      handleChange={handleChange}  
    />)    
  )
}

export default ListBody;