import React from 'react';
import { useAtom } from 'jotai';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getSortedMainPies, builderAtom, sortPies} from '../../jotais'
import {MainBlock, SpecialEffectComponent} from './../BuilderBlock'
import SpecialEffectIndicator from './SpecialEffectIndicator'

const MainBlocks = () => {
  const [pies] = useAtom(getSortedMainPies);
  const [builder] = useAtom(builderAtom);
  const {button_text_color, button_color, text_color} = builder;
  const [, sortBuilderPies] = useAtom(sortPies);

  const handleOnDragEnd = (result) => {
    // we need to make change on two different state
    // one in the component state, and one in the jotai data
    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination ? result.destination.index : -1;
    if(destinationIndex >= 0) {
      const source = pies[sourceIndex];
      const destination = pies[destinationIndex];      
      sortBuilderPies({sourcePieId: source.id, destinationPieId: destination.id})
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="main-blocks">
        {(provided) => (
          <div className="sortable-blocks"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {
              pies.map((pie, idx) => {
                const {id, modal_title, main_type, sub_type, value, name, title, description, artwork_cdnUrl, image_url, icon_group, icon_name, link_value, price_tag, price_tag_background_color, is_pop, schedule_from_date, is_hidden, placeholder, button_text, title_font_size, description_font_size} = pie;
                return (
                  <Draggable key={`main-block-${idx}`} draggableId={`main-block-${idx}`} index={idx}>
                    {(provided) => (
                      <div {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef} 
                      >
                        <SpecialEffectComponent is_pop={is_pop}>
                          <SpecialEffectIndicator schedule_from_date={schedule_from_date} is_hidden={is_hidden}>
                            <MainBlock 
                              id={id}
                              main_type={main_type}
                              sub_type={sub_type}
                              name={name}
                              title={title}
                              modal_title={modal_title}
                              description={description}
                              image_url={artwork_cdnUrl ? artwork_cdnUrl : image_url}
                              icon_group={icon_group}
                              icon_name={icon_name}
                              link_value={link_value}
                              value={value}
                              placeholder={placeholder}
                              price_tag={price_tag}
                              price_tag_background_color={price_tag_background_color}
                              button_text={button_text}
                              // button_color={pie.background_color && pie.background_color.length > 4 ? pie.background_color : `${button_color}`} 
                              // text_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.title_text_color : button_text_color}
                              button_color={button_color}
                              button_text_color={button_text_color}
                              text_color= {text_color}
                              icon_color={pie.icon_color && pie.icon_color.length > 4 ? pie.icon_color : button_text_color}
                              is_hidden={pie.is_hidden}
                              schedule_from_date={pie.schedule_from_date}
                              countdown_from_date={pie.countdown_from_date}
                              countdown_to_date={pie.countdown_to_date}
                              title_font_size={title_font_size}
                              description_font_size={description_font_size}
                            /> 
                          </SpecialEffectIndicator>   
                        </SpecialEffectComponent> 
                      </div>
                    )}                    
                  </Draggable>       
                )
              })
            }
            {provided.placeholder}
          </div>            
        )}      
      </Droppable>
    </DragDropContext>
  )
}

export default MainBlocks;