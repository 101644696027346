import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import {setNotificationState } from '../../jotais'
import railsApi from '../../api/railsApi'

const useMailingIntegrations = () => {
  const [, setNotification] = useAtom(setNotificationState)
  const [state, setState] = useState([]);

  // GET
  const fetchData = () => {
    railsApi.get(`/v1/mailing_integrations`)
    .then((res) => {
      // setState(res.data);
      const data = res.data;
      if(data) {
        setState(data);
      }
    })
    .catch((err) => {
      console.log(err.response)
    })
  }

  // DELETE
  const deleteMailingIntegration = (service_id, integration_id) => {
    railsApi.delete(`/v1/mailing_services/${service_id}/mailing_integrations/${integration_id}`)
    .then((res) => {
      // setState(res.data);
      const _items = state.filter((x) => x.id.toString() !== integration_id.toString());
      setState(_items);
      
      const message = res.data.message;
      setNotification({
        status: true, 
        category: 'Notification',
        message: `Success`,
        autoReset: true, 
        description: message,
        type: 'success',
      })
    })
    .catch((err) => {
      console.log(err.response.message)
    })
  }

  // UPDATE

  useEffect(() => {
    fetchData();
  }, [])

  return [state, fetchData, deleteMailingIntegration];
}

export default useMailingIntegrations;