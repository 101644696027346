import React from 'react';
import { Result, Button } from 'antd';
import {useOptoutEmailCampaign} from './../hooks'

const OptoutCampaign = (props) => {
  const itunes_id = props.match.params.itunes_id;
  const [loading] = useOptoutEmailCampaign(itunes_id)

  if(loading) {
    return null;
  }

  return (
    <div>
      <Result
        status="success"
        title="Successfully to unsubscribe you from our mailing list."
        subTitle="We apologize for any inconvenience."
        extra={[
          <Button key="buy" href={process.env.REACT_APP_LINK_PAGE_PATH}>Learn more about Castpie</Button>,
        ]}
      />
    </div>
  )

}

export default OptoutCampaign;