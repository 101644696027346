import React, {useState} from 'react';
import {useTranslation} from 'react-i18next'
import { Form, FormGroup, Label, Input } from 'reactstrap';
import {ListColorPicker} from '../ColorPicker'

const CountDownTextInputs = (props) => {
  const {t} = useTranslation();

  const {id, title, description, link_value, price_tag, price_tag_background_color, handleInputChange, handleColorPicked} = props;
  const [colors, setColors] = useState([{ id: 1,solid_color: "#4cd963"}, { id: 1,solid_color: "#eb5757"}, { id: 1,solid_color: "#f2c84c"}]);

  return (
    <div>
      <FormGroup role="form">
        <Input 
          type="text" 
          name="title" 
          id="title"
          value={title}
          onChange={handleInputChange}
          placeholder={t("HEADER-TITLE.3")}
        />
      </FormGroup>
      <FormGroup role="form">
        <Input 
          type="textarea" 
          name="description" 
          id="description"
          placeholder={t("HEADER-DESCRIPTION.3")}
          value={description}
          onChange={handleInputChange}          
        />
      </FormGroup> 
      <FormGroup role="form">
        <Input 
          type="url" 
          name="link_value" 
          id="link_value"
          placeholder={t("INPUT-LINK-VALUE-PLACEHOLDER.1")}
          value={link_value}
          onChange={handleInputChange}          
        />
      </FormGroup>
      {
        link_value ? <FormGroup role="form">      
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>          
            <Input 
              id={id}
              type="text" 
              name="price_tag" 
              style={{flex: 1}}
              // id={pie.id}
              id="price_tag" 
              placeholder={t("INPUT-PRICE-TAG-VALUE-PLACEHOLDER.1")}
              value={price_tag ? price_tag : ""}
              onChange={handleInputChange}          
            />         
            {price_tag && <ListColorPicker
              style={{flex: 1}}
              colors={colors}
              selectedColor={price_tag_background_color ? price_tag_background_color : "#4cd963"}
              handleColorPicked={handleColorPicked}
            />}
          </div>          
        </FormGroup> : null
      }
            
    </div>
  )
}

export default CountDownTextInputs;