import React, {useState} from "react";
import { useAtom } from 'jotai';
import { signup} from '../jotais'
import AuthForm from './AuthForm'

const SignUp = () => {

  const [tryUser, setTryUser] = useState({email: "", password: ""});
  const [, userSignUp] = useAtom(signup);

  const onValuesChange = (values) => {
    const keys = Object.keys(values);
    const [name] = keys;
    const value = values[name];
    setTryUser({...tryUser, [name]: value});
  }

  const onFinish = () => {
    userSignUp(tryUser)
  }

  return (
    <>
      <h1 className="display-4 text-center mb-3">Sign up</h1>
      <p className="text-muted text-center mb-5">Connect audiences to all of your content with just one link</p>    
      <AuthForm
        formTitle="Register"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        submitButtonLabel="Sign up"
        helpoutMessage={{
          line1: "Already have an account?",
          line2: "Sign in",
          link: "/signin"
        }}
      />
    </>
  )  
}

export default SignUp;