import React, { useEffect} from "react";
import { Route, Redirect } from 'react-router-dom'
import { useAtom } from 'jotai';
import { authAtom, tryLocalSignin } from '../jotais'
import './../assets/styles/theme.min.css';
import backgroundImage from './../assets/img/covers/sign-in-podcast-cover.jpeg'
// import './../assets/styles/auth.css';
// It checks if the user is authenticated, if they are, redirect them to dashboard
// it renders the "component" prop. if not, then ask them to login/sign up

const AuthRoute = ({ component: Component, ...rest }) => {
  // const {state, tryLocalSignin} = useContext(AuthContext)
  const [authUser] = useAtom(authAtom);
  const [, trylocalSignIn] = useAtom(tryLocalSignin);
  // 
  // if login, then redirect to main page
  useEffect(() => {
    trylocalSignIn()
  }, [])

  if(authUser && authUser.uid && authUser.signedIn) {
    return (
      <Route {...rest} render={(props) => (
        <Redirect to='/dashboard' />
      )}/>
    )
  } else if (authUser.signedIn === false) {
    return (
      <Route {...rest} render={(props) => (
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
              <Component {...props} />
            </div> 
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div className="bg-cover vh-100 mt-n1 mr-n3" style={{
                backgroundImage: `url(${backgroundImage})`
              }}/>
            </div>
          </div>             
        </div>  
      )}/>    
    )
  } else {
    return null;
  }
}

export default AuthRoute;