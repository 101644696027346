import mixpanel from 'mixpanel-browser';
if(process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}


const Mixpanel = {
  identify: (id) => {
    if(process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.identify(id);
    }    
  },
  alias: (id) => {
    if(process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.alias(id);
    }    
  },
  track: (name, props) => {
    if(process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.track(name, props);
    }    
  },
  people: {
    set: (props) => {
      if(process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.people.set(props);
      }      
    }
  }
}

export {
  Mixpanel
}