import React from 'react';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { toggleBuilderModal, builderAtom} from '../../jotais'
import {  getTextColor } from '../../libs'

const ColorSettings = (props) => {
  const {t} = useTranslation();
  const {type, title, name} = props;
  const [, toggle] = useAtom(toggleBuilderModal);
  const [builder] = useAtom(builderAtom);
  const {text_color} = builder;

  const cssStyle = {
    cursor: 'pointer',
    // borderStyle: 'dashed',
    textAlign: 'center',
    width: '80px',
    fontSize: "12px",
    borderRadius: "50px",
    padding: ".2em 1.2em",
    textDecoration: 'none',
    color: getTextColor(text_color),
    background: text_color
  }

  return (
    <div style={cssStyle} onClick={() => {
      toggle({type, title, name});
    }}>
      {t("SETTINGS.1")}
    </div>
  )
}

export default ColorSettings;