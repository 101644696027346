import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import { Form, Input, Button, Select } from 'antd';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { builderAtom, 
  updateOpeningModalPieId, 
  getPieById, 
  cudPie,
  authAtom
} from '../../jotais'
import {useMailingIntegrations, useFetchMailingLists} from '../../hooks'
import { DemoBlockPlaceholder } from '../Misc'
import { MailingListBlock } from "../BuilderBlock";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 6,
  },
  // wrapperCol: {
  //   span: 14,
  // },
};

const MailingListModalBody = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const [user] = useAtom(authAtom);
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);  

  const [integrations] = useMailingIntegrations();
  const defaultIntegrationId = pie.integration_id ? pie.integration_id : (integrations[0] ? integrations[0]['id'] : null);
  const [mailingLists] = useFetchMailingLists(defaultIntegrationId);

  const {button_color, button_text_color} = builder;

  useEffect(() => {
    if(integrations.length > 0 && mailingLists.length > 0) {
      if(pie.integration_id) {
        const {title, success_message, mailing_list_id, integration_id, campaign_tag, button_text} = pie;
        
        // look up integration lable
        const options = integrations.filter((x) => x.id.toString() === integration_id.toString())        
        const option = options[0];
        const integration = `${option.service_name} (${option.account_email})`

        const mailingList = mailingLists.filter((x) => x.id.toString() === mailing_list_id.toString())
        
        
        let mailintListOption = mailingLists[0];
        if(mailingList.length === 0) {
          // this means we changed the integration, but didn't change the mailing list id
          const mailing_list_id = mailintListOption['id'];
          const newData = { ...pie, mailing_list_id};
          createUpdateDeletePie(newData); 
        } else {
          mailintListOption = mailingList[0]
        }

        form.setFieldsValue({
          integration_id: integration,
          mailing_list_id: mailintListOption['name'],
          title,
          button_text,
          success_message,
          campaign_tag
        })
      } else {
        // init local state
        const option = integrations[0];
        const integration = `${option.service_name} (${option.account_email})`
        const mailingList = mailingLists[0];
        const modal_title = builder.modal_title;

        const pieID = pie.id;

        if(builder.modal_data_id !== pieID) {      
          updateModalId(pieID)
        }

        const newData = { ...pie, 
          integration_id: option['id'],
          mailing_list_id: mailingList['id'],
          title: "Join our newsletter!",
          button_text: 'Click to Subscribe',
          success_message: 'Thanks for subscribing!',
          campaign_tag: "",
          modal_title        
        };

        createUpdateDeletePie(newData);

        form.setFieldsValue({
          integration_id: integration,
          mailing_list_id: mailingList['name'],
          title: "Join our newsletter!",
          button_text: 'Click to Subscribe',
          success_message: 'Thanks for subscribing!',
          campaign_tag: ""
        })        
      }
    }
  }, [pie, integrations, mailingLists])  

  const onIntegrationChange = (value) => {
    // console.log(value);
  }

  const onValuesChange = (values) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    const newData = { ...pie, ...values, modal_title};
    createUpdateDeletePie(newData);   
  }

  if(user.signedIn === false) {
    return <div>Please <Link to="/">sign in</Link>sign in to use this feature.</div>
  }

  if(integrations.length === 0) {
    return <div>Please go to <Link to="/integrations">integrations</Link> page to add a mailing provider to continue.</div>
  }

  const onFinish = () => {
  };

  return (
    <div>
      <Form 
        {...layout} 
        layout="horizontal"
        form={form} 
        name="mailing-form" 
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        >        
        <Form.Item
          name="integration_id"
          label="Mailing Integration"
          rules={[{required: true}]}
        >
          <Select
            placeholder="Mailing provider, e.g. Mailchimp, Sendgrid"
            onChange={onIntegrationChange}
          >
            {integrations.map((option) => <Option value={option.id} key={option.id}>{option.service_name} ({option.account_email})</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name="mailing_list_id"
          label="Mailing list"
          rules={[{required: true}]}
        >
          <Select
            placeholder="Mailing list on Mailchimp"
            onChange={onIntegrationChange}
          >
            {mailingLists.map((option) => <Option value={option.id} key={option.id}>{option.name} ({option.id})</Option>)}
          </Select>
        </Form.Item>        
        <Form.Item 
          name="title"
          label="Title"          
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="button_text"
          label="Button Text"
          rules={[{required: true}]}          
        >
          <Input />
        </Form.Item>  
        <Form.Item 
          name="success_message"
          label="Success Message"
          rules={[{required: true}]}          
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="campaign_tag"
          label="Tag Value"
        >
          <Input />
        </Form.Item>        
      </Form>
      <DemoBlockPlaceholder is_pop={pie.is_pop}>
        <MailingListBlock
          button_color={button_color} 
          text_color={button_text_color}            
          title={pie.title}
          success_message={pie.success_message}
          integration_id={pie.integration_id}
          campaign_tag={pie.campaign_tag}
          button_text={pie.button_text}
          demoView={true}
        />
      </DemoBlockPlaceholder>
    </div>
  )
}

export default MailingListModalBody;