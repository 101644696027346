import React, {useState, useEffect,  useRef} from 'react';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import QRCode from "qrcode.react";
import { Button } from 'antd';

const QrCodeDisplay = (props) => {
  const qrRef = useRef();
  const { artworkUrl, shortUrl, qr_color, qr_size, qr_level} = props;
  const [qrImgLocal, setQrImgLocal] = useState(null);
  const [savingPNG, setSavingPNG] = useState(false);
  // const artworkUrl = "https://ucarecdn.com/e90e510e-531b-4fd2-9ab2-7a5e3bc7bf35/"

  const getImageRatio = (qrlevel) => {
    const ratios = {"L": 0.1, "M": 0.2, "Q": 0.2, "H": 0.2};
    return ratios[qrlevel];
  }

  useEffect(() => {

    // we do this so that we can escape the Anonymous issue
    if(artworkUrl) {
      fetch(artworkUrl, {
        method: "GET",
        headers: {}
      })
      .then((res) => {
        res.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          setQrImgLocal(url)
        })
        .catch((err) => {
          console.log(err)
        })
      })
    }
  }, [artworkUrl])

  const handleSaveAsPng = () => {
    // const img = new Image();
    // img.crossOrigin = 'Anonymous';
    setSavingPNG(true);
    const canvas = qrRef.current.querySelector("canvas");
    const image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `my-podcast-qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setSavingPNG(false)
  }  

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div ref={qrRef}>        
        <QRCode 
          includeMargin={true}
          value={shortUrl} 
          size={qr_size ? qr_size : 200}
          fgColor={qr_color ? qr_color : "#000"}
          level={qr_level ? qr_level : "L"}
          imageSettings={qrImgLocal ? {
            src: qrImgLocal,
            excavate: false,
            width: (qr_size ? qr_size : 200) * (getImageRatio(qr_level ? qr_level : "L")),
            height: (qr_size ? qr_size : 200) * (getImageRatio(qr_level ? qr_level : "L"))
          } : null}
        />  
      </div>
      <div style={{marginTop: '30px'}}>
        <Button onClick={handleSaveAsPng} style={{marginRight: '20px'}}>
          {savingPNG ? <LoadingOutlined/> : <DownloadOutlined/>} PNG
        </Button>
        {/* <Button onClick={handleSaveAsSvg} ><DownloadOutlined/> SVG</Button> */}
      </div>
    </div>  
  )
}

export default QrCodeDisplay;