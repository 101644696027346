import React, {useState} from 'react';
import { SketchPicker } from 'react-color';
import { CheckOutlined } from '@ant-design/icons';

const SingleColorPickerItem = ({
  handleColorPicked,
  selectedColor,
  color,
}) => {

  const cssStyle = {
    // backgroundColor: color,
    // display: "inline-block",
    // height: '40px',
    // width: '40px',
    // paddingTop: '10px',
    // margin: '4px',
    // borderRadius: '40px',
    // cursor: 'pointer',
    // swatch
    paddingTop: '10px',
    margin: '4px',
    padding: '10px',
    backgroundColor: color,
    borderRadius: '40px',
    height: '40px',
    width: '40px',  
    boxShadow: '0 0 0 4px rgba(0,0,0,.1)',
    display: 'inline-block', 
    cursor: 'pointer',
  }

  const coverStyle =  {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  }

  const handleClose = () => {
    setDisplayColorPicker(false);
  }

  const handleChange = (color) => {
    if(color) {
      handleColorPicked(color.hex);
    }    
  };

  return (
    <div>
      <div style={cssStyle} onClick={handleClick}>
        <div style={{
          width: '20px',
          height: '20px',
          borderRadius: '20px',
          background: color,
          display: 'flex',
          justifyContent: 'center'
        }}></div>
      </div>
      {
        displayColorPicker ? <div style={{position: 'absolute', zIndex: "2"}}>
          <div style={coverStyle} onClick={handleClose}/>
          <SketchPicker color={ color ? color : "#000"} onChangeComplete={ handleChange } />
        </div> : null
      }
    </div>
  )
}

export default SingleColorPickerItem;