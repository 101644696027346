import React, { useState } from 'react';
import { Modal } from 'antd';
import {AuthForm} from '../../auth'

const AuthModal = ({isModalVisible, setIsModalVisible, handClickSignUp}) => {
  // const [isModalVisible, setIsModalVisible] = useState(false);

  const [tryUser, setTryUser] = useState({email: "", password: ""});

  const onValuesChange = (values) => {
    const keys = Object.keys(values);
    const [name] = keys;
    const value = values[name];
    setTryUser({...tryUser, [name]: value});
  }

  const onFinish = () => {
    // userSignUp(tryUser)
    handClickSignUp(tryUser)
  }  
  
  const handleOk = () => {
    setIsModalVisible(false);
    handClickSignUp(tryUser)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  return (
    <>
      <Modal title="Create your account" 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={[]}
      >
        <AuthForm
          formTitle="Register"
          textColor="#000"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          submitButtonLabel="Sign up"
        />        
      </Modal>
    </>
  )
}

export default AuthModal;