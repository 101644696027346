import React, {useState} from 'react';
import {useTranslation} from 'react-i18next'
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { useAtom } from 'jotai';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import {FontSizePicker} from '../Misc'

const SimpleTextInput = () => {
  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie)
  // const [pie, setPie] = useState({title: "", id: "", description: ""});

  const handleInputChange = (event) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    const name = event.target.name;
    const value = event.target.value;
    createUpdateDeletePie({...pie, [name]: value, modal_title})
  }

  return (
    <div>
      <FormGroup role="form">
        <Label for="title">{t("HEADER-TITLE.3")}</Label>
        <Input 
          type="text" 
          name="title" 
          id={pie.id}
          value={pie.title}
          onChange={handleInputChange}
          placeholder={t("HEADER-TITLE.3")}
        />
      </FormGroup>
      <FormGroup role="form">
        <Label for="description">{t("HEADER-DESCRIPTION.3")}</Label>
        <Input 
          type="textarea" 
          name="description" 
          id={pie.id}
          placeholder={t("HEADER-DESCRIPTION.3")}
          value={pie.description}
          onChange={handleInputChange}          
        />
      </FormGroup> 
      <Row>
        <Col md={6}>
          <FontSizePicker label="Title Font Size" name="title_font_size" value={pie.title_font_size} handleInputChange={handleInputChange}/>
        </Col>
        <Col md={6}>
          <FontSizePicker label="Description Font Size" name="description_font_size" value={pie.description_font_size} handleInputChange={handleInputChange}/>
        </Col>        
      </Row>      
    </div>
  )
}

export default SimpleTextInput;