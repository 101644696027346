export const blockOptions = [
  {
    id: 1,
    icon_type: 'fas',
    icon_name: 'align-justify',
    icon_color: 'black',
    icon_size: "2x",
    name: 'SUBTITLE-AND-TEXT',
    category_name: 'MAIN-BLOCK',
    hr: true,
    type: 'TEXT-INPUT'
  },
  {
    id: 2,
    icon_type: 'fas',
    icon_name: 'crown',
    icon_color: 'black',
    icon_size: "2x",
    name: 'PLATFORM-BADGE', 
    category_name: 'MAIN-BLOCK',
    type: 'PLATFORM-BADGE',   
    hr: true,    
  },
  {
    id: 3,
    icon_type: 'fas',
    icon_name: 'link',
    icon_color: 'black',
    icon_size: "2x",
    name: 'BUTTON-OR-LINK', 
    category_name: 'MAIN-BLOCK',
    type: 'LINK-INPUT',   
    hr: true,
  },
  {
    id: 4,
    icon_type: 'fas',
    icon_name: 'id-card',
    icon_color: 'black',
    icon_size: "2x",
    name: 'CARD',
    category_name: 'MAIN-BLOCK',
    type: 'CARD-INPUT',
    hr: true,    
  },
  {
    id: 5,
    icon_type: 'fas',
    icon_name: 'rss-square',
    icon_color: 'black',
    icon_size: "2x",
    category_name: 'MAIN-BLOCK',
    name: 'PODCAST-FEED',
    type: 'PODCAST-FEED',
    hr: true,    
  },
  {
    id: 6,
    icon_type: 'fas',
    icon_name: 'clock',
    icon_color: 'black',
    icon_size: "2x",
    category_name: 'MAIN-BLOCK',
    type: 'COUNT-DOWN',
    name: 'COUNT-DOWN',
    hr: true   
  },
  {
    id: 7,
    icon_type: 'fas',
    icon_name: 'envelope',
    icon_color: 'black',
    icon_size: "2x",
    category_name: 'MAIN-BLOCK',
    type: 'MAILING-LIST',
    name: 'MAILING-LIST',
    hr: true   
  },
  {
    id: 8,
    icon_type: 'fas',
    icon_name: 'piggy-bank',
    icon_color: 'black',
    icon_size: "2x",
    name: 'TIP-US-BADGE', 
    category_name: 'MAIN-BLOCK',
    type: 'TIP-US-BADGE',   
    hr: false,    
  },    
]
