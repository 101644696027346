export const reorderArray = (arr, sourceIdx, destinationIdx) => {
  if(arr.length > 0) {
    const items = [...arr];
    const [sourceItem] = items.splice(sourceIdx, 1);
    items.splice(destinationIdx, 0, sourceItem);
    return items;
  } else {
    return arr;
  }
}
