const blockSize = (total, pos, cols) => {
  const options = ["100%", "48%", "32%"];
  // const basic = options[cols-1];
  const r = total % cols;
  // const k = (total - r);
  // totla = 5 
  // cols = 3
  // r = 2
  // k = (5-2) = 3
  // if(pos < k) {
  //   return options[cols-1];
  // } else {
  //   return options[r-1];
  // }  

  if( pos < r) {
    return options[r-1];
  } else {
    return options[cols-1];
  }
}

export default blockSize