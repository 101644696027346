import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Collapse, CardBody, Card, CardHeader  } from 'reactstrap';
import { Popover, Button } from 'antd';
import {useTranslation} from 'react-i18next'
import { blockOptions} from '../../libs'
import CreateButton from './CreateButton'
import BlockOptionItem from './BlockOptionItem'

const AddBlockButton = (props) => {  
  const {width, type, title, name, buttonLabel} = props;
  const [isOpen, setIsOpen] = useState(false);
  
  // const [, toggle] = useAtom(toggleBuilderModal);
  const {t, i18n} = useTranslation();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Popover 
        placement="bottomLeft" 
        trigger="click"
        visible={isOpen}
        onVisibleChange={toggle}
        content={
          blockOptions.map((option, key) => {
            const {id, type, icon_type, icon_name, icon_color, icon_size, name, hr, category_name} = option;
            return (
              <BlockOptionItem
                toggleMenu={toggle}
                key={key}
                type={type}
                icon_type={icon_type}
                icon_name={icon_name}
                icon_color={icon_color}
                icon_size={icon_size}
                category_name={category_name}
                name={name}
                hr={hr}
              />                  
            )            
          })          
        }>
        <CreateButton width={width}>
          <div>+ {buttonLabel ? t(buttonLabel) : ''}</div>      
        </CreateButton>
      </Popover>
    </>
  )   
}

export default AddBlockButton;