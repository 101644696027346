import React from 'react';
import { getContrastColor } from '../../libs'
import { CheckOutlined } from '@ant-design/icons';

// import { CustomPicker } from 'react-color';

const ListColorPickerItem = ({
  // linear_gradient, 
  // degree, 
  // gradient_color_from, 
  // gradient_color_to,
  // solid_color,
  handleColorPicked,
  selectedColor,
  color,
}) => {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow
  // const backgroundColor = `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`;  
  const cssStyle = {
    backgroundColor: color,
    display: "inline-block",
    height: '40px',
    width: '40px',
    paddingTop: '10px',
    margin: '4px',
    borderRadius: '40px',
    cursor: 'pointer',
    // borderStyle: selectedColor && color && selectedColor === color ? `solid` : "none",
    // borderColor: selectedColor && color && selectedColor === color ? `${getContrastColor(color)}80` : "none"
    // boxShadow: selectedColor && color && selectedColor === color ? `12px 12px 2px 1px ${color}` : "none",
    // background: linear_gradient ? `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`: `${solid_color}`
  }

  const handleClicked = () => {
    // console.log(color)
    handleColorPicked(color)
  }

  return (
    <div style={cssStyle} onClick={handleClicked}>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <CheckOutlined style={{color: selectedColor && color && selectedColor === color ? getContrastColor(color) : color, fontSize:"22px"}}/>
      </div>
    </div>
  )
}

export default ListColorPickerItem;