import React from "react";
import {useFetchPodcastHealth} from '../../../hooks'
import {PodcastHealthBarChart} from '../stats'

const PodcastHealthChart = ({project_id, is_check_podcast_health}) => {
  const [stats, networks] = useFetchPodcastHealth({project_id, is_check_podcast_health});

  if(is_check_podcast_health) {
    return (
      <>
        <hr/>
        <div style={{margin: '10px'}}>
          <div className="font-weight-bolder font-size-h4">Uptime monitoring</div>
          {
            networks.map((network, idx) => {
              return <PodcastHealthBarChart key={idx} networkName={network} stats={stats[network]}/>
            })
          }
        </div>
      </>
    )
  }

  return null;
}

export default PodcastHealthChart;