import React from 'react';
import { useAtom } from 'jotai';
import AuthorCardImage from './AuthorCardImage'
import { toggleBuilderModal} from '../../jotais'

const AuthorBlockRender = (props) => {
  const {id, text_color, button_color, main_type, modal_title, 
    sub_type, name, title, price_tag, description, image_url, 
    icon_group, icon_name, link_value, demoView, price_tag_background_color} = props;
  
  const [, toggle] = useAtom(toggleBuilderModal);
  const style = {cursor: 'pointer'};

  const handleClick = () => {
    toggle({type: "AUTHORS", title: "AUTHORS", name: "AUTHORS"})
  }
  
  return (
    <div className="author-card"
      style={style}
      onClick={handleClick}
    >
      <AuthorCardImage 
        image_url={image_url} 
        title={title}
        button_color={button_color}
        text_color={text_color}
      />
      <div style={{
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        maxWidth: '60px',
        maxHeight: '50px'
      }}>{title}</div>
    </div>
  )
}

export default AuthorBlockRender