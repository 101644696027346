import React from "react";
import { Switch, Route } from 'react-router-dom'
import {SignIn, SignUp, PasscodeSignIn} from '../auth'
import AuthRoute from './AuthRoute'
import AuthedRoute from './AuthedRoute'
import BuilderRoute from './BuilderRoute'
import DemoRoute from './DemoRoute'
import { Main, ResetPassword, Integrations, ProjectSettings} from '../admin'
import { Builder, NoMatch, ImportFromItunes, OptoutCampaign } from '../screens'
import {Notification} from '../layouts'
import {MailchimpCallback} from '../integration-callbacks'

const MainRoutes = () => {
  return (
    <>
      <Notification/>
      <Switch>
        <AuthRoute exact path="/" component={SignIn}/>        
        <AuthRoute exact path="/signin" component={SignIn}/>
        <AuthRoute exact path="/signup" component={SignUp}/>
        <AuthRoute exact path="/beta-user-invite" component={PasscodeSignIn}/>        
        <AuthedRoute exact path="/dashboard" component={Main}/>
        <AuthedRoute exact path="/integrations" component={Integrations}/>
        <AuthedRoute exact path="/change-password" component={ResetPassword}/>
        <AuthedRoute exact path="/mailchimp_integration_oauth_callback" component={MailchimpCallback}/>
        <AuthedRoute exact path="/project-setup" component={ImportFromItunes}/>
        <AuthedRoute exact path="/projects/:id/settings" component={ProjectSettings}/>
        <BuilderRoute exact path="/projects" component={Builder}/>        
        <BuilderRoute exact path="/projects/:id" component={Builder}/>                
        <DemoRoute exact path="/quickstart" component={ImportFromItunes}/>
        <DemoRoute exact path="/quickstart-project" component={Builder}/>
        <Route exact path="/opt-out-campaign/:itunes_id" component={OptoutCampaign} />
        <Route component={NoMatch}/>
      </Switch>
    </>
  )
}

export default MainRoutes;