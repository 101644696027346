import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const options = [...Array(40).keys()].map(i => i + 6);

const SelectionOptions = ({options, inputLabel}) => {  
  return (
    options.map((item) => 
      <option 
      key={inputLabel+item.toString()} 
      value={item} >{item}</option>
    )
  )
}

const FontSizePicker = (props) => {
  const {label, name, value, handleInputChange, defaultFontSize} = props;
  return (
    <FormGroup>
      <Label for={`${name}FontSelect`}>{label}</Label>
      <Input 
        type="select" 
        name={`${name}`} 
        id={`${name}FontSelect`}
        value={value ? value : (defaultFontSize ? defaultFontSize : 12)}
        onChange={handleInputChange}
      >
        <SelectionOptions
          options={options}
          inputLabel={`${name}FontSelect`}
        />
      </Input>
    </FormGroup>
  )
}

export default FontSizePicker