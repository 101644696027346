import React from 'react';
import { InputGroup } from 'reactstrap';

const AuthorListItemLayout = (props) => {
  return (
    <div style={{marginBottom: '10px'}}>
      <InputGroup size="lg">
        {props.children}
      </InputGroup>
    </div>
  )
}

export default AuthorListItemLayout;