const socialNetworks = [
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 1,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'facebook',
    icon_color: 'black',
    placeholder: 'Facebook username or page id',
    value: "",
    name: 'FACEBOOK',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 2,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'instagram',
    icon_color: 'black',
    placeholder: 'Instagram username',
    value: "",
    name: 'INSTAGRAM',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 3,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'twitter',
    icon_color: 'black',
    placeholder: 'Twitter username',
    value: "",
    name: 'TWITTER',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 4,  
    title: '',
    title_text_color: '',
    link_value: '',
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'youtube',
    icon_color: 'black',
    placeholder: 'Youtube channel url',
    value: "",
    name: 'YOUTUBE',
    is_username: false,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 5,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'tiktok',
    icon_color: 'black',
    placeholder: 'Tiktok username',
    value: "",
    name: 'TIKTOK',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 6,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'pinterest',
    icon_color: 'black',
    placeholder: 'Pinterest username',
    value: "",
    name: 'PINTEREST',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 7,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'patreon',
    icon_color: 'black',
    placeholder: 'Patreon username',
    value: "",
    name: 'PATREON',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 8,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'tumblr',
    icon_color: 'black',
    placeholder: 'Tumblr username',
    value: "",
    name: 'TUMBLR',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 9,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'vk',
    icon_color: 'black',
    placeholder: 'VK username or page id',
    value: "",
    name: 'VK',
    is_username: true,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 10,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'linkedin',
    icon_color: 'black',
    placeholder: 'Linkedin profile url',
    value: "",
    name: 'LINKEDIN',
    is_username: false,
  },
  {
    id: '',
    main_type: 'SOCIAL-NETWORKS',
    sortable: true,
    sort_order: 11,  
    title: '',
    title_text_color: '',
    link_value: '',    
    background_color: '',
    image_url: '',
    description: '',
    icon_group: 'fab', 
    icon_name: 'snapchat',
    icon_color: 'black',
    placeholder: 'Snapchat username',
    value: "",
    name: 'SNAPCHAT',
    is_username: true,
  }       
]

export default socialNetworks;