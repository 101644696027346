import { useState, useEffect } from 'react';
import railsApi from '../api/railsApi'


const useFetchPies = (projectId) => {

  const [state, setState] = useState([]);
  const [projectTitle, setProjectTitle] = useState('Undefined');
  // const [error, setError] = useState(null);

  useEffect(() => {
    railsApi.get(`/v1/projects/${projectId}/pies`)
    .then((res) => {
      // setState(res.data);
      const data = res.data.filter((x) => x.link_value)
      setState(data);
      // HEADER-TITLE
      const [title] = res.data.filter((x) => x.main_type === 'HEADER-TITLE')
      if(title) {
        setProjectTitle(title.title)
      }
      // 
    })
    .catch((err) => {      
      console.log(err.response.statusText)
    })
  }, [])

  return [state, projectTitle];
}

export default useFetchPies;