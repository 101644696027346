// https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
// https://dev.to/selbekk/how-to-overlay-your-background-images-59le
import { atom } from "jotai";
import {  Mixpanel, getOpacityBackgroundColor, getTextColor, 
  getButtonColor, getContrastColor, setLocalStorage, removeLocalBuilderData 
  ,getDefaultPodcastPies
} from './../libs'
import { piesAtom } from './piesAtom'
import { notificationAtom } from './notificationAtom'
import { authAtom } from './authAtom'
import railsApi from '../api/railsApi'

const temp = {
  // background_color: "#E4C1F9",
  // background_color: "linear-gradient(180deg, #308695, #F2A490)",  
  id: "",
  text_color: '#ffffff', // reverse background color
  button_text_color: "#000000",
  // button_color: "#F2A490", 
  button_color: "#D5DBE4",    
  linear_gradient: false,
  degree: 0,
  gradient_color_from: null,
  gradient_color_to: null,
  solid_color: "#424242",
  initial: true,  
  localStorageId: null,
  modals_open: false,
  modal_data_id: null,
  modal_data_name: '',
  modal_type: '',
  modal_title: '',
  updated_at: null,
  pies_updated_at: null,
  slug: null,
  // background_type: 'LINEAR-GRADIENT', // string e.g. COLOR, IMAGE
  background_type: 'SOLID', // string e.g. COLOR, IMAGE
  artwork_uuid: null, // string
  artwork_cdnUrl: null, // string
  artwork_originalUrl: null, // string
  artwork_mime_type: null, // string
  background_image_uuid: null, // string
  background_image_cdnUrl: null, // string
  background_image_originalUrl: null, // string
  background_image_mime_type: null, // string
  selected_theme_id: null,
  qr_size: 200,
  qr_level: "L",
  qr_color: null,
  isEditing: false // not a rails value
}

export const builderTemp = temp;

const refreshBuilderLocalStorageUpDate = (localStorageId) => {
  const dateVal = (new Date()).valueOf()
  setLocalStorage(`${localStorageId}_updated_at`, dateVal);
}

export const updateBuilderEditing = (get, set, isEditing, _builderAtom) => {
  const currentState = get(builderAtom);
  set(_builderAtom, () => {
    return {...currentState, isEditing}
  })
} 

export const builderAtom = atom(temp);
export const builderAtomWithPersistence = atom(null, (get, set, id) => {
  // https://excalidraw.com/#json=6575188913160192,fw8RLniMY_1f4ru9aMqViQ
  
  // 1. load data from api
  // 2. load data from localstorage
  // 3. compare and get the latest
  const localStorageId = id ? `${id}_builder` : 'draft_builder';
  const persistenceState = localStorage.getItem(localStorageId);
  const builderLocalData = persistenceState ? {...JSON.parse(persistenceState), modals_open: false} : {...temp, modals_open: false, initial: false, localStorageId};
  const localUpdatedAt = localStorage.getItem(`${localStorageId}_updated_at`);

  if(id) {
    railsApi.get(`/v1/projects/${id}`)
    .then((res) => {
      const data = res.data;
      const serverUpdatedDate = new Date(data.updated_at).valueOf();      
      if(builderLocalData.id && localUpdatedAt && Number(localUpdatedAt) >= serverUpdatedDate) {
        // use the local copy
        set(builderAtom, () => {
          return {...builderLocalData, localStorageId};
        })
      } else {
        // use the server copy
        const newData = {...builderLocalData, ...data, localStorageId};
        setLocalStorage(localStorageId, newData); // add to local storage
        setLocalStorage(`${localStorageId}_updated_at`, serverUpdatedDate);
        set(builderAtom, () => {
          return newData;
        })        
      }
    })
    .catch((err) => {
      // TODO: ERROR HANDLE
      // Redirect back to home page and send modal saying having issue
      // it can be unauthorized (401)
      const data = get(notificationAtom);
      set(notificationAtom, () => {
        return {...data, 
          status: true, 
          message: `Project not found`, 
          category: 'Notification',
          description: `Please try again or send us an email to report issue.`,
          redirect: true,          
          redirectTo: '/dashboard',
          type: 'error',
          autoReset: true,
        }
      })
      // console.log(err.response.status);
    })
  } else {
    // Draft
    // builderLocalData
    setLocalStorage(localStorageId, builderLocalData)
    set(builderAtom, () => {
      return builderLocalData;
    })    
  }

  // set(builderAtom, () => {
  //   // const currentState = get(builderAtom);
  //   if(persistenceState) {
  //     const builder = JSON.parse(persistenceState);
  //     return ({...temp, ...builder, modals_open: false});
  //   } else {
  //     return ({...temp, initial: false, localStorageId: input});
  //   }
  // })
})

export const toggleBuilderModal = atom(null, (_get, set, input) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    const modals_open = !currentState.modals_open;
    const {type, title, name, pieId} = input;
    const modal_type = modals_open ? type : "";
    const modal_title = modals_open ? title : "";
    const modal_data_name = modals_open ? name : "";
    const modal_data_id = modals_open ? pieId : null;
    return ({...currentState, modals_open, modal_type, modal_title, modal_data_name, modal_data_id});
  })
})

export const getBackgroundStyle = atom((get) => {
  const currentState = get(builderAtom);
  const { 
    background_image_cdnUrl, solid_color, degree, gradient_color_from, 
    gradient_color_to, linear_gradient, background_type
  } = currentState;

  const baseStyle = {minHeight: "100vh", display: "flex", flexDirection: "column"}
  switch(background_type) {
    case 'IMAGE':
      return {
        ...baseStyle, 
        // backgroundImage: `url(${background_image_cdnUrl})`,
        background: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${background_image_cdnUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    case 'SOLID':
      return {
        ...baseStyle,
        background: solid_color
      }
    case 'LINEAR-GRADIENT':
      return {
        ...baseStyle,
        // background: `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`
        background: `linear-gradient(180deg, ${gradient_color_from}, ${gradient_color_to})`
      }
    default:
      return {
        ...baseStyle,
        background: solid_color
      }
  }
  // if(background_type === 'IMAGE' && background_image_cdnUrl) {
  //   return {
  //     ...baseStyle, 
  //     backgroundImage: `url(${background_image_cdnUrl})`,
  //     backgroundPosition: 'center',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundSize: 'cover'
  //   }
  // } else {
  //   if(linear_gradient) {
  //     return {
  //       ...baseStyle,
  //       background: `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`
  //     }
  //   } else {
  //     return {
  //       ...baseStyle,
  //       background: solid_color
  //     }
  //   }
  // }
})

export const getBackgroundColor = atom((get) => {
  // TODO learn radius-gradient
  // https://developer.mozilla.org/en-US/docs/Web/CSS/radial-gradient()
  
  const currentState = get(builderAtom)  
  const {degree, linear_gradient, gradient_color_from, gradient_color_to, solid_color} = currentState;
  if(linear_gradient) {
    return `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`;
  } else {
    return solid_color;
  }
})

export const getPodcastsBackgroundColor = atom((get) => {
  const currentState = get(builderAtom);
  const {gradient_color_from, solid_color} = currentState;
  const color = gradient_color_from ? gradient_color_from : solid_color;
  return getOpacityBackgroundColor(color);
})

export const getUpperSectionContrastColor = atom((get) => {
  const currentState = get(builderAtom);
  const {gradient_color_from, solid_color} = currentState;
  const color = gradient_color_from ? gradient_color_from : solid_color;
  return getTextColor(color);
})

export const getLowerSectionContrastColor = atom((get) => {
  const currentState = get(builderAtom);
  const {gradient_color_to, solid_color} = currentState;
  const color = gradient_color_to ? gradient_color_to : solid_color;
  return getTextColor(color);
})


export const updateBuilder = atom(null, (get, set, data) => {
  const currentState = get(builderAtom);
  const localStorageId = currentState.localStorageId;
  
  set(builderAtom, () => {
    const newData = {...currentState, 
      ...data,
      isEditing: true
    };    
    setLocalStorage(localStorageId, newData)
    refreshBuilderLocalStorageUpDate(localStorageId)  
    return newData;  
  })
})

export const updateSolidColor = atom(null, (_get, set, color) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    // const textColor = getContrastColor(color);    
    const textColor = getTextColor(color);
    // const {button_color, button_text_color} = getButtonColor(color);
    const localStorageId = currentState.localStorageId;

    const newData = {...currentState, 
      solid_color: color, 
      text_color: textColor,
      // button_color,
      // button_text_color,
      linear_gradient: false,
      gradient_color_from: null,
      gradient_color_to: null,
      isEditing: true,
      background_type: 'SOLID',
      degree: 0,
      selected_theme_id: null
      // updated_at: new Date()
    };

    setLocalStorage(localStorageId, newData)
    refreshBuilderLocalStorageUpDate(localStorageId)
    return newData;
  })
})

export const updateGradientColorFrom = atom(null, (_get, set, color) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    const localStorageId = currentState.localStorageId;
    const textColor = getTextColor(color);

    const newData = {
      ...currentState, 
      // solid_color: null, 
      solid_color: color, 
      gradient_color_from: color, 
      linear_gradient: true, 
      text_color: textColor,
      isEditing: true,
      degree: 0,
      selected_theme_id: null,
      background_type: 'LINEAR-GRADIENT'
    }

    setLocalStorage(localStorageId, newData)
    refreshBuilderLocalStorageUpDate(localStorageId)
    return (newData);  
  })
})

export const updateGradientColorTo = atom(null, (_get, set, color) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    const localStorageId = currentState.localStorageId;

    const newData = {
      ...currentState, 
      solid_color: null, 
      gradient_color_to: color, 
      linear_gradient: true,
      isEditing: true,
      degree: 0,
      selected_theme_id: null
      // background_type: 'COLOR',
    }
    setLocalStorage(localStorageId, newData)
    refreshBuilderLocalStorageUpDate(localStorageId)
    return (newData);  
  })
})

export const updateButtonColor = atom(null, (_get, set, color) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    const textColor = getTextColor(color)
    const localStorageId = currentState.localStorageId;
    const newData = {
      ...currentState, 
      button_color: color, 
      button_text_color: textColor,
      isEditing: true,
    }
    setLocalStorage(localStorageId, newData)
    refreshBuilderLocalStorageUpDate(localStorageId)
    return (newData);  
  })
})

export const updateOpeningModalPieId = atom(null, (_get, set, id) => {
  set(builderAtom, () => {
    const currentState = _get(builderAtom);
    const newData = {...currentState, modal_data_id: id};
    return newData;
    // modal_data_id
  })
})

export const publishBuilder = atom(null, (_get, set, id) => {  
  const builderData = _get(builderAtom);
  const pies = _get(piesAtom);
  const localStorageId = builderData.localStorageId;
  let createdProjectId = '';
  const notiData = _get(notificationAtom);
  const userData = _get(authAtom);
  const userId = userData.id;
  const userEmail = userData.email;

  // get random slug
  railsApi.get(`/v1/projects/random_slug`)
  .then((res) => {
    const slug =  res.data.data.unique_slug;
    return {...builderData, slug, is_check_podcast_health: true};
  })
  .then((projectData) => {
    return railsApi.post('/v1/projects', projectData)
  })  
  .then((res) => {
    set(builderAtom, () => {
      return temp;
    })
    createdProjectId = res.data.id;
    return res.data.id; // project id
  })
  .then((project_id) => {
    return railsApi.post(`/v1/projects/${project_id}/pies/sync`, pies)
  })
  .then((res) => {
    set(piesAtom, () => {
      return [];
    })    
    // here we might want to redirect to somewhere?
    // either back to the HOME page or to the acutal page  
    removeLocalBuilderData({localStorageId});
    // console.log(res.data);
    return;
  })
  .then(() => {
    // createdProjectId
    // send to mixpanl
    Mixpanel.identify(userId);
    Mixpanel.track('Created a new project', {email: userEmail, project_id: createdProjectId});   

    const notiDataId = (notiData.id ? notiData.id : 0) + 1;
    set(notificationAtom, () => {
      return {...notiData, 
        id: notiDataId,
        status: true, 
        message: `Success`, 
        category: 'Notification',
        description: `Project created successfully.`,
        redirect: true,
        redirectTo: `/projects/${createdProjectId}`,
        type: 'success',
        autoReset: true,
      }
    })    
  })
  .catch((err) => {
    // TODO: handle error
    const notiDataId = (notiData.id ? notiData.id : 0) + 1;
    set(notificationAtom, () => {
      return {...notiData, 
        id: notiDataId,
        status: true, 
        category: 'Notification',
        message: `Error`, 
        description: `Failed to create a new project.  Please screenshot this and send us email to support@castpie.com.`,
        type: 'error',
        autoReset: true,
      }
    })
  })
})

export const updateServerBuilder = atom(null, (_get, set, id) => {
  const builderData = _get(builderAtom);
  const pies = _get(piesAtom);
  // const localStorageId = builderData.localStorageId;
  const builderId = builderData.id;
  const notif = _get(notificationAtom);
  const userData = _get(authAtom);
  const userId = userData.id;
  const userEmail = userData.email;

  set(notificationAtom, () => {
    return {...notif, 
      status: true, 
      category: 'Modal',
      message: `Upadting...`, 
      description: `We are updating the project data, please wait.`,
      type: 'info',
      autoReset: false
    }
  })

  railsApi.put(`/v1/projects/${builderData.id}`, builderData)
  .then((res) => {
    
    setLocalStorage(`${builderId}_builder_updated_at`, new Date(res.data.updated_at).valueOf());
    setLocalStorage(`${builderId}_builder`, res.data);
  
    set(builderAtom, () => {
      return {...temp, ...res.data, localStorageId: `${builderId}_builder`};
    });

    return res.data.id; // project id
  })
  .then((project_id) => {
    return railsApi.post(`/v1/projects/${project_id}/pies/sync`, pies)
  })
  .then((res) => {    
    const updated_builder_data = _get(builderAtom);

    const updated_pies = res.data.pies;
    // set some toast to say project has been updated
    const serverPiesUpdatedAt = updated_pies.length > 0 ? Math.max(...(updated_pies.map((x) => new Date(x.updated_at)))) : 0;
    // const serverPiesUpdatedAt = (new Date()).valueOf()

    Mixpanel.identify(userId);
    Mixpanel.track('Updated a project', {email: userEmail, project_id: builderId});   

    setLocalStorage(`${builderId}_builder_pies_updated_at`, serverPiesUpdatedAt);
    setLocalStorage(`${builderId}_builder_pies_server_copy_updated_at`, serverPiesUpdatedAt);    
    setLocalStorage(`${builderId}_builder_pies`, updated_pies);

    set(piesAtom, () => {
      return updated_pies;
    })

    set(builderAtom, () => {
      return {...updated_builder_data, pies_updated_at: serverPiesUpdatedAt}
    })

    set(notificationAtom, () => {
      return {...notif, 
        status: true, 
        category: 'Notification',
        message: `Success`,
        autoReset: true, 
        description: `Project updated successfully.`,
        type: 'success',
        autoReset: true,
      }
    })
        
    return;
  })
  .catch((err) => {
    // console.log(err.response)
    // TODO: handle error
    // set some toast to say project has issue to be updated
    set(notificationAtom, () => {
      return {...notif, 
        status: true, 
        category: 'Notification',
        message: `Error`,
        autoReset: true, 
        description: `Project updated failed.`,
        type: 'error',
        autoReset: true,
      }
    })
  })  
})

export const setupProjectWithItunesData = atom(null, (get, set, data) => {

  const {artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type , trackViewUrl, trackName, feedUrl} = data;
  const localStorageId = 'draft_builder';
  const builderLocalData = {...temp, modals_open: false, initial: false, localStorageId, artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type};
  const piesLocalData = getDefaultPodcastPies(data);

  setLocalStorage(localStorageId, builderLocalData)  
  set(builderAtom, () => {
    return builderLocalData;
  })

  setLocalStorage('draft_builder_pies', piesLocalData); 
  set(piesAtom, () => {
    return piesLocalData;
  })  
})

export default {}