import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

const baseStyle = {
  display: "inline-block",
  height: '40px',
  width: '40px',
  paddingTop: '10px',
  margin: '4px',
  borderRadius: '40px',
  cursor: 'pointer',
}


const ListThemePickerItem = ({
  id, 
  solid_color, 
  degree, 
  theme_name, 
  background_type,
  gradient_color_from,
  gradient_color_to,  
  text_color,
  button_color,
  button_text_color,
  selectedThemeId,
  handleThemePicked
}) => {

  const cssStyle = background_type === 'SOLID' ? {...baseStyle, backgroundColor: solid_color} : {...baseStyle, background: `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})`}
  // backgroundColor: background_type === 'SOLID' ? solid_color : null,
  // background: background_type === 'LINEAR-GRADIENT' ? `linear-gradient(${degree}deg, ${gradient_color_from}, ${gradient_color_to})` : null,  

  const handleClicked = () => {
    // console.log(color)
    handleThemePicked({
      selected_theme_id: id, solid_color, degree, background_type, 
      gradient_color_from, gradient_color_to, text_color, button_color,
      button_text_color
    })
  }

  return (
    <div style={cssStyle} onClick={handleClicked}>
      <div style={{ display: 'flex', justifyContent: 'center'}}>    
        <CheckOutlined style={{
          color : `${selectedThemeId && selectedThemeId === id ? text_color : "000000"}`, 
          opacity: `${selectedThemeId && selectedThemeId === id ? 1 : 0}`, 
          fontSize:"22px"}}/>        
      </div>
    </div>
  )
}

export default ListThemePickerItem;