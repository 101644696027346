// https://www.w3schools.com/howto/howto_css_menu_horizontal_scroll.asp
// https://dev.to/kartikbudhiraja/create-horizontal-scroll-list-in-react-1h62
import React from 'react';
import ListColorPickerItem from './ListColorPickerItem'
import SingleColorPickerItem from './SingleColorPickerItem';

const ListColorPicker = ({selectedColor, colors, handleColorPicked}) => {

  const style = {
    // display: 'flex',
    overflow: "auto",
    whiteSpace: "nowrap",
  }

  if(colors) {
    return (
      <div style={{display: 'flex', alignContent: 'center'}}>
        <SingleColorPickerItem
          handleColorPicked={handleColorPicked}
          color={selectedColor}
        />      
        <div style={style}>
          {
            colors.map((color, index) => <ListColorPickerItem
            key={index}
            selectedColor={selectedColor}
            color={color.solid_color}
            handleColorPicked={handleColorPicked}
          />)
          }  
        </div>
      </div>            
    ) 
  }

  return null;  
}

export default ListColorPicker;