import React from 'react';
import { Widget } from "@uploadcare/react-widget";

const FileUpload = (props) => {
  
  const publicKey = process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY;
  const {widgetApi, handleChanged} = props;

  return (
    <>
     {/* <button onClick={() => widgetApi.current.openDialog()}>
       Click me
     </button>   */}
      <Widget        
        ref={widgetApi} 
        imagesOnly={true}
        id="file"
        name="upload file"
        publicKey={publicKey}
        tabs="file camera url facebook gdrive gphotos dropbox instagram evernote flickr onedrive box"
        previewStep={true}
        onChange={(x) => {
          handleChanged(x)
        }}
        clearable
        preloader={null}
      />
      {props.children}
    </>

  )
}

export default FileUpload;