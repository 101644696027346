import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import {useTranslation} from 'react-i18next'
import { cudPie, builderAtom, toggleBuilderModal, deletePie, modalTmpDataAtom, resetModalInputData} from '../../jotais'
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Modal, Button } from 'antd';
import BuilderModalBody from './BuilderModalBody'
import {getRandomId} from '../../libs'

const BuilderModal = () => {
  const {t} = useTranslation();

  const [builder] = useAtom(builderAtom);
  const [modalInputData] = useAtom(modalTmpDataAtom);
  const [, removePie] = useAtom(deletePie);
  const {modals_open, modal_title, modal_data_id} = builder;
  const [, toggle] = useAtom(toggleBuilderModal);
  const [, createUpdateDeletePie] = useAtom(cudPie);

  const {title, link_value, artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type, sub_type} = modalInputData;
  const [, reset] = useAtom(resetModalInputData);

  const handleDeletePie = () => {
    if(modal_data_id) {
      removePie(modal_data_id)
    };
    toggle('');
  }

  const handleDoneClicked = () => {
    // check if we have any data in modal input, and wish to save it to a pie
    if(title && title.length > 0) {
      const randomId = getRandomId() * -1;
      createUpdateDeletePie({id: randomId, title, link_value, artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type, sub_type, modal_title})
      // setAuthor(tmpData)
      reset()
    }

    toggle('')    
  }

  return (
    <Modal
      title={modal_title ? t(modal_title+".1") : 'TITLE'}
      centered      
      visible={modals_open}
      width={800}
      onOk={() => {toggle('')}}
      onCancel={() => toggle('')}
      footer={[      
          modal_data_id ? <Button key="delete" type="danger" onClick={handleDeletePie}>
            <FontAwesomeIcon icon={['fas', 'trash-alt']} color="white"/>
          </Button> : null
        ,
        <Button key="back" type="primary" onClick={handleDoneClicked}>{t("DONE MODAL.1")}</Button>
      ]}
    >
      <BuilderModalBody/>
    </Modal>
  )
}

export default BuilderModal