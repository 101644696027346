import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom'
import { notification, Modal} from 'antd';
import { useAtom } from 'jotai';
import { notificationAtom, resetNotification} from '../jotais'

const openNotification = ({type, message, description, duration}) => {
  notification[type]({
    message,
    description,
    duration
  });
};

const openModal = ({type, message, description}) => {
  return Modal[type]({
    title: message,
    content: description,
  });
};

const Hanlder = () => {
  
  const [state] = useAtom(notificationAtom);
  const [, resetState] = useAtom(resetNotification)
  const { id, category, autoReset,type, message, description, duration, status, redirectTo, redirect} = state;
  const [modal, setModal] = useState(null);
  
  useEffect(() => {
    if(status) {      
      if(modal) {
        modal.destroy();
      }
      switch(category) {        
        case 'Modal':
          const _modal = openModal({type, message, description});
          setModal(_modal);
          break;
        default:
          openNotification({message, description, duration, type})
      }
      if(autoReset) {
        resetState()
      }      
    }    
  }, [id, status, category, description, message])

  if(redirect) {
    return <Redirect to={redirectTo}/>
  }

  return null;

}

export default Hanlder;