import React from 'react';
import { Spin, Alert } from 'antd';
import {SpecialEffectComponent} from '../BuilderBlock'

const DemoBlockPlaceholder = (props) => {
  const {is_pop, is_loading, alertMessage, alertDescription} = props;

  const cssStyle = {
    padding: "20px", 
    display: "flex", 
    justifyContent: 'center', 
    alignItems: 'center',
    // margin: "10px"
  }

  if(is_loading) {
    return (
      <div style={{...cssStyle}}>
        <div style={{ width: '360px', height: '140px', ...cssStyle}}>
          <Spin tip="Loading...">
            <Alert
              message={alertMessage}
              description={alertDescription}
              type="info"
            />            
          </Spin>
        </div>        
      </div>
    ) 
  }

  return (
    <div style={cssStyle}>
      <SpecialEffectComponent is_pop={is_pop}>
        {props.children}
      </SpecialEffectComponent>
    </div>
  )
}

export default DemoBlockPlaceholder;