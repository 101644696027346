import { atom } from "jotai";
import { notificationAtom } from './notificationAtom'
import railsApi from '../api/railsApi'

const temp = []

export const themesAtom = atom(temp);

export const fetchThemes = atom(null, (get, set, _) => {
  railsApi.get(`/v1/themes`)
  .then((res) => {
    const colors = res.data;
    set(themesAtom, () => {      
      return colors;
    })
  })
  .catch((err) => {
    // TODO: ERROR HANDLE
    // Redirect back to home page and send modal saying having issue
    // it can be unauthorized (401)
    const data = get(notificationAtom);
    set(notificationAtom, () => {
      return {...data, 
        status: true, 
        message: `Themes not found`, 
        category: 'Notification',
        description: `Please try again or send us an email to report issue.`,
        redirect: true,          
        redirectTo: '/dashboard',
        type: 'error',
        autoReset: true,
      }
    })
    // console.log(err.response.status);
  })
})