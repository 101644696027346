import React, { useEffect, useState} from "react";
import { Redirect } from 'react-router-dom'
import { Spin } from 'antd';
import {railsApi} from '../api'
import { useAtom } from 'jotai';
import {setNotificationState } from '../jotais'

const IntegrationCallback = (props) => {
  const [, setNotification] = useAtom(setNotificationState)
  const [redirect, setRedirect] = useState(false);  
  const {apiURL, code} = props;

  useEffect(() => {
    if(apiURL && code) {
      railsApi.post(apiURL, {code})
      .then((res) => {
        setRedirect(true)
        const message = res.data.message;
        setNotification({
          status: true, 
          category: 'Notification',
          message: `Success`,
          autoReset: true, 
          description: message,
          type: 'success',
        })          
      })
      .catch((err) => {
        setRedirect(true)
        const message = err.response.message;
        setNotification({
          status: true, 
          category: 'Notification',
          message: `Error`,
          autoReset: true, 
          description: message,
          type: 'error',
        })          
        // console.log(err.response)
      })
    }
  }, [apiURL, code])

  if(redirect) {
    return <Redirect to='/dashboard' />
  }

  return (
    <div style={{
      margin: "20px 0",
      marginBottom: "20px",
      padding: "30px 50px",
      textAlign: "center"      
    }}>
      <Spin />
    </div>
  )
}

export default IntegrationCallback;