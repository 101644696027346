import { useState, useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';
import {setNotificationState } from '../jotais'
import railsApi from '../api/railsApi'

const useUpdateProject = () => {
  const [changed, setChanged] = useState(0);
  const [errorChanged, setErrorChanged] = useState(0);
  const [, setNotification] = useAtom(setNotificationState)

  const projectUpdate = (data) => {
    const {id} = data;
    railsApi.put(`/v1/projects/${id}`, data)
    .then((res) => {
      setChanged(changed+1);
      setNotification({
        id: `${changed+1}`,
        status: true, 
        category: 'Notification',
        message: `Success`,
        autoReset: true, 
        description: `Project updated.`,
        type: 'success',
      })         
    })
    .catch((err) => {
      // error handle
      setErrorChanged(errorChanged+1);
      setNotification({
        id: `${errorChanged+1}`,
        status: true, 
        category: 'Notification',
        message: `Error`,
        autoReset: true, 
        description: `Failed to update project ${id}`,
        type: 'error',
      })          
    })
  }

  return [changed, projectUpdate];
}

export default useUpdateProject;