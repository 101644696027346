import { useState } from 'react';
import { useAtom } from 'jotai';
import {setNotificationState } from '../jotais'
import railsApi from '../api/railsApi'
import { removeLocalBuilderData } from './../libs'

const useDeleteProject = () => {
  
  // const [state, setState] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [, setNotification] = useAtom(setNotificationState)

  const deleteAPI = (id) => {
    railsApi.delete(`/v1/projects/${id}`)
    .then((res) => {
      setSuccessMessage(`Deleted project ${id}`);
      // find the delete project and remove data from local storage
      const localStorageId = `${id}_builder`;
      removeLocalBuilderData({localStorageId})
      setNotification({
        status: true, 
        category: 'Notification',
        message: `Success`,
        autoReset: true, 
        description: `Deleted project ${id}`,
        type: 'success',
      })          
    })
    .catch((err) => {
      setErrorMessage(`Failed to delete project ${id}`)
      setNotification({
        status: true, 
        category: 'Notification',
        message: `Error`,
        autoReset: true, 
        description: `Failed to delete project ${id}`,
        type: 'error',
      })      
      // console.log(err)
      // setState({...state, error: err, loading: false})
    })
  }

  return [successMessage, errorMessage, deleteAPI];
}

export default useDeleteProject;