import React, {useEffect, useState} from 'react';
import { Redirect, Link} from 'react-router-dom'
import {Steps, Divider, Input, Modal, Spin, Space, Button, List, Avatar } from 'antd';
import {Card, CardBody} from 'reactstrap'
import { useAtom } from 'jotai';
import axios from 'axios';
import {ImportPodcast} from '../components/import-podcasts'
import { authAtom, setupProjectWithItunesData} from '../jotais'
import {UploadClient} from '@uploadcare/upload-client'

const { Search } = Input;
const { Step } = Steps;

const publicKey = process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY;
const baseURL = process.env.REACT_APP_MS_BASE_PATH;

// Init a timeout variable to be used below
let timeout = null;

const ImportFromItunes = () => {
  const [authUser] = useAtom(authAtom);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  
  const [_, setupData] = useAtom(setupProjectWithItunesData);

  useEffect(() => {
    const data = localStorage.getItem('draft_builder');
    if(data === null) {
      setLoading(false)
    } else {
      // redirect to the projects page
      setLoading(false);
      setRedirect(true)
    }
  }, [])

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };    

  const handlePickedPodcast = (data) => {
    const {artworkUrl600, trackId, trackViewUrl, trackName, feedUrl, artistName, networks} = data;
    const client = new UploadClient({ publicKey: publicKey })
    client.uploadFile(artworkUrl600)
    .then((file) => {      
      const {uuid, cdnUrl, mimeType, originalUrl} = file;
      const artwork_uuid = uuid;
      const artwork_cdnUrl = cdnUrl;
      const artwork_originalUrl = originalUrl;
      const artwork_mime_type = mimeType;
      setupData({artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type, trackViewUrl, trackName, feedUrl, networks})
      setRedirect(true)
    })
    .catch((err) => {
      setModalMessage(`Failed to setup.  Please screenshot the issue, and send your podcast rss feed to support@castpie.com`);
      console.log(err);
    })
  }

  if(loading) {
    return null;
  }

  if(redirect && authUser.signedIn === true) {
    return <Redirect to="/projects"/>
  }

  if(redirect) {
    return <Redirect to='/quickstart-project'/>
  }

  return (
    <div className="row" style={{padding: '40px 40px 0 40px'}}>
      <div className="col-xl-12 col-xxl-12">
        <Card className="card card-custom card-stretch card-shadowless gutter-b dashboard-card-background-blur">
          <CardBody>
            <Steps current={0}>
              <Step title="Setup" />
              <Step title="Customize" />
              <Step title="Publish" />
            </Steps>
            <Divider/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{fontSize: '16px'}}>Use your existing podcast to instantly setup your page.</div>
              <Link to="/projects">Skip</Link>
            </div>
            <Divider/>
            <ImportPodcast 
              handlePickedPodcast={handlePickedPodcast}
              handleMessageCallback={setModalMessage}
              handleMessageVisable={setIsModalVisible}
            >
              <Modal title="Searching Podcast..." 
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel}
                confirmLoading={true}
              >
                <p>{modalMessage}</p>
              </Modal>              
            </ImportPodcast>
          </CardBody>    
        </Card>
      </div>
    </div>
  )
}

export default ImportFromItunes;