import React, { useEffect, useState} from "react";
import { Route, Redirect } from 'react-router-dom'
import { useAtom } from 'jotai';
import { authAtom, tryLocalSignin } from '../jotais'
import '../assets/styles/media-player.css';

// Try to login, if not yet signed in, then stay with the page
// if has been signed in then redirect to the builder page
const DemoRoute = ({ component: Component, ...rest }) => {
  const [authUser] = useAtom(authAtom);
  const [, trylocalSignIn] = useAtom(tryLocalSignin);
  
  useEffect(() => {
    trylocalSignIn()
  }, [])

  if(authUser.type === 'signout' ) {
    return (
      <Route {...rest} render={(props) => (
        <>
          <Component {...props} />
        </>
      )}/>
    )
  } else if (authUser.signedIn) {    
    return <Redirect to='/project-setup'/>;
  } else {    
    return null; // loading...
  }
}

export default DemoRoute;