import React from 'react';
import { motion } from "framer-motion"

// https://www.youtube.com/watch?v=qN-tiyUUZAo
const SpecialEffectComponent = (props) => {  
  const {scale, duration, is_pop} = props;

  const bounceTransition = {    
    yoyo: Infinity,
    duration: duration ? duration : 2,
  }

  if(is_pop) {
    return (
      <div style={{marginTop: '20px'}}>
        <motion.div animate={{ scale: scale ? scale : 0.8 }} transition={bounceTransition}>
          {props.children}
        </motion.div>
      </div>
    )    
  }
  
  return <div style={{marginTop: '20px'}}>{props.children}</div>
}

export default SpecialEffectComponent;