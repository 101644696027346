import React from 'react';
import {useTranslation} from 'react-i18next'
// import {Builder} from './screens';
import {MainRoutes} from './routes'

const App = () => {
  const {t, i18n} = useTranslation();

  const handleClick = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <MainRoutes>
      {/* <nav style={{width: "100%", padding: '2rem 0', backgroundColor: 'gray'}}>
        <button onClick={() => handleClick('en')}>English</button>
        <button onClick={() => handleClick('cn')}>Chinese</button>
      </nav> */}      
    </MainRoutes>
  )
}

export default App;