import React from 'react';
import { useAtom } from 'jotai';
import { getSocialNetworkPies, builderAtom} from '../../jotais'
import { SocialNetworkBlock} from '../BuilderBlock'

const SocialNetworksBlock = () => {

  const cssStyle= {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // backgroundColor: '#fff'
  }

  const [pies] = useAtom(getSocialNetworkPies);
  const [builder] = useAtom(builderAtom);
  const piesCount = pies.length;
  const {text_color} = builder;
  // const flexBasis = (pies.length % 2 === 1 ? );  
  return (
    <div style={cssStyle}>
      {
        pies.map((pie, idx) => (
          <SocialNetworkBlock 
            pos={idx} 
            key={idx}
            value={pie.value} 
            name={pie.name} 
            piesCount={piesCount}  
            icon_group={pie.icon_group}
            icon_name={pie.icon_name}    
            icon_color={pie.title_text_color && pie.title_text_color.length > 4 ? pie.icon_color : text_color}
          /> 
        ))
      }
    </div>
  )
}

export default SocialNetworksBlock;