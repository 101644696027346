import { atom } from "jotai";

const tmpData = {
  title: "",
  link_value: "",
  artwork_uuid: "", 
  artwork_cdnUrl: "", 
  artwork_originalUrl: "", 
  artwork_mime_type: "",
  sub_type: "HORIZONTAL-VIEW"
}

export const modalTmpDataAtom = atom(tmpData);

export const addModalInputData = atom(null, (get, set, input) => {
  const currentState = get(modalTmpDataAtom);
  set(modalTmpDataAtom, () => {
    return {...currentState, ...input};
  })
})

export const resetModalInputData = atom(null, (get, set, input) => {
  set(modalTmpDataAtom, () => {
    return tmpData;
  })
})