import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse,CardFooter, CardBody, CardHeader, Card, Input, InputGroup } from 'reactstrap';
import { getTopFontAwesomeIcons, searchFontAwesomeIcons } from '../../libs'

// Init a timeout variable to be used below
let timeout = null;

const IconPanel = ({isOpen, toggleOpen, iconClicked}) => {

  const {t} = useTranslation();

  const [icons, setIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pages, setPages] = useState(1);
  const [querying, setQuerying] = useState(false);
  
  // const [timeout, setTimeout] = useState(null);

  const iconPanelStyle = {
    marginTop: "10px",
    display: 'flex',
    flexWrap: 'wrap'
  }

  const iconStyle = {
    flex: "1 0 21%",
    // margin: "5px",
    height: "50px",
    borderStyle: 'solid',
    borderWidth: "0.1px",
    borderColor: '#ebedf8',
    cursor: 'pointer',
    fontSize: '1.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  useEffect(() => {
    if(isOpen) {
      const items = getTopFontAwesomeIcons(1);
      setIcons(items);
    }
  }, [isOpen])


  const clicMoreIcons = () => {
    const items = getTopFontAwesomeIcons(pages+1);
    setPages(pages+1);
    setIcons(items);
  }

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearchTerm(text);
    setQuerying(true);
    // Wait for User to Stop Typing
    // https://schier.co/blog/wait-for-user-to-stop-typing-using-javascript    
    if(timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if(text && text.length > 0) {
        const items = searchFontAwesomeIcons(text);
        setIcons(items);
      } else {
        const items = getTopFontAwesomeIcons(1);
        setPages(1);
        setIcons(items);
      }
      setQuerying(false);
    }, 1000)
    
  }

  return (
    <Collapse isOpen={isOpen}>
      <Card>
        <CardHeader>
          <div style={{float: 'right', cursor: 'pointer'}} onClick={toggleOpen}><FontAwesomeIcon icon={['fas', 'times']}/></div> 
        </CardHeader>
        <CardBody>          
          <div>
            <Input 
              type="text"
              placeholder="Find in 1000 icons" 
              value={searchTerm} 
              onChange={handleSearchChange}/>                               
          </div>
          {
            querying ? <div style={{marginTop: "10px", marginBottom: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div className="spinner-grow spinner-grow-sm text-info" role="status"></div>
              <div style={{marginLeft: '20px'}} className="text-info">Loading...</div>
            </div>  : null
          }
          <div style={iconPanelStyle}>
            {
              icons.map((icon, idx) => {
                const _icon = icon[0].split(' ');
                return (
                  <div key={idx} style={iconStyle}
                    onClick={() => {
                      iconClicked(_icon);
                    }}
                  >
                    <FontAwesomeIcon icon={_icon}/>
                  </div>
                )
              })
            }
          </div>          
        </CardBody>
        <CardFooter>
          <div className="btn btn-primary btn-block" onClick={clicMoreIcons}>{t('MORE-ICONS.1')}</div>
        </CardFooter>
      </Card>
    </Collapse>
  )
}

export default IconPanel;