import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'

const BlockOptionItem = (props) => {
  const {toggleMenu, type, icon_type, icon_name, icon_color, icon_size, name, hr, category_name} = props;
  const [, toggle] = useAtom(toggleBuilderModal);
  const {t} = useTranslation();

  const handleClick = () => {
    toggleMenu();
    // toggle({type, title, name});
    toggle({type, title: name, name: category_name})
  }

  return (
    <>              
      <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={handleClick}>
        <FontAwesomeIcon icon={[icon_type, icon_name]} color={icon_color} size={icon_size}/>
        <div style={{fontSize: "20px", marginLeft: "20px"}}>{t(`${name}.1`)}</div>
      </div>
      {hr ? <hr/> : null}
    </>      
  );
}

export default BlockOptionItem;