import React, {useEffect, useState} from 'react';
import { useAtom } from 'jotai';
import {Card, CardBody, Button, Collapse} from 'reactstrap'
import { Spin, Alert } from 'antd';
import ImportPodcast from './ImportPodcast'
import {turnArrayIntoHashByKey, podcastNetworks, getRandomId} from '../../libs'
import {getPodcastPies, unionPodcastNetworksPies} from '../../jotais'

const RefreshPodcast = () => {
  const [existingPodcastNetworks] = useAtom(getPodcastPies);
  const [_, updatePies] = useAtom(unionPodcastNetworksPies);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageVisable, setMessageVisable] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handlePickedPodcast = (data) => {
    const {networks} = data;
    const podcastNetworksDict = turnArrayIntoHashByKey(podcastNetworks, 'name');
    // // 1. get the new podcasts
    const newNewtworks = networks.map((network) => {
      const {name, url} = network;
      podcastNetworksDict[name]['link_value'] = url;
      podcastNetworksDict[name]['id'] = -1 * getRandomId();
      return podcastNetworksDict[name];
    })
    updatePies(newNewtworks);
    setMessageVisable(false);
    setMessage("")
    setIsOpen(false)    
  }

  return (
    <Card>
      <CardBody>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={toggle}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h3>Find links of your podcast</h3>
          </div>
          <Button onClick={toggle}>Start</Button>
        </div>      
        <div>{  messageVisable && <Spin tip="Loading...">
                <Alert
                  message="Searching the Internet..."
                  description={message}
                  type="success"
                />
              </Spin>}
            </div>            
      </CardBody>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <ImportPodcast 
            handlePickedPodcast={handlePickedPodcast}
            handleMessageCallback={setMessage}
            handleMessageVisable={setMessageVisable}
          ></ImportPodcast>        
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default RefreshPodcast