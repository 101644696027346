import React from "react";
import {ShortLinkCopyControl} from '../../components/Misc'

const ProjectCardBody = (props) => {
  const {title, id, shorterUrl, artworkUrl, qr_color, qr_size} = props;

  const publishButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#007bff',
  }


  return (
    <div className="">
      <ShortLinkCopyControl artworkUrl={artworkUrl} shortUrl={shorterUrl} publishButtonStyle={publishButtonStyle}/>
    </div>    
  )
}

export default ProjectCardBody;