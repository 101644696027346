import React, {useEffect, useState} from 'react';
import { useAtom } from 'jotai';
import { builderAtomWithPersistence, 
  builderAtom, 
  getBackgroundStyle,
  fetchColors,
  fetchThemes,
  piesAtomWithPersistence
} from '../jotais'
import { AddMessengersButton, 
  BuilderModal, 
  MessengersBlock,
  SocialNetworksBlock,
  PodcastNetworksBlock,
  ColorSettings,
  HeaderMenu,
  HeaderAvatar,
  HeaderTitle,
  AddBlockButton, 
  MainBlocks,
  FooterMenu,
  BackToDashboard,
  AuthorsBlock
} from './../components/BuilderPie'
import {
  MediaPlayer
} from './../media-player'

const Builder = (props) => {
  const id = props.match.params.id;
  const [loading, setLoading] = useState(true);
  // const id = "123"
    
  const [_, updateBuilder] = useAtom(builderAtomWithPersistence);
  const [__, updatePies] = useAtom(piesAtomWithPersistence);
  const [___, updateColors] = useAtom(fetchColors);
  const [____, updateThemes] = useAtom(fetchThemes);

  const [builder] = useAtom(builderAtom);
  const {text_color, button_color, button_text_color} = builder;
  const [wrapperStyle] = useAtom(getBackgroundStyle);  

  // const wrapperStyle = {
  //   background: backgroundColor, 
  //   minHeight: "100vh",
  //   display: "flex",
  //   flexDirection: "column",    
  //   // backgroundImage: `url(https://ucarecdn.com/25ba4e13-304e-49a7-80fb-6b196fef012e/)`,
  //   // backgroundPosition: 'center',
  // }

  const containerStyle = {    
    display: 'flex',    
    justifyContent: 'center',  
    flex: 1,
  }

  const smartPageStyle = {
    maxWidth: "400px",
    width: "100%", 
    padding: "10px",
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'stretch',
    // backgroundColor: "#fff"
  }

  const footerStyle = {
    // maxWidth: "400px",
    width: "100%", 
    // padding: "10px",
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: '#fff',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', 
    // justifyContent: 'center',
  }

  useEffect(() => {
    updateColors();
    updateThemes();
  }, [])

  useEffect(() => {    
    updateBuilder(id);
    updatePies(id);
  }, [id]) 
  
  useEffect(() => {
    if(builder.id == id) {
      // why is == not ===
      // because 3 == "3" is true, but 3 === "3" is false
      setLoading(false);
    }
  }, [builder.id])

  if(id && loading) {
    return null;
  }

  return (
    <MediaPlayer>
      <div style={wrapperStyle}>
        <div style={containerStyle}>
          <BuilderModal/>
          <div style={smartPageStyle}>                
            <HeaderMenu>
              <BackToDashboard/>
              <HeaderAvatar/>
              <ColorSettings name="COLOR-SETTINGS" type="COLOR-SETTINGS" title="SETTINGS"/>
            </HeaderMenu>
            <HeaderTitle textColor={text_color}/>
            <MainBlocks/>
          </div>        
        </div>      
        <div style={footerStyle}>        
          <div style={{maxWidth: '400px', width: "100%", padding: "10px",}}>            
            <AddBlockButton name="BLOCK" type="BLOCK" title="BLOCK" buttonLabel='ADD-BLOCK.1' width="100%"/>
            <AuthorsBlock/>
            <PodcastNetworksBlock/>
            <MessengersBlock/>
            <SocialNetworksBlock/>
            <br/>
            <div style={{display: 'flex', maxWidth: "400px", justifyContent: 'space-between', marginBottom: "10px", marginTop: "10px"}}>
              <AddMessengersButton name="PODCAST-NETWORK" type="PODCAST-NETWORK" title="PODCASTS" buttonLabel='Add-PODCASTS.1' width="48%"/>
              <AddMessengersButton name="AUTHORS" type="AUTHORS" title="AUTHORS" buttonLabel='Add-AUTHORS.1' width="48%"/>
            </div>                        
            <div style={{display: 'flex', maxWidth: "400px", justifyContent: 'space-between', marginBottom: "10px", marginTop: "10px"}}>              
              <AddMessengersButton name="MESSENGERS" type="MESSENGERS" title="MESSENGERS" buttonLabel='Add Messengers.1' width="48%"/>            
              <AddMessengersButton name="SOCIAL-NETWORKS" type="SOCIAL-NETWORKS" title="SOCIAL-NETWORKS" buttonLabel='Add-SOCIAL-NETWORKS.1' width="48%"/>
            </div>                          
            <div style={{width: "100%", marginTop: '10px', textAlign: 'center', fontSize: '14px', fontWeight: '700'}} >
              <a 
                href={process.env.REACT_APP_LINK_PAGE_PATH} 
                alt="Castpie Landing page"
                style={{color: text_color}}
                target="_blank"
              >CASTPIE</a>
            </div>
          </div>                
          <FooterMenu/>
        </div>       
      </div>
    </MediaPlayer>
  )
}

export default Builder;