import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'

const ButtonBlock = (props) => {
  const {id, text_color, button_color, main_type, modal_title, sub_type, name, title, description, image_url, icon_group, icon_name, link_value, demoView} = props;
  const [, toggle] = useAtom(toggleBuilderModal);

  const cssStyle = {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px", 
    display: "flex", 
    justifyContent: 'center', 
    alignItems: 'center',
    // width: '100%',
    // maxWidth: "400px",
    width: demoView ? "320px" : null,
    // width: '100%',
    minHeight: "60px",
    justifyContent: 'center',
    // borderStyle: 'dashed',    
    // borderRadius: "10px",
    borderRadius: "10px",
    backgroundColor: button_color,
    // color: "rgba(0, 0, 0, 0.5)",
    // borderColor: "rgba(0, 0, 0, 0.5)",
    color: `${text_color}`,
    borderColor: `${text_color}`,
    fontSize: "18px",
    fontWeight: '700',
    // margin: "10px"
  }

  const handleClick = () => {
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }

  if(demoView) {
    return (
      <div style={{...cssStyle, 
        cursor: 'pointer',
        boxShadow: '5px 10px 8px 10px #C1C1BD'
      }}>
        {icon_name && icon_group && <FontAwesomeIcon icon={[icon_group, icon_name]} size="2x"/>}
        {title ? <div style={{marginLeft: "5px", marginRight: "5px"}}>{title}</div> : null}
      </div> 
    )
  }


  return (
    <div style={cssStyle} onClick={handleClick}>
      {icon_name && icon_group && <FontAwesomeIcon icon={[icon_group, icon_name]} size="2x"/>}
      {title ? <div style={{marginLeft: "5px", marginRight: "5px"}}>{title}</div> : null}      
    </div>
  )
}

export default ButtonBlock;