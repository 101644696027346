import React, {useState} from 'react';
import { useAtom } from 'jotai';
import {  modalTmpDataAtom,
  addModalInputData,
  resetModalInputData,} from '../../jotais'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroupAddon, Button, FormText} from 'reactstrap';
import {getRandomId} from '../../libs'
import AuthorListItemLayout from './AuthorListItemLayout'
import AuthorListItemCore from './AuthorListItemCore'

const tmpData = {
  title: "",
  link_value: "",
  artwork_uuid: "", 
  artwork_cdnUrl: "", 
  artwork_originalUrl: "", 
  artwork_mime_type: "",
  sub_type: "HORIZONTAL-VIEW" 
}

const AuthorNewItem = ({handleCreate}) => {

  // const [author, setAuthor] = useState(tmpData);
  const [invalid, setInvalid] = useState(false);
  const [author] = useAtom(modalTmpDataAtom);
  const [, add] = useAtom(addModalInputData);
  const [, reset] = useAtom(resetModalInputData);
  const {title, link_value, artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type, sub_type} = author;

  const inputChanged = (data) => {    
    // setAuthor({...author, ...data});
    add(data)
  }  

  const handleCreateClicked = () => {
    if(title && title.length > 0) {
      const randomId = getRandomId() * -1;
      handleCreate({id: randomId, 
        title, link_value, artwork_uuid, artwork_cdnUrl, artwork_originalUrl, artwork_mime_type, sub_type
      });
      // setAuthor(tmpData)
      reset()
      setInvalid(false)
    } else {
      setInvalid(true)
    }
  }

  return (
    <>
      <AuthorListItemLayout>
        <AuthorListItemCore
          invalid={invalid}
          link_value={link_value}
          title={title}
          handleChange={inputChanged}
        />
        <InputGroupAddon addonType="append">
          <Button outline color="secondary" onClick={handleCreateClicked}>
            <FontAwesomeIcon icon={['fas', 'plus']} color="black"/> 
          </Button>
        </InputGroupAddon>
      </AuthorListItemLayout>
      {invalid ? <FormText color="danger">Please make sure you had entered the author name.</FormText> : null}
    </>
  )
}

export default AuthorNewItem;