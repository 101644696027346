import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {useFetchProjects, useDeleteProject, useUpdateProject} from '../../hooks'
import {ProjectsList, LoadingPageCard, PageHeader} from '../components'
import { Button, Empty } from 'antd';

const Main = () => {

  const [projects, loading, fetchData, changeProject] = useFetchProjects();
  const [deletedProject, deleteError, deleteAction] = useDeleteProject();
  const [projectUpdated, projectUpdate] = useUpdateProject();

  const handleDelete = (id) => {
    deleteAction(id);
  }

  const handleProjectUpdate = (data) => {
    projectUpdate(data);
  }

  useEffect(() => {
    if(deletedProject) {
      fetchData();
    }
    if(projectUpdated > 0) {
      fetchData();
    }
  }, [deletedProject, projectUpdated])

  if(loading) {
    return <>
      <PageHeader title={"Home"}/>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <LoadingPageCard loading={loading}/>
          </div>
        </div>
      </div>      
      </>
  } else if (projects.length === 0) {
    return (
      <>
        <PageHeader title={"Home"}/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Empty>
                <Button type="primary" shape="round" size="large">
                  <Link to="/projects">+ Add New Project</Link>
                </Button>
              </Empty>            
            </div>
          </div>
        </div> 
      </>   
    )
  } else {
    return (
      <>
        <PageHeader title={"Home"}/>
        <div className="container-fluid">
          <ProjectsList 
            items={projects} 
            handleDelete={handleDelete}
            handleUpdate={handleProjectUpdate}
            changeProject={changeProject}
          />  
        </div>    
      </>  
    )
  }
}

export default Main;