import React from "react";

const PageHeader = ({title}) => {

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center">
            <div className="col">
              <h1 className="header-title">{title}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>    
  )
}

export default PageHeader;