import React from 'react';
import TextBlock from './TextBlock'
import ButtonBlock from './ButtonBlock'
import CardBlock from './CardBlock'
import CountDownBlock from './CountDownBlock'
import PodcastBlock from './PodcastBlock'
import PlatformBadgeBlock from './PlatformBadgeBlock'
import MailingListBlock from './MailingListBlock'

const MainBlock = (props) => {
  const {id, text_color, button_text_color, button_color, main_type, modal_title, 
    sub_type, name, title, description, image_url, artwork_cdnUrl, value,
    icon_group, icon_name, link_value, price_tag, price_tag_background_color,
    is_hidden, schedule_from_date, countdown_to_date, countdown_from_date,
    placeholder, button_text, title_font_size, description_font_size
  } = props;

  switch(main_type) {
    case 'TEXT-INPUT':
      return <TextBlock 
      id={id}
      main_type={main_type}
      sub_type={sub_type}
      name={name}
      title={title}
      text_color={text_color} 
      modal_title={modal_title}
      description={description}
      image_url={image_url}
      title_font_size={title_font_size}
      description_font_size={description_font_size}
    />
    case 'LINK-INPUT':
      return <ButtonBlock 
      id={id}
      text_color={button_text_color}
      button_color={button_color}
      main_type={main_type}
      sub_type={sub_type}
      name={name}
      title={title}
      modal_title={modal_title}
      description={description}
      image_url={image_url}
      icon_group={icon_group}
      icon_name={icon_name}
      link_value={link_value}
    />
    case 'PLATFORM-BADGE':
      return <PlatformBadgeBlock 
      id={id}
      text_color={button_text_color}
      button_color={button_color}
      main_type={main_type}
      sub_type={sub_type}
      name={name}
      title={title}
      modal_title={modal_title}
      description={description}
      image_url={image_url}
      icon_group={icon_group}
      icon_name={icon_name}
      link_value={link_value}
    />  
    case 'TIP-US-BADGE':
      return <PlatformBadgeBlock 
      id={id}
      text_color={button_text_color}
      button_color={button_color}
      main_type={main_type}
      sub_type={sub_type}
      name={name}
      title={title}
      modal_title={modal_title}
      description={description}
      image_url={image_url}
      icon_group={icon_group}
      icon_name={icon_name}
      link_value={link_value}
    />        
    case 'CARD-INPUT':
      return <CardBlock 
      id={id}
      text_color={button_text_color}
      button_color={button_color}
      main_type={main_type}
      sub_type={sub_type}
      name={name}
      title={title}
      price_tag={price_tag}
      price_tag_background_color={price_tag_background_color ? price_tag_background_color : "#4cd963"}
      modal_title={modal_title}
      description={description}
      image_url={image_url}
      icon_group={icon_group}
      icon_name={icon_name}
      link_value={link_value}
      is_hidden={is_hidden}
      schedule_from_date={schedule_from_date}      
    />
    case "COUNT-DOWN":
      return <CountDownBlock
        id={id}
        button_text_color={button_text_color}
        button_color={button_color}
        main_type={main_type}
        sub_type={sub_type}
        name={name}
        title={title}
        price_tag={price_tag}
        price_tag_background_color={price_tag_background_color ? price_tag_background_color : "#4cd963"}
        modal_title={modal_title}
        description={description}
        image_url={image_url}
        icon_group={icon_group}
        icon_name={icon_name}
        link_value={link_value}
        is_hidden={is_hidden}
        schedule_from_date={schedule_from_date} 
        countdown_from_date={countdown_from_date}
        countdown_to_date={countdown_to_date}  
      />
    case "PODCAST-FEED":
      return <PodcastBlock
        id={id}
        text_color={text_color}
        button_text_color={button_text_color}
        button_color={button_color}
        main_type={main_type}
        sub_type={sub_type}
        name={name}
        title={title}
        placeholder={placeholder}
        price_tag={price_tag}
        price_tag_background_color={price_tag_background_color ? price_tag_background_color : "#4cd963"}
        modal_title={modal_title}
        description={description}
        image_url={image_url}
        icon_group={icon_group}
        icon_name={icon_name}
        link_value={link_value}
        is_hidden={is_hidden}
        value={value}
        schedule_from_date={schedule_from_date} 
        countdown_from_date={countdown_from_date}
        countdown_to_date={countdown_to_date}  
      />
      case "MAILING-LIST":
        return <MailingListBlock
          id={id}
          text_color={button_text_color}
          button_color={button_color}
          main_type={main_type}
          sub_type={sub_type}
          name={name}
          title={title}
          modal_title={modal_title}
          description={description}
          image_url={image_url}          
          icon_group={icon_group}
          icon_name={icon_name}
          link_value={link_value}
          value={value}
          button_text={button_text}
          placeholder={placeholder}
          price_tag={price_tag}      
        />
    default:
      return null;    
  }
}

export default MainBlock;