import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import {blockSize} from '../../libs'
import { toggleBuilderModal} from '../../jotais'

const MessageBlock = ((props) => {  
  const {value, name, piesCount, pos, icon_group, icon_name, icon_color} = props;
  const [, toggle] = useAtom(toggleBuilderModal);
  const flexBasis = blockSize(piesCount, pos, 3);
  
  const cssStyle = {
    order: 1,
    flexBasis: "20%",
    marginTop: "10px",
    marginBottom: "10px",
    height: "40px",
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: "pointer"
  }  
 
  return (
    <div style={cssStyle} onClick={() => {
      toggle({type: 'MESSENGERS-SORT', title: 'MESSENGERS', name: 'MESSENGERS'});
    }}>
      <FontAwesomeIcon 
        icon={[icon_group, icon_name]} 
        size="2x"
        color={icon_color}
      /> 
    </div>
  )
})

export default MessageBlock;