import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai';
import {blockSize} from '../../libs'
import { toggleBuilderModal} from '../../jotais'

const SocialNetworkBlock = ((props) => {  
  const {value, name, piesCount, pos, icon_group, icon_name, icon_color} = props;
  const [, toggle] = useAtom(toggleBuilderModal);
  // const flexBasis = blockSize(piesCount, pos, 3);
  
  const cssStyle = {
    order: 1,
    flexBasis: "32%",
    // flexBasis,
    // backgroundColor: "#e3e3e3",
    // padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    height: "40px",
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: "pointer"
  }     
  return (
    <div style={cssStyle} onClick={() => {
      toggle({type: 'SOCIAL-NETWORKS-SORT', title: 'SOCIAL-NETWORKS', name: 'SOCIAL-NETWORKS'});
    }}>
      <FontAwesomeIcon 
        icon={[icon_group, icon_name]} 
        size="2x"
        color={icon_color}
      /> 
    </div>
  )
})

export default SocialNetworkBlock;