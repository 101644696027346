import { useState, useEffect } from 'react';
import railsApi from '../api/railsApi'

const useFetchPodcastHealth = ({project_id, is_check_podcast_health}) => {
  const [state, setState] = useState({});
  const [networks, setNetworks] = useState([]);

  const fetchData = () => {
    railsApi.get(`/v1/projects/${project_id}/get_podcast_health_charts`)
    .then((res) => {
      setState(res.data)
      setNetworks(Object.keys(res.data));
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {    
    if(project_id && is_check_podcast_health) {
      fetchData();
    }
  }, [project_id, is_check_podcast_health])  

  return [state, networks, fetchData];
}

export default useFetchPodcastHealth;