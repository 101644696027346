import React, {useState} from 'react';
import { Link, Redirect } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { authAtom, builderAtom } from '../../jotais'
import {  getTextColor, removeLocalBuilderData } from '../../libs'

const BackToDashboard = () => {
  const {t} = useTranslation();
  const [user] = useAtom(authAtom);
  const [builder] = useAtom(builderAtom);
  const {text_color} = builder;
  const [redirect, setRedirect] = useState(false);

  const cssStyle = {
    cursor: 'pointer',
    // borderStyle: 'dashed',
    textAlign: 'center',
    width: '80px',
    fontSize: "12px",
    borderRadius: "50px",
    padding: ".2em 1.2em",
    color: getTextColor(text_color),
    background: text_color
  }

  const handlRemove = () => {
    const localStorageId = 'draft_builder';
    removeLocalBuilderData({localStorageId});
    setRedirect(true);
  }

  if(redirect) {
    return <Redirect to="/quickstart"/>
  }

  if(user.signedIn) {
    return (
      <Link to="/dashboard" style={{ textDecoration: 'none' }}>
        <div style={cssStyle}>
          {t("HOME.1")}          
        </div>
      </Link>
    )
  } else {
    return <div style={cssStyle} onClick={handlRemove}>{t("RESET.1")}</div>;
  }
}

export default BackToDashboard;