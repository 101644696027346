import React from 'react';
import { useAtom } from 'jotai';
import { toggleBuilderModal} from '../../jotais'

const TextBlock = (props) => {
  const {id, main_type, modal_title, text_color, sub_type, name, title, description, image_url, title_font_size, description_font_size} = props;
  const [, toggle] = useAtom(toggleBuilderModal);

  
  const style = {
    color: text_color,    
    padding: '10px'
  }

  const titleStyle = {color: text_color}
  if(title_font_size) {    
    titleStyle['fontSize'] = `${title_font_size}px`
  }
  const descriptionStyle = {}
  if(description_font_size) {
    descriptionStyle['fontSize'] = `${description_font_size}px`
  }  

  const handleClick = () => {
    toggle({type: main_type, name, title: modal_title, pieId: id})
  }

  return (
    <div style={style} onClick={handleClick}>
      {title ? <h4 style={titleStyle}>{title}</h4> : null}
      {description ? <p style={descriptionStyle}>{description}</p> : null}
    </div>
  )
}

export default TextBlock;