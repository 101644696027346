import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from 'react-i18next'
import {Row, Col, Form, FormGroup, Label, Input, InputGroup, InputGroupText, Button, DropdownToggle} from 'reactstrap';
import { useAtom } from 'jotai';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import { DemoBlockPlaceholder, PlatformBadgesDropdown} from '../Misc'
import {platformBadges} from '../../libs'
import { PlatformBadgeBlock } from "../BuilderBlock";

const BadgeModalBody = () => {
  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);  
  const {button_color, button_text_color} = builder;
  const [initState, setInitState] = useState(true);
  // const [pie, setPie] = useState({title: "", id: "", description: ""});
  const [currentData, setCurrentData] = useState({title: "", description: "", link_value: "", icon_name: ""});

  const pieChange = (name, value) => {
    const pieID = pie.id;
    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    const newData = { ...pie, ...currentData, [name]: value, modal_title};
    createUpdateDeletePie(newData);
    
  } 

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCurrentData({...currentData, [name]: value})
    pieChange(name, value);
  }

  const handleSelectChange = (data) => {
    const modal_title = builder.modal_title;
    const {title, icon_color, icon_group, icon_name, placeholder} = data;
    if(pie.link_value) {
      const newData = { ...pie, ...currentData, modal_title, title, icon_color, icon_group, icon_name, placeholder};
      createUpdateDeletePie(newData);
      setCurrentData({...currentData, icon_name, title})
      // we only change the icon
    } else {
      const newData = { ...currentData, ...data};
      setCurrentData({...newData})
    }
  }

  useEffect(() => {
    if(initState) {
      if(pie.title) {            
        setCurrentData({...currentData, ...pie})
      } else {
        // init local state
        const initData = platformBadges[0];
        setCurrentData({...currentData, ...initData})
      }

      setInitState(false)
    }

  }, [pie])

  return (
    <>
      <Form>
        <Row form>
          <Col md={6}>
            <Label for="link_value">{t("PLATFORM-BADGE.1")}</Label>        
            <PlatformBadgesDropdown 
              onSelect={handleSelectChange} 
              value={currentData.icon_name}
            />
          </Col>
          <Col md={6}>
            <FormGroup role="form">
              <Label for="link_value">{t("HEADER-LINK.1")}</Label>
              <Input 
                type="url" 
                name="link_value" 
                // id={pie.id}
                id="link"
                value={currentData.link_value}
                onChange={handleInputChange}
                placeholder={currentData.placeholder}
              />
            </FormGroup>  
          </Col>
        </Row>
              
        <Row form>
          <Col md={6}>
            <FormGroup role="form">
              <Label for="title">{t("PLATFORM-NAME.1")}</Label>
              <Input 
                type="text" 
                name="title" 
                // id={pie.id}
                id="title"
                placeholder={t("PLATFORM-NAME.1")}
                value={currentData.title}
                onChange={handleInputChange}          
              />
            </FormGroup>            
          </Col>
          <Col md={6}>
            <FormGroup role="form">
            <Label for="description">{t("PLATFORM-DESCRIPTION.1")}</Label>
              <Input 
                type="text" 
                name="description" 
                // id={pie.id}
                id="description"
                placeholder={t("PLATFORM-DESCRIPTION.1")}
                value={currentData.description}
                onChange={handleInputChange}          
              />
            </FormGroup> 
          </Col>
        </Row>                 
      </Form>
      <DemoBlockPlaceholder is_pop={pie.is_pop}>
        <PlatformBadgeBlock
          button_color={currentData.background_color && currentData.background_color.length > 4 ? currentData.background_color : `${button_color}`} 
          text_color={currentData.title_text_color && currentData.title_text_color.length > 4 ? currentData.title_text_color : button_text_color}
          icon_color={currentData.icon_color && currentData.icon_color.length > 4 ? currentData.icon_color : button_text_color}
          title={currentData.title}
          description={currentData.description}
          icon_group={currentData.icon_group}
          icon_name={currentData.icon_name}
          link_value={currentData.link_value}
          demoView={true}
        />
      </DemoBlockPlaceholder>
    </>
  )
}

export default BadgeModalBody;