import React from 'react';
import {useTranslation} from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AddSpecialEffect = (props) => {
  const {name, icon, handleClicked, selected} = props;
  const {t} = useTranslation();

  return (
    <div style={{
      marginRight: '10px',
      width: "100px",
      height: "100px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer'
    }} className="hover-text-underline"
    onClick={() => {
      handleClicked(name)
    }}
    >
      <div style={{
        backgroundColor: selected === true ? "#0077ff" : "#dadada", 
        width: "50px",
        height: "50px",
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <FontAwesomeIcon icon={icon} color="white" size="lg"/>
      </div>
      <div style={{
        marginTop: '10px'
      }}>{t(`${name}.1`)}</div>
    </div>
  )
}

export default AddSpecialEffect;