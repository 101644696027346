import React from 'react';
import {getTextColor} from '../../libs'

const CountDownCallToActionButton = ({price_tag, price_tag_background_color}) => {
  const buttonStyle = {
    cursor: 'pointer',
    borderRadius: '40px',
    borderWidth: '1px',
    minHeight: '40px',
    padding: "10px",
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: "10px",
    backgroundColor: price_tag_background_color,
    color: getTextColor(price_tag_background_color)
  }

  if(price_tag) {
    return (
      <div style={buttonStyle}>
        {price_tag}
      </div>
    )
  }

  return null;
}

export default CountDownCallToActionButton;