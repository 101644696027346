import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import {setNotificationState } from '../jotais'
import railsApi from '../api/railsApi'

const useFetchProject = (projectId) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  const [, setNotification] = useAtom(setNotificationState)

  useEffect(() => {
    if(projectId) {
      railsApi.get(`/v1/projects/${projectId}`)
      .then((res) => {
        setState(res.data);
        setLoading(false)
      })
      .catch((err) => {      
        console.log(err.response.statusText)
        setLoading(false)
      })
    }
  }, [projectId])

  const updateProject = () => {
    railsApi.put(`/v1/projects/${projectId}/update_settings`, state)
    .then((res) => {
      setState(res.data);
      setNotification({
        id: Math.floor(Math.random() * 10000000),
        status: true, 
        category: 'Notification',
        message: `Success`,
        autoReset: true, 
        description: `Updated project ${projectId}`,
        type: 'success',
      })        
    })
    .catch((err) => {
      setNotification({
        id: Math.floor(Math.random() * 10000000),
        status: true, 
        category: 'Notification',
        message: `Error`,
        autoReset: true, 
        description: `Failed to update project ${projectId}`,
        type: 'error',
      })   
    })
  }
  
  return [state, setState, loading, updateProject];
}

export default useFetchProject;