import { atom } from "jotai";

// list of notifcaition type
// MODAL, ALERT, TOAST, notification

const item = {
  id: 0,
  status: false,
  message: '',
  description: '',
  title: '',
  category: 'Notification',
  type: 'open', // open, warning, info, success, error
  redirect: false,
  redirectTo: '',
  autoReset: true,
  duration: 4.5 // in seconds
}

export const notificationAtom = atom(item);

export const setNotificationState = atom(null, (get, set, data) => {

  const _data = get(notificationAtom);

  setTimeout(() => {    
    set(notificationAtom, () => {
      return {..._data, ...data, status: true}
    })
  }, 500)
})

export const toggleNotification = atom(null, (get, set, _) => {
  const data = get(notificationAtom);
  set(notificationAtom, () => {
    return {...data, status: !data.status}
  })
})

export const resetNotification = atom(null, (get, set, _) => {  
  return item;
})