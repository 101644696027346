import { useState, useEffect } from 'react';
import railsApi from '../api/railsApi'

const useOptoutEmailCampaign = (id) => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if(id) {
      railsApi.put(`/v1/opt-out-cold-email-campaign/${id}`, {
        api_key: process.env.REACT_APP_CAPST_PIE_API_CLIENT_KEY
      })
      .then((res) => {
        setLoading(false)
      })
      .catch((err) => {      
        setLoading(false)
      })
    }
  }, [id])

  return [loading];
}

export default useOptoutEmailCampaign