import React from 'react';
import {getTextColor} from '../../libs'

// https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter
const CardBlockBackgroundButton = (props) => {

  const {handleClick, text_color, button_color, title, price_tag, image_url, demoView, price_tag_background_color} = props;

  const titleStyle = {
    fontSize: "18px",
    backgroundColor: `${button_color}60`,
    color: getTextColor(button_color),
    padding: '10px',
    borderRadius: '10px',
    backdropFilter: 'blur(10px)'
  }
  
  return (
    <div style={{
      display: 'flex', 
      justifyContent: 'space-between',
      flexDirection: 'column',
      borderRadius: "10px",
      minHeight: "104px",
      width: demoView ? "360px" : null,
      padding: '10px 20px',
      backgroundColor: button_color,       
      color: `${text_color}`,
      borderColor: `${text_color}`,
      cursor: 'pointer',
      boxShadow: demoView ? '5px 10px 8px 10px #C1C1BD' : null,
      // boxShadow: 'inset 0 0 0 100vw rgba(0,0,0,0.5)',
      background: `url(${image_url ? image_url : "https://via.placeholder.com/160"}) no-repeat center center`,
      backgroundSize: 'cover'
    }}
      onClick={handleClick}
    >      
      {title ? <div style={titleStyle}>{title}</div> : null}
      {
        price_tag && price_tag.length > 0 ? <div style={{
          marginTop: '10px',
          alignSelf: 'flex-start', 
          backgroundColor: price_tag_background_color, 
          padding: "6px", 
          color: getTextColor(price_tag_background_color),
          borderRadius: "8px",}}>
          <strong>{price_tag}</strong>
        </div> : null
      }
    </div>
  )
}

export default CardBlockBackgroundButton;