import React from 'react';
import { useAtom } from "jotai";
import { builderAtom, getUpperSectionContrastColor } from '../../jotais'

const CreateButton = (props) => {
  const [builder] = useAtom(builderAtom);
  const {text_color} = builder;
  const [borderColor] = useAtom(getUpperSectionContrastColor);
  const {width} = props;

  const cssStyle = {
    padding: "10px", 
    display: "flex", 
    justifyContent: 'center', 
    alignItems: 'center',
    // maxWidth: "400px",
    width: width,
    height: "60px",
    borderStyle: 'dashed',    
    borderRadius: "10px",
    // color: "rgba(0, 0, 0, 0.5)",
    // borderColor: "rgba(0, 0, 0, 0.5)",
    color: borderColor,
    borderColor: borderColor,
    fontSize: "16px",
    fontWeight: '700',
    // margin: "10px"
  }

  return (
    <div style={cssStyle} onClick={props.onClick}>
      {props.children}
    </div>
  )
}

export default CreateButton;