import React, {useEffect, useState} from "react";
import {useFetchMailingServices, 
  useMailingIntegrations} from '../../hooks'
import { PageHeader} from '../components'
import {CardTable, CardBodyList} from '../components/integrations'

const Integrations = (props) => {

  const [mailingServices] = useFetchMailingServices();
  const [mailingIntegrations, _, deleteMailingIntegration] = useMailingIntegrations();

  const handleDeleteIntegration = (service_id, integration_id) => {
    deleteMailingIntegration(service_id, integration_id)
  }

  return (
    <>
      <PageHeader title={"Integrations"}/>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <CardTable options={mailingServices}>
              <CardBodyList 
                items={mailingIntegrations}
                handleDelete={handleDeleteIntegration}
              />
            </CardTable>
          </div>
        </div>
      </div>     
    </>
  )
}

export default Integrations;