import React from 'react';

const HeaderMenu = (props) => {
  const cssStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

  return (
    <div style={cssStyle}>
      {props.children}
    </div>
  )
}

export default HeaderMenu;