import React, {useState} from "react";
import {Tooltip} from 'reactstrap'
import { format } from 'date-fns'

const BooleanBarItem = ({item, id}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div 
        id={`Tooptip-${id}`}
        style={{
        height: '30px', 
        width: '5px',
        borderRadius: '2px', 
        backgroundColor: item.is_error ? '#E8308C' : '#44B217', marginRight: '2px'}}></div>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={`Tooptip-${id}`}
        toggle={toggle}
      >
        {format(new Date(item.created_at), 'MM/dd hh:mm a')}
      </Tooltip>        
    </>
  )

}

export default BooleanBarItem;

// 80EAAB
// 44B217