import React, {useState} from "react";
import { useAtom } from 'jotai';
import { signin} from '../jotais'
import AuthForm from './AuthForm'

const SignIn = () => {

  const [tryUser, setTryUser] = useState({email: "", password: ""});
  const [, userSignIn] = useAtom(signin);

  const handleSubmit = (event) => {
    event.preventDefault();
    userSignIn(tryUser)
  }

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   // console.log({[name]: value})
  //   setTryUser({...tryUser, [name]: value});
  // }

  const onValuesChange = (values) => {
    const keys = Object.keys(values);
    const [name] = keys;
    const value = values[name];
    setTryUser({...tryUser, [name]: value});
  }

  const onFinish = (values) => {
    userSignIn(tryUser)
  }

  return (
    <>
    <h1 className="display-4 text-center mb-3">Sign in</h1>
    <p className="text-muted text-center mb-5">Connect audiences to all of your content with just one link</p>
    <AuthForm
      formTitle="Account Login"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      submitButtonLabel="Log in"
      helpoutMessage={{
        line1: "Don't have an account?",
        line2: "Register now",
        link: "/signup"
      }}
    />
    </>
  )

  
}

export default SignIn;