import { useState, useEffect } from 'react';
import msApi from '../api/msApi'

const useFetchWebsite = () => {
  // const [state, setState] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState({});

  const fetchData = (uri) => {
    if(uri) {
      setLoading(true);
      msApi.get(`/v1/url_parser?uri=${uri}`)
      .then((res) => {
        setLoading(false)
        const {
          author, date, description, image, logo, publisher,
          title, price, hostname, url, video
        } = res.data;

        setWebsite({author, date, description, image, logo, publisher,
          title, price, hostname, url, video})
      })
      .catch((err) => {
        setLoading(false);
        // setError(err.response.statusText);
      })
    } else {
      setWebsite({})
      setError(null);
    }  
  }


  // useEffect(() => {
  //   fetchData();
  // }, [uri])  

  return [website, error, loading, fetchData];
}

export default useFetchWebsite;