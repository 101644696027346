import React from "react";
import { Divider, Image, Empty } from 'antd';
import {Card, CardBody} from 'reactstrap'
import {useFetchPies} from '../../hooks'
import ProjectCardHeader from './ProjectCardHeader'
import ProjectCardBody from './ProjectCardBody'
import BackgroundCard from './BackgroundCard'
import {ProgressBarChart} from './stats'
import PiesList from './PiesList'
import SlugUpdateForm from './SlugUpdateForm'
import {ProjectStatsSummaryCard, PodcastHealthChart} from './project-stats'
import {QrCodeDisplay} from './../../components/Misc'

const ProjectListCard = (props) => {
  const {project, slug, slugPrefix, shorterUrl, idx, changeProject,
    artworkUrl, projectId, createdDate, is_check_podcast_health,
    handleDelete, handleUpdate} = props;

  const [_, title] = useFetchPies(projectId);
  
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card className="card card-custom card-stretch card-shadowless gutter-b dashboard-card-background-blur">
            <CardBody>
              <ProjectCardHeader
                title={title}
                id={project.id}
                shorterUrl={shorterUrl}
                artworkUrl={artworkUrl}
                createdDate={createdDate}
                handleDelete={handleDelete}
              >
                <ProjectCardBody artworkUrl={artworkUrl} id={projectId} shorterUrl={shorterUrl}/>
              </ProjectCardHeader>
              
              
              <div className="row align-items-center">
                <div className="col-12">
                  <PodcastHealthChart project_id={projectId} is_check_podcast_health={is_check_podcast_health}/>
                </div>
              </div>
              <hr/>
              <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                  <SlugUpdateForm 
                      idx={idx}
                      slug={slug} 
                      slugPrefix={slugPrefix} 
                      project={project}                    
                      handleUpdate={handleUpdate}
                      changeProject={changeProject}
                    />
                </div>
                <div className="col-12 col-lg-6">
                  <QrCodeDisplay
                    artworkUrl={artworkUrl}
                    shortUrl={shorterUrl}
                    qr_color={project.qr_color}
                    qr_size={project.qr_size}
                    qr_level={project.qr_level}          
                  />   
                </div>                                                           
              </div>                                          
            </CardBody>
          </Card>
        </div>
        {/* <div className="col-xl-6 col-xxl-4">
          <ProjectStatsSummaryCard
            title="Monthly Visitors"
            chartId={`${projectId}-monthly-visitor-chart`}
            endpointPath={`/v1/projects/${projectId}/get_total_views`}
          />
          <ProjectStatsSummaryCard
            title="Monthly Clicks"
            chartId={`${projectId}-monthly-click-chart`}
            endpointPath={`/v1/projects/${projectId}/get_total_pie_clicks`}
          />                  
        </div>      */}
                  
      </div>
    </>
  )
}

export default ProjectListCard;