import React, {useState, useEffect} from 'react';
import { Alert, Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber } from 'antd';
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai';
import { builderAtom, updateOpeningModalPieId, getPieById, cudPie} from '../../jotais'
import { PodcastBlock} from '../BuilderBlock'
import {DemoBlockPlaceholder} from '../Misc'
import {SpecialEffectIndicator} from '../BuilderPie'
import {useFetchRss} from '../../hooks'

const { Search } = Input;
let timeout = null;

const PodcastBlockModalBody = (props) => {
  
  const {t} = useTranslation();
  const [builder] = useAtom(builderAtom);
  const [, updateModalId] = useAtom(updateOpeningModalPieId);
  const [pie] = useAtom(getPieById)
  const [, createUpdateDeletePie] = useAtom(cudPie);
  const {button_color, button_text_color} = builder;
  const [inputURL, setInputURL] = useState('');
  const [podcast, error, loading, fetchData] = useFetchRss();
  const {title, description, image_url, items, people} = podcast;
  // const [subType, setSubType] = useState('THUMBNAIL');
  const epItems = [{key: 1, value: 1}, {key: 2, value: 2}, {key: 3, value: 3}, {key: 4, value: 4}, {key: 5, value: 5}]
  const subTypes = [
    {key: 1, value: 'MIX-CARD-TILE', label: 'Mix'},
    {key: 2, value: 'THUMBNAIL', label: 'Thumbnail'}, 
    {key: 3, value: 'CARD', label: 'Card'}, 
    {key: 4, value: 'TILE', label: 'Tile'}
  ]

  const [searchValue, setSearchValue] = useState(pie.link_value);

  // https://download.randgad.com/feed.xml
  useEffect(() => {
    fetchData(inputURL);
  }, [inputURL])

  useEffect(() => {
    if(items && items.length > 0) {
      const newPie = {
        link_value: inputURL,
        value: pie.value ? pie.value : 3,
        sub_type: pie.sub_type ? pie.sub_type : 'Mix',
        title,
        description,
        image_url
      }
      pieChange(newPie);
    }        
  }, [items])

  useEffect(() => {
    setInputURL(pie.link_value);
  }, [pie.link_value])

  const onSearch = (value) => {    
    setInputURL(value);
  }
  
  const searchValueChange = (val) => {
    const searchTerm = val.currentTarget.value;
    setSearchValue(searchTerm);
    if(timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if(searchTerm) {
        // Make a new timeout set to go off in 1000ms (1 second)
        setInputURL(searchTerm)
      }
    }, 1000);
    // setInputURL(searchTerm)

  }

  const handleNumberOfItemsChange = (data) => {
    const value = data.value;
    pieChange({value})
  }  

  const handleSubTypeChange = (data) => {
    const sub_type = data.value;
    pieChange({sub_type})
  }

  const pieChange = (newData) => {
    const pieID = pie.id;

    if(builder.modal_data_id !== pieID) {      
      updateModalId(pieID)
    }
    const modal_title = builder.modal_title;
    createUpdateDeletePie({...pie, ...newData, modal_title})    
  } 

  const onPeopleTitleChange = (e) => {
    const placeholder = e.target.value;
    pieChange({placeholder})
  }

  return (
    <div>
      <Search 
        name="searchTerm"
        placeholder={t("INPUT-RSS-FEED.1")}
        onSearch={onSearch} 
        onChange={searchValueChange}
        allowClear
        size="large"
        enterButton
        value={searchValue} 
        defaultValue={searchValue}
        loading={loading}
      />    
      <div style={{margin: '20px 0', display: 'flex', justifyContent: 'center'}}>
        <Form
          layout="inline"
        >
          <Form.Item label="Layout">
              <Select 
                labelInValue
                defaultValue={{ value: pie.sub_type ? pie.sub_type : 'MIX-CARD-TILE' }}
                style={{ width: 120 }}
                onChange={handleSubTypeChange}
              >
                {
                  subTypes.map((item) => <Select.Option key={item.key} value={item.value}>{item.label}</Select.Option>)
                }
              </Select>
            </Form.Item>           
          <Form.Item label="Items">
            <Select 
              labelInValue
              defaultValue={{ value: pie.value ? pie.value : 3 }}
              style={{ width: 120 }}
              onChange={handleNumberOfItemsChange}
            >
              {
                epItems.map((item) => <Select.Option key={item.key} value={item.value}>{item.value}</Select.Option>)
              }
            </Select>
          </Form.Item> 
        </Form>
      </div>
      {
        people ? <Input showCount maxLength={128} onChange={onPeopleTitleChange} value={pie.placeholder ? pie.placeholder : "Meet the Hosts"} /> : null
      }
      {error ? <Alert message={error} type="error" showIcon /> : null}
      <DemoBlockPlaceholder is_pop={pie.is_pop}>
        <SpecialEffectIndicator is_hidden={pie.is_hidden} schedule_from_date={pie.schedule_from_date}>
          <PodcastBlock
            id={pie.id}
            button_text_color={button_text_color}
            button_color={button_color}
            name={pie.name}
            title={pie.title}
            description={pie.description}
            placeholder={pie.placeholder}
            sub_type={pie.sub_type}
            image_url={pie.artwork_cdnUrl ? pie.artwork_cdnUrl : pie.image_url}
            icon_group={pie.icon_group}
            icon_name={pie.icon_name}
            link_value={pie.link_value}
            is_hidden={pie.is_hidden}
            value={pie.value}            
            schedule_from_date={pie.schedule_from_date}
            schedule_to_date={pie.schedule_to_date}
            demoView={true}
          />
        </SpecialEffectIndicator>
      </DemoBlockPlaceholder>
    </div>
  )
}

export default PodcastBlockModalBody;