import React from 'react';
import { Empty, Button } from 'antd';

const ErrorPodcastStatus = ({url, handleClick}) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_DEFAULT}
      imageStyle={{
        height: 60,
      }}
      description={
        <span>
          Unable to fetch data from {url}
        </span>
      }
    >
      <Button type="primary" onClick={handleClick}>Try another one?</Button>
    </Empty>
  )
}

export default ErrorPodcastStatus;