import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HideOrScheduleIcon = ({schedule_from_date, is_hidden, cssStyle}) => {
  // ['fa', 'clock']
  // ['fa', 'eye-slash']
  
  if(schedule_from_date) {
    return <FontAwesomeIcon icon={['fa', 'clock']} color="black" size="lg" style={cssStyle}/>
  } else if (is_hidden) {
    return <FontAwesomeIcon icon={['fa', 'eye-slash']} color="black" size="lg" style={cssStyle}/>
  } else {
    return null;
  }
}

export default HideOrScheduleIcon