import React, {useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { useAtom } from 'jotai';
import { signin} from '../jotais'

const PasscodeSignIn = (props) => {
  const [, userSignIn] = useAtom(signin);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const values = queryString.parse(props.location.search);  

    if(values && values['username'] && values['passcode']) {
      const email = values['username'];
      const password = values['passcode'];
      userSignIn({email, password, is_activated: true});
    } else {
      setRedirect(true);
    }
  }, [])

  if(redirect) {
    return <Redirect to='/dashboard' />;
  }

  return null;
}

export default PasscodeSignIn;