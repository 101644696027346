import React, { useState, useEffect } from "react";
import { SketchPicker } from 'react-color'
import { Button, Form, Input, Select, Modal} from 'antd';

const SlugUpdateForm = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pattern = /^[a-z0-9]+$/i;
  const {slug, slugPrefix, project, handleUpdate, changeProject, idx} = props;  
  const [newSlug, setNewSlug] = useState(slug);
  const [newSize, setNewSize] = useState(null);
  const [newLevel, setNewLevel] = useState(null);
  const [newColor, setNewColor] = useState(null);

  const [qrSizes, setQrSizes] = useState([
    {lable: "100", value: 100},
    {lable: "200", value: 200},
    {lable: "300", value: 300},
    {lable: "400", value: 400},
  ])
  
  const [qrlevels, setQrLevels] = useState([
    {lable: "L", value: "L"},
    {lable: "M", value: "M"},
    {lable: "Q", value: "Q"},
    {lable: "H", value: "H"},
  ])  

  const layout = {  labelCol: { span: 8 },
  wrapperCol: { span: 16 },}

  const [form] = Form.useForm();

  useEffect(() => {
    setNewSlug(slug);
  }, [slug])

  useEffect(() => {
    if(project) {   
      setNewSize((project.qr_size ? project.qr_size : 200))
      setNewLevel((project.qr_level ? project.qr_level : "L"))
      setNewColor((project.qr_color ? project.qr_color : "#000"));
    }    
  }, [project])

  const onFinish = () => {
    // userSignIn(tryUser)
    const newData = {...project, slug: newSlug}
    handleUpdate(newData)
  }

  const onValuesChange = (values) => {
    if(values['slug']) {
      setNewSlug(values['slug'])
    }
  }

  const handlReset = () => {
    form.resetFields()
  }

  const handleChangeQrSize = (evt) => {
    // console.log(evt)
    const qr_size = evt;
    const newData = {...project, qr_size};    
    changeProject(newData, idx);
  }

  const handleChangeQrLevel = (evt) => {
    const qr_level = evt;
    const newData = {...project, qr_level};    
    changeProject(newData, idx);
  }  

  const handleChangeComplete = (color) => {
    if(color) {
      const qr_color = color.hex;
      const newData = {...project, qr_color};
      changeProject(newData, idx);
    }
  }

  return (
    <>
      <Modal title="QR Color Picker" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <SketchPicker 
            color={newColor}
            onChange={handleChangeComplete}
          />
        </div>
      </Modal>
      <Form
        form={form}
        name="example-form"
        className="slug-form"
        layout={layout}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        size='large'
        style={{marginBottom: '10px'}}
        initialValues={{
          slug: slug, 
          qr_size: (project.qr_size ? project.qr_size : 200),
          qr_level: (project.qr_level ? project.qr_level : "L"),
          qr_color: (project.qr_color ? project.qr_color : "#000")
        }}
      >
        <Form.Item
          name="slug"
          label="Project Link alias"
          rules={[
            {
              required: true,
              message: 'Please input your project link alias!',
            },
            {
              pattern, message: "Slug does not allow special characters"
            }
          ]}
        >
          <Input
            addonBefore={`${slugPrefix}/`}
            placeholder="randgad"
            style={{
              maxWidth: '260px',
              marginBottom: '10px',
            }}
          />              
        </Form.Item>
        <Form.Item
          name="qr_size"
          label="QR Size"
        >
          <Select 
            options={qrSizes} 
            onChange={handleChangeQrSize} 
          /> 
        </Form.Item>
        <Form.Item
          name="qr_level"
          label="QR Level"
        >
          <Select 
            options={qrlevels} 
            onChange={handleChangeQrLevel} 
          /> 
        </Form.Item>
        <Form.Item
          name="qr_color"
          label="QR Color"
        >
          <span>
            <Input
              value={newColor}
              type="text"              
              style={{ width: 100 }}
            />
            <Button onClick={showModal}>Color Picker</Button>
          </span>
        </Form.Item>        
        <Form.Item>
          <Button className="btn btn-sm btn-primary font-weight-bolder px-6" style={{marginRight: "10px"}} htmlType="submit">Save</Button>
          <Button className="btn btn-sm btn-warning font-weight-bolder px-6" onClick={handlReset}>Reset</Button>
        </Form.Item>            
      </Form>             
    </>
  )
}

export default SlugUpdateForm;