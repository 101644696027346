import React from "react";
import {Link} from 'react-router-dom'
import { Form, Input, Button } from 'antd';
import { MailOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const AuthForm = ({
  formTitle, 
  onFinish,   
  onValuesChange, 
  submitButtonLabel,
  helpoutMessage,
  textColor
}) => {
  return (
    <>      
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        size='large'
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        {/* <Form.Item>
          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Form.Item> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit" className="login-form-button">
            {submitButtonLabel}
          </Button>
          {helpoutMessage ? <p className="text-center" style={{color: textColor ? textColor : '#000', marginTop: '20px', fontSize: '18px'}}>{helpoutMessage.line1} <Link to={helpoutMessage.link} style={{color: '#2c7be5'}}>{helpoutMessage.line2}</Link></p> : null}
        </Form.Item>            
      </Form>
    </>
  )
}

export default AuthForm