import React, {useState} from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const QrCodeModal = (props) => {  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };  

  return (
    <>
      <div style={{height: '30px', fontSize: '20px', display: 'flex', alignItems: 'center'}}>
        <QrcodeOutlined onClick={showModal}/>
      </div>      
      <Modal title="Castpie" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        {props.children}
      </Modal> 
    </>  
  )
}

export default QrCodeModal;