import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;
const { listTimeZones } = require('timezone-support')

const TimeZonePicker = ({pickedTimeZone, handleTimeZone}) => {
  // <div>{Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
  const [timeZone, setTimeZone] = useState(pickedTimeZone ? pickedTimeZone  : Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {   
    setTimeZones(listTimeZones());
  }, []);

  const onChange = (x) => {
    handleTimeZone(x);
  }

  const onSearch = (x) => {
    // console.log(x)
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', padding: "20px", alignItems: 'center'}}>
      <div style={{marginRight: '20px'}}>TIME-ZONE</div>
      <Select
        showSearch
        size="large"
        style={{ width: "65%"}}
        placeholder="SELECT-A-TIME-ZONE"
        optionFilterProp="children"
        labelInValue
        defaultValue={{ value: timeZone }}
        onChange={onChange}
        onSearch={onSearch}
      
      >        
        {
          timeZones.map((tz, idx) => <Option value={tz} key={idx}>{tz}</Option>)
        }
      </Select>
    </div>
  )
}

export default TimeZonePicker;