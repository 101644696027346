import React, {useRef, useEffect} from 'react'
import ReactPlayer from 'react-player/lazy'
import { useAtom } from 'jotai';
import {mediaPlayerAtom, updatePlayerRef, 
  handleDurationChange, handlePlayedChange,
  handleOnReadyChange
} from './MediaPlayerAtom'

const MediaPlayer = (props) => {

  const playerRef = useRef(null);
  const [mediaPlayer] = useAtom(mediaPlayerAtom);  
  const [, saveRef] = useAtom(updatePlayerRef);
  const [, setPlayed] = useAtom(handlePlayedChange)
  const [, setDuration] = useAtom(handleDurationChange)
  const [, setOnReady] = useAtom(handleOnReadyChange)
  
  // const []

  useEffect(() => {
    saveRef(playerRef)
  }, [playerRef])

  const handleDuration = (durationSeconds) => {
    setDuration(durationSeconds)
  }

  const handleProgress = (res) => {
    setPlayed(res.playedSeconds);
  }

  const handleOnReady = ((res) => {
    if(res) {
      // update the onReady
      setOnReady(true);
    }
  })

  return (
    <>
      {props.children}
      <ReactPlayer 
        width='0%'
        height='0%'
        url={mediaPlayer.url}
        playing={mediaPlayer.playing}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onReady={handleOnReady}
        ref={playerRef}
      />
    </>
  )  
}

export default MediaPlayer;