import { useState, useEffect } from 'react';
import railsApi from '../../api/railsApi'

const useFetchMailingLists = (id) => {
  const [state, setState] = useState([]);

  // GET mailing list
  const fetchData = (id) => {
    railsApi.get(`/v1/mailing_integrations/${id}/mailing_lists`)
    .then((res) => {
      const data = res.data;
      if(data) {
        setState(data);
      }    
    })
    .catch((err) => {
      console.log(err.response.message)
    })
  }

  useEffect(() => {
    if(id) {
      fetchData(id);
    }
  }, [id])

  return [state];
}

export default useFetchMailingLists;

