import { atom } from "jotai";
// import {playerWatcherAtom} from './../jotais'

const temp = {
  pieId: null,
  url: null,
  artworkUrl: null, 
  episodeArtworkUrl: null, 
  podcastName: null, 
  episodeName: null,
  playing: false,
  durationSeconds: 0,
  playedSeconds: 0,
  onReady: false,
  playerRef: null  
}

export const mediaPlayerAtom = atom(temp) ;

export const handlePlayPauseClick = atom(null, (get, set, data) => {
  const currentData = get(mediaPlayerAtom);
  const {playing, playerRef} = currentData;

  // const playerWatcher = get(playerWatcherAtom);
  // const {nowPlayingRef} = playerWatcher;
  // if(nowPlayingRef && nowPlayingRef !== playerRef) {
  //   // if there is another media is playing, then will like to stop it
  //   console.log(nowPlayingRef);
  // }
  // check if the one going to be play the same as the existing one
  // yes, then just toggle the playing
  // no, then add data, and change the playing to be true
  if(currentData.pieId === data.pieId && currentData.url === data.url) {
    const newState = {...currentData, playing: !playing};
    set(mediaPlayerAtom, () => {
      return newState;
    })
  } else {
    const newState = {...currentData, ...data, playing: true, onReady: false, durationSeconds: 100, playedSeconds: 0};
    set(mediaPlayerAtom, () => {
      return newState;
    })    
  }
})

export const updatePlayerRef = atom(null, (get, set, playerRef) => {
  const currentData = get(mediaPlayerAtom);
  const newState = {...currentData, playerRef};
  set(mediaPlayerAtom, () => {
    return newState;
  })  
})

export const handleDurationChange = atom(null, (get, set, durationSeconds) => {
  const currentData = get(mediaPlayerAtom);
  const newState = {...currentData, durationSeconds};
  set(mediaPlayerAtom, () => {
    return newState;
  }) 
})

export const handlePlayedChange = atom(null, (get, set, playedSeconds) => {
  const currentData = get(mediaPlayerAtom);
  const newState = {...currentData, playedSeconds};
  set(mediaPlayerAtom, () => {
    return newState;
  }) 
})

export const handleOnReadyChange = atom(null, (get, set, onReady) => {
  const currentData = get(mediaPlayerAtom);
  const newState = {...currentData, onReady};
  set(mediaPlayerAtom, () => {
    return newState;
  }) 
})

export const getPlayerLoadingStatus = atom((get) => {
  const currentData = get(mediaPlayerAtom);
  const {onReady, playing} = currentData;
  if(onReady === false && playing === true) {
    return true;
  }

  return false;
})