import React from 'react';
import ListThemePickerItem from './ListThemePickerItem'

const ListThemePicker = ({selectedThemeId, themes, handleThemePicked}) => {
  const style = {
    // display: 'flex',
    overflow: "auto",
    whiteSpace: "nowrap",
  }

  if(themes) {
    return (
      <div style={style}>        
        {
          themes.map((theme, index) => <ListThemePickerItem
          key={index}
          selectedThemeId={selectedThemeId}
          handleThemePicked={handleThemePicked}
          id={theme.id}
          solid_color={theme.solid_color}
          degree={theme.degree}
          theme_name={theme.theme_name}
          background_type={theme.background_type}
          gradient_color_from={theme.gradient_color_from}
          gradient_color_to={theme.gradient_color_to}
          text_color={theme.text_color}
          button_color={theme.button_color}
          button_text_color={theme.button_text_color}
        />)
        }        
      </div>
    ) 
  }

  return null;    
}

export default ListThemePicker;