import React, { useEffect, useState} from "react";
import queryString from 'query-string'
import IntegrationCallback from './IntegrationCallback';

const MailchimpCallback = (props) => {

  const values = queryString.parse(props.location.search);
  const code = values ? values['code'] : null;
  const apiURL = `/v1/mailing_services/1/mailing_integrations`

  return (
    <IntegrationCallback 
      code={code} 
      apiURL={apiURL}
    />
  )
}

export default MailchimpCallback;